import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridItemChangeEvent,
  GridNoRecords,
  GridToolbar
} from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import GridLoader from '../../../../components/Loaders/GridLoader/GridLoader';
import ModalWrapper from '../../../../components/ModalWrapper/ModalWrapper';
import useAppBundleVersion from '../../../../helpers/hooks/storeHooks/useAppBundleVersion';
import useCommon from '../../../../helpers/hooks/storeHooks/useCommon';
import {
    deleteAppBundleVersion,
  getAppBundleVersionList,
  initialAppBundleVersionState,
  setDataState
} from '../../../../stores/appBundleVersion/reducer';
import { setError } from '../../../../stores/common/reducer';
import { IAppBundleVersion, IDeleteAppBundleVersion } from '../../../../types/appBundleVersion';
import { IGridDataState, Nullable } from '../../../../types/common';
import { IModalWrapperButton } from '../../../../types/modal';
import AppBundleVersionActionCell from './components/AppBundleVersionActionCell';
import CreateAppBundleVersionModal from './modals/CreateAppBundleVersionModal';

const AppBundlesVersionPage = () => {
  const dispatch = useDispatch()
  const { appBundleId = '' } = useParams()
  const { appBundleVersionList, isAppBundleVersionListLoading, dataState } = useAppBundleVersion()
  const [isShowAddBundleVersionModal, setIsShowAddBundleVersionModal] = useState(false)
  const [bundleVersionIdToEdit, setBundleVersionIdToEdit] = useState('');
  const [gridData, setGridData] = useState<IAppBundleVersion[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const { error } = useCommon()
  const [err, setErr] = useState<string>('');
  const [deletingAppBundleVersion, setDeletingAppBundleVersion] = useState<Nullable<IAppBundleVersion>>(null);

  useEffect(() => {
    setGridData(appBundleVersionList?.data || [])
  }, [appBundleVersionList])

  useEffect(() => {
    dispatch(getAppBundleVersionList(appBundleId))
    return () => {
      dispatch(setDataState(initialAppBundleVersionState.dataState))
    }
  }, [dispatch, appBundleId])

  useEffect(() => {
    if (error !== '' && error?.response?.status === 422 && error?.response?.data?.message) {
      setErr(error?.response?.data?.message)
    }
  }, [error])

  useEffect(() => {
    if (err !== '') {
      setIsShowErrorModal(true)
      setErr('')
    }
    return () => {
      dispatch(setError(''))
    }
  }, [err])

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    dispatch(setDataState(e.dataState as any))
    dispatch(getAppBundleVersionList(appBundleId))
  }

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';
    const newData = gridData.map(item =>
      item.id === event.dataItem.id
        ? { ...item, [field]: event.value }
        : item
    );
    setGridData(newData)
  };

  const toggleAppBundleModal = () => {
    setIsShowAddBundleVersionModal(!isShowAddBundleVersionModal)
    setBundleVersionIdToEdit('')
  }
  
  const onEdit = (item: IAppBundleVersion) => {
    setBundleVersionIdToEdit(item.id)
    setIsShowAddBundleVersionModal(true)
  }

  const onDelete = (item: IAppBundleVersion) => {
    setDeletingAppBundleVersion(item)
    setIsShowModal(true)
  };

  const continueOperation = () => {
    if (deletingAppBundleVersion) {
      const payload = {
        id: deletingAppBundleVersion.id,
        appBundleId: deletingAppBundleVersion.appBundleId
      } as IDeleteAppBundleVersion
      dispatch(deleteAppBundleVersion(payload))
    }
    closeModal();
  }

  const closeModal = () => {
    setIsShowModal(false);
    setIsShowErrorModal(false);
  };

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueOperation
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeModal
    }
  ];

  const cantDeleteButton: IModalWrapperButton[] = [
    {
      buttonText: 'Ok',
      onButtonClick: closeModal
    }
  ];

  return (
    <div className="product-page app-bundle-page">
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowErrorModal}
        className=""
        buttons={cantDeleteButton}
      >
        <div>
          {"You can't delete "}
          <span className={"modal-wrapper-var"}>{deletingAppBundleVersion?.version}</span>
          {". Version is being used in Alias."}
        </div>
      </ModalWrapper>
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowModal}
        className=""
        buttons={modalButtons}
      >
        <div>
          <div>{"Are you sure you want to continue: delete "}
            <span className={"modal-wrapper-var"}>{deletingAppBundleVersion?.version}</span>
            {"?"}
          </div>
        </div>
      </ModalWrapper>
      <Grid
        className="product-grid app-bundle-grid"
        data={gridData}
        {...(dataState as IGridDataState)}
        total={appBundleVersionList?.total}
        pageable={true}
        sortable={true}
        onItemChange={itemChange}
        onDataStateChange={onDataStateChange}
      >
        <GridToolbar>
          <Button variant="primary" onClick={toggleAppBundleModal}>
            Add
          </Button>
        </GridToolbar>
        <GridNoRecords>
          <GridLoader isLoading={isAppBundleVersionListLoading} />
        </GridNoRecords>
        <GridColumn field="version" title="Version" />
        <GridColumn
          field="action"
          title="Action"
          className="action-cell"
          sortable={false}
          cell={(props: GridCellProps) =>
            <AppBundleVersionActionCell onEdit={onEdit} onDelete={onDelete} {...props} />
          }
        />
      </Grid>
      <CreateAppBundleVersionModal isOpen={isShowAddBundleVersionModal} onClose={toggleAppBundleModal} dataToUpdateId={bundleVersionIdToEdit} />
    </div>
  )
}

export default AppBundlesVersionPage