import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  Grid,
  GridNoRecords,
  GridColumn,
  GridToolbar,
  GridCellProps,
  GridDataStateChangeEvent,
  GridItemChangeEvent
} from '@progress/kendo-react-grid';
import GridLoader from '../../../../components/Loaders/GridLoader/GridLoader';
import { IGridDataState, Nullable } from '../../../../types/common';
import {
  DropDownList,
  DropDownListChangeEvent,
  MultiSelect,
  MultiSelectChangeEvent
} from '@progress/kendo-react-dropdowns';
import {
  IPageSizeSetItem,
  PAGE_SIZE_SET
} from '../../../../types/producVersionConfigurations';
import useOrders from './../../../../helpers/hooks/storeHooks/useOrders';
import { IOrder, IPutOrder } from './../../../../types/order';
import {
  getOrderList,
  putOrder,
  setDataState
} from '../../../../stores/order/reducer';
import { DateCell } from './../../../../components/grid-components/DateCell';
import OrderStatusCell from './components/OrderStatusCell';
import OrdersActionCell from './components/OrdersActionCell';
import usePublications from '../../../../helpers/hooks/storeHooks/usePublcations';
import { enumGetKey } from '../../../../helpers/enumFunctions';
import { DateRangePicker, DateRangePickerChangeEvent } from '@progress/kendo-react-dateinputs';

interface IDateState {
  start: Nullable<Date>;
  end: Nullable<Date>;
}

const d = new Date();
d.setMonth(d.getMonth() - 1);
d.setDate(d.getDate() + 1);

const defaultValueDateState = {
  start: new Date(d),
  end: new Date()
};

enum DateRanges {
  month = 1,
  week = 2,
  day = 3,
  custom = 4
}

enum RequestStatuses {
  InProgress = 0,
  Declined = 1,
  Done = 2,
}

interface Item {
  Numb: number;
  Status: string;
}
const statusValue = "Status";
const statutsKey = "Numb";

const OrdersPage = () => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const { orderList, isOrdersLoading, dataState } = useOrders();
  const { onlineStores, publicationList } = usePublications();
  const [statusSelected, setStatusSelected] = useState(-1);

  const [gridData, setGridData] = useState<IOrder[]>([]);

  const [pageSize, setPageSize] = useState<IPageSizeSetItem>(PAGE_SIZE_SET[0]);
  const [pageSizeSet, setPageSizeSet] =
    useState<IPageSizeSetItem[]>(PAGE_SIZE_SET);

  const [status, setStatus] = useState<RequestStatuses[]>([RequestStatuses.InProgress]);
  const [dates, setDates] = useState<IDateState>(defaultValueDateState);
  const [filterString, setfilterString] = useState<string>('')
  const [isSelected, setIsSelected] = useState({ month: true, week: false, day: false, custom: false });
  const onChangeDate = (event: DateRangePickerChangeEvent) => {
    setDates(event.value);
  };

  const [data, setData] = useState<Item[]>([
    { Status: enumGetKey(RequestStatuses.InProgress, RequestStatuses), Numb: RequestStatuses.InProgress },
    { Status: enumGetKey(RequestStatuses.Done, RequestStatuses), Numb: RequestStatuses.Done },
    { Status: enumGetKey(RequestStatuses.Declined, RequestStatuses), Numb: RequestStatuses.Declined },]);

  const emptyItem: Item = data[0]
  const [value, setValue] = useState<Item[]>([emptyItem]);

  const datePickButton = (value: DateRanges) => {
    setIsSelected({
      ...isSelected,
      month: value == DateRanges.month ? true : false,
      week: value == DateRanges.week ? true : false,
      day: value == DateRanges.day ? true : false,
      custom: value == DateRanges.day ? true : false
    });

    const d = new Date();
    if (value === DateRanges.month) {
      d.setMonth(d.getMonth() - 1);
      d.setDate(d.getDate() + 1);
    } else if (value === DateRanges.week) {
      d.setDate(d.getDate() - 6);
    } else if (value === DateRanges.day) {
      d.setDate(d.getDate());
    }

    const currentDate = new Date()
    setDates(
      {
        start: d,
        end: currentDate
      })
  }

  const onStatusChange = React.useCallback((event: MultiSelectChangeEvent) => {
    const value = event.value;

    if (value && value[statusValue] === emptyItem[statusValue]) {
      return;
    } else if (Array.isArray(value) && value.length === 0) {
      setValue([emptyItem]);
    }
    setValue(value);
  }, []);


  useEffect(() => {
    console.log("filter")
    if (!dates.start || !dates.end) {
      return;
    }

    let statusFilter = "&filter=(("

    if (value.length === 0) {
      statusFilter = statusFilter + `status~eq~'${data[0].Status}'`
    } else {
      value.forEach(x => statusFilter = statusFilter + `status~eq~'${x.Status}'~or~`)
      statusFilter = statusFilter.slice(0, -4);
    }

    var endDate = new Date();
    endDate.setDate(dates.end.getDate() + 1);

    const str = statusFilter
      + `)~and~(created~gt~'${dates.start?.toDateString()}'~and~`
      + `created~lte~'${endDate.toDateString()}'))`

    setfilterString(str)

    console.log(filterString, " filterString")
    console.log(str, " filterString")
    dispatch(getOrderList(
      `${organizationId}${str}`
    ));

  }, [dates, status, value]);

  useEffect(() => {
    const total = orderList?.total || PAGE_SIZE_SET[0].id;
    const pageSizeNew: IPageSizeSetItem[] = PAGE_SIZE_SET.reduce(
      (accum: IPageSizeSetItem[], current: IPageSizeSetItem) => {
        if (current?.value < total) {
          accum.push(current);
        }
        return accum;
      },
      []
    );
    const pageSizeResult = pageSizeNew.length
      ? [...pageSizeNew, { id: `${total}`, value: 'all' }]
      : [PAGE_SIZE_SET[0]];
    setPageSizeSet(pageSizeResult);
    const orderGridData = orderList?.data.map((item) => ({
      ...item,
      isInEdit: false
    }));
    setGridData(orderGridData || []);

  }, [orderList, publicationList, onlineStores]);

  useEffect(() => {
    console.log(321)
    const newDataState = {
      ...dataState,
      take: +pageSize.id,
      skip: Math.floor(dataState.skip / +pageSize.id) * +pageSize.id
    };
    dispatch(setDataState(newDataState));

    if (organizationId && filterString) {
      dispatch(getOrderList(
        `${organizationId}${filterString}`
      ));
    }
  }, [pageSize, filterString]);

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';
    const newData = gridData.map((item: IOrder) =>
      item.id === event.dataItem.id ? { ...item, [field]: event.value } : item
    );
    setGridData(newData);
  };

  const onDataStateChange = (e: GridDataStateChangeEvent) => {

    dispatch(setDataState(e.dataState as any));
    if (organizationId && e.dataState) {
      console.log(e.dataState, 123)
      dispatch(
        getOrderList(
          `${organizationId}${filterString}`
        ))
    };
  };

  const onEditOrder = (orderId: string) => {
    const orderGridData = gridData.map((item: IOrder) =>
      item.id === orderId ? { ...item, isInEdit: true } : item
    );
    setGridData(orderGridData);
  };

  const onCancel = () => {
    const userGridData = gridData.map((item: IOrder) => ({
      ...item,
      isInEdit: false
    }));
    setGridData(userGridData);
  };

  const onUpdateOrder = (orderId: string) => {
    const putData: IPutOrder = {
      id: orderId,
      status: statusSelected
    };
    dispatch(putOrder(putData));
    setStatusSelected(-1);
    const orderGridData = gridData.map((item: IOrder) =>
      item.id === orderId
        ? {
          ...item,
          status: statusSelected,
          isInEdit: false
        }
        : {
          ...item,
          isInEdit: false
        }
    );
    setGridData(orderGridData);
  };

  const onSelectOrderStatus = (status: number) => {
    setStatusSelected(status);
  };

  const onPageSizeChange = (e: DropDownListChangeEvent) => {
    const chosenValue = e.value;
    setPageSize(chosenValue);
  };

  const changeStatusText = (props: GridCellProps) => {
    const text = (props.dataItem as IOrder).noPayment
      ? 'Not required' : 'Required';
    return (
      <td>{text}</td>
    )
  }

  return (
    <>
      <div className="btn-group filter-row" role="group">
        <div className="filter-row-left">
          <DateRangePicker
            className={'k-flex'}
            defaultValue={defaultValueDateState}
            onChange={onChangeDate}
            value={{ start: dates.start || new Date(), end: dates.end || new Date() }}
          />
          <div className="date-buttons">
            <button
              onClick={() => datePickButton(DateRanges.month)}
              name="month"
              disabled={isSelected.month ? true : false}
              className={isSelected.month ? "btn btn-primary" : "btn btn-outline-primary"}>
              month
            </button>
            <button
              onClick={() => datePickButton(DateRanges.week)}
              name="week"
              disabled={isSelected.week ? true : false}
              className={isSelected.week ? "btn btn-primary" : "btn btn-outline-primary"}>
              week
            </button>
            <button
              onClick={() => datePickButton(DateRanges.day)}
              name="day"
              disabled={isSelected.day ? true : false}
              className={isSelected.day ? "btn btn-primary" : "btn btn-outline-primary"}>
              day
            </button>
          </div>
          <MultiSelect
            className={"status-filter-multiselect"}
            onChange={onStatusChange}
            data={data}
            dataItemKey={statutsKey}
            textField={statusValue}
            value={value}
            filterable={true}
            autoClose={true}
          />
        </div>
        <div className="filter-row-right">
          <DropDownList
            style={{ width: '100px' }}
            data={pageSizeSet}
            value={pageSize}
            textField="value"
            dataItemKey="id"
            onChange={onPageSizeChange}
          />
        </div>
      </div>
      <Grid
        className="order-grid"
        data={gridData}
        {...(dataState as IGridDataState)}
        total={orderList?.total}
        pageable={true}
        sortable={true}
        onItemChange={itemChange}
        onDataStateChange={onDataStateChange}
      >
        <GridNoRecords>
          <GridLoader isLoading={isOrdersLoading} />
        </GridNoRecords>
        <GridColumn field="externalId" title="Order Id" className="grid-cell-form" />
        <GridColumn field="name" title="Name" />
        <GridColumn field="customerEmail" title="Customer Email" />
        <GridColumn field="created" title="Created" cell={DateCell} />
        <GridColumn field="onlineStoreName" title="Online Store" />
        <GridColumn field="noPayment" title="Payment Status" cell={changeStatusText} />
        <GridColumn
          field="status"
          title="Status"
          cell={(gridProps: GridCellProps) => (
            <OrderStatusCell
              gridProps={gridProps}
              onSelectOrderStatus={onSelectOrderStatus}
            />
          )}
        />
        <GridColumn
          field="action"
          title="Action"
          className="action-cell"
          cell={(gridProps: GridCellProps) => (
            <OrdersActionCell
              gridProps={gridProps}
              onEditOrder={onEditOrder}
              onCancel={onCancel}
              onUpdateOrder={onUpdateOrder}
            />
          )}
          sortable={false}
        />
      </Grid>
    </>
  );
};

export default OrdersPage;
