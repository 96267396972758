import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from 'react-bootstrap';
import { IUser } from '../../../../../types/user';

interface IUsersActionCell {
  gridProps: GridCellProps;
  onDeleteClickUser: (user: IUser) => void;
  onEditUser: (userId: string) => void;
  onCancel: () => void;
  onUpdateUser: (userId: string) => void;
}

const UsersActionCell = ({
  gridProps,
  onDeleteClickUser,
  onEditUser,
  onCancel,
  onUpdateUser
}: IUsersActionCell) => {
  const { dataItem } = gridProps;

  return (
    <td
      className={gridProps.className}
      colSpan={gridProps.colSpan}
      role="gridcell"
      aria-colindex={gridProps.ariaColumnIndex}
      aria-selected={gridProps.isSelected}
    >
      {dataItem.isInEdit ? (
        <>
          <Button
            variant="outline-secondary"
            className="k-m-1 pt-0 pb-0"
            onClick={() => onUpdateUser(dataItem.id)}
          >
            Update
          </Button>
          <Button
            variant="outline-secondary"
            className="k-m-1 pt-0 pb-0"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <Button
            variant="outline-secondary"
            className="k-m-1 pt-0 pb-0"
            onClick={() => onEditUser(dataItem.id)}
          >
            Edit
          </Button>
          <Button
            variant="outline-secondary"
            className="k-m-1 pt-0 pb-0"
            onClick={() => onDeleteClickUser(dataItem)}
          >
            Delete
          </Button>
        </>
      )}
    </td>
  );
};
export default UsersActionCell;
