import { apiRoutes } from './routes';
import {
  IAccountInfo,
  IPostPasswordRecovery,
  IPostPasswordRecoveryConfirm,
  IPutAccountInfo,
  ISignInData,
  ISignUpData,
  IUser,
  IInviteInfo,
  IPostChangePassword,
  IPostChangeEmail,
  IPostEmailChangeConfirmation
} from '../../types/user';
import client from './http.client';
import {
  IAssignedUsersList,
  IDesignToManufacturersOrganizations,
  IDesignToStores,
  IOrganisation,
  IOrganisationDesigning,
  IOrganisationManufacturing,
  IPostOrganisation,
  IPutAssignedUser,
  IPutOrganisation
} from '../../types/organisationPage';
import {
  IGetProdListResp,
  IPostProduct,
  IProduct,
  IPutProduct
} from '../../types/product';
import {
  IGetProductVersionList,
  IPostProductVersion,
  IProductVersion
} from '../../types/productVersion';
import {
  IConfiguration,
  IConfigurationParamData,
  IConfigurationRequests,
  IConfigurationRequestById,
  IGetConfigurations,
  IPostConfigurations,
  IPostConfigurationsMulti,
  IPutConfigurations,
  PostConfigurationResponse,
  IConfigurationRequestsDataItemResponse
} from '../../types/producVersionConfigurations';
import {
  IAppBundle,
  IGetAppBundleResp,
  IPostAppBundle,
  IPutAppBundle
} from '../../types/appBundle';
import { IConfigurationListItem } from './../../types/producVersionConfigurations';
import { IPostInviteUser } from './../../types/organisationPage';
import {
  IPublication,
  IPublicationList,
  IPutPublication
} from '../../types/publications';
import { IOnlineStore, IPostPublication } from './../../types/publications';
import { ITelemetry, IGetDataTelemetry } from '../../types/telemetry';
import { IDelOrder, IOrder, IOrderList, IPutOrder } from './../../types/order';
import { IPostTariff, IPutTariff, ITariff } from '../../types/tariff';
import {
  IAppBundleVersion,
  IAppBundleVersionList,
  IPostAppBundleVersion,
  IPutAppBundleVersion
} from '../../types/appBundleVersion';
import {
  IAppBundleAlias,
  IAppBundleAliasesResp,
  IAppBundleAliasList,
  IPostAppBundleAlias,
  IPutAppBundleAlias
} from '../../types/appBundleAlias';
import { IPaymentHistory, IPostBuyCloudPoints, IPostPayTariff } from '../../types/billing';
import { config } from 'process';

export default class Api {
  public static async signIn(formData: ISignInData): Promise<IUser> {
    const response = await client.post(apiRoutes.auth.signIn, formData);
    return response.data;
  }

  public static async signUp(formData: ISignUpData): Promise<string> {
    const response = await client.post(apiRoutes.user.signUp, formData);
    return response.data;
  }

  public static async postEmailConfirmation(
    hash: string
  ): Promise<any> {
    const response = await client.post(apiRoutes.user.emailConfirm(hash));
    return response;
  }

  public static async postEmailChangeConfirmation(
    formData: IPostEmailChangeConfirmation
  ): Promise<any> {
    const response = await client.post(apiRoutes.user.emailChangeConfirm, formData);
    return response;
  }

  public static async postSendEmailToRestorePassword(
    formData: IPostPasswordRecovery
  ): Promise<string> {
    const response = await client.post(apiRoutes.auth.pwdEmail, formData);
    return response.data;
  }

  public static async postPasswordRecoveryConfirm(
    formData: IPostPasswordRecoveryConfirm
  ): Promise<string> {
    const response = await client.post(apiRoutes.auth.pwdConfirm, formData);
    return response.data;
  }

  public static async putAccountInfo(
    formData: IPutAccountInfo
  ): Promise<string> {
    const response = await client.put(apiRoutes.account.root, formData);
    return response.data;
  }

  public static async postChangePassword(
    formData: IPostChangePassword
  ): Promise<any> {
    const response = await client.post
      (apiRoutes.account.changePassword(formData.oldPassword, formData.password));
    return response;
  } 

  public static async postChangeEmail(
    formData: IPostChangeEmail
  ): Promise<any> {
    const response = await client.post
      (apiRoutes.account.changeEmail, formData);
    return response;
  } 

  public static async authOrg(orgId: string): Promise<IUser> {
    const response = await client.post(apiRoutes.auth.org(orgId));
    return response.data;
  }

  public static async getOrganisations(
    userId: string
  ): Promise<IOrganisation[]> {
    const response = await client.get(apiRoutes.organisations.list(userId));
    return response.data;
  }

  public static async getOrganisationById(
    organisationId: string
  ): Promise<IOrganisation> {
    const response = await client.get(
      apiRoutes.organisations.byId(organisationId)
    );
    return response.data;
  }

  public static async getAssignedUsersOrganisationById(
    parameters: string
  ): Promise<IAssignedUsersList> {
    const response = await client.get(
      apiRoutes.organisations.assignedUsersById(parameters)
    );
    return response.data;
  }

  public static async putAssignedUser(
    formData: IPutAssignedUser
  ): Promise<string> {
    const response = await client.put(
      apiRoutes.organisations.assignedUser,
      formData
    );
    return response.data;
  }

  public static async deleteAssignedUser(parameters: string): Promise<string> {
    const response = await client.post(
      apiRoutes.organisations.deleteAssignedUser(parameters)
    );
    return response.data;
  }

  public static async inviteUser(formdata: IPostInviteUser): Promise<string> {
    const response = await client.post(
      apiRoutes.organisations.invite(),
      formdata
    );
    return response.data;
  }

  public static async getInviteInfoByInviteId(
    inviteId: string
  ): Promise<IInviteInfo> {
    const response = await client.get(apiRoutes.organisations.invite(inviteId));
    return response.data;
  }

  public static async acceptInvite(inviteId: string): Promise<string> {
    const response = await client.post(
      apiRoutes.organisations.acceptInvite(inviteId)
    );
    return response.data;
  }

  public static async postOrganisation(
    organisation: IPostOrganisation
  ): Promise<string> {
    const response = await client.post(
      apiRoutes.organisations.root,
      organisation
    );
    return response.data;
  }

  public static async putOrganisation(
    formData: IPutOrganisation
  ): Promise<string> {
    const response = await client.put(apiRoutes.organisations.root, formData);
    return response.data;
  }

  public static async getManufacturingOrganizationByUserId(
    userId: string
  ): Promise<IOrganisationManufacturing[]> {
    const response = await client.get(
      apiRoutes.organisations.manufacturingbyuser(userId)
    );
    return response.data;
  }

  public static async getAllManufacturingOrganization(): Promise<
    IOrganisationManufacturing[]
  > {
    const response = await client.get(apiRoutes.organisations.manufacturing());
    return response.data;
  }

  public static async getAllDesigningOrganization(): Promise<
    IOrganisationDesigning[]
    > {
    const response = await client.get(apiRoutes.organisations.designing());
    return response.data;
  }

  public static async getDesignToManufacturersOrganizations(): Promise<
    IDesignToManufacturersOrganizations[]
    > {
    const response = await client.get(apiRoutes.organisations.designToManufacturer());
    return response.data;
  }

  public static async postDesignToManufacturersOrganizations(organizationid: string):
    Promise<any> {
    const response = await client.post(apiRoutes.organisations.designToManufacturerById(organizationid));
    return response;
  }

  public static async deleteDesignToManufacturersOrganizations(organizationid: string):
    Promise<string> {
    const response = await client.delete(apiRoutes.organisations.designToManufacturerById(organizationid));
    return response.data;
  }

  public static async getDesignToStores(): Promise<
    IDesignToStores[]
    > {
    const response = await client.get(apiRoutes.organisations.designToStore());
    return response.data;
  }

  public static async postDesignToStores(organizationid: string):
    Promise<any> {
    const response = await client.post(apiRoutes.organisations.designToStoreId(organizationid));
    return response;
  }

  public static async deleteDesignToStores(organizationid: string):
    Promise<string> {
    const response = await client.delete(apiRoutes.organisations.designToStoreId(organizationid));
    return response.data;
  }

  public static async getAccount(): Promise<IAccountInfo> {
    const response = await client.get(apiRoutes.account.root);
    return response.data;
  }

  public static async getTariff(): Promise<ITariff[]> {
    const response = await client.get(apiRoutes.tariff.root);
    return response.data;
  }

  public static async getTariffById(id: string): Promise<ITariff> {
    const response = await client.get(apiRoutes.tariff.item(id));
    return response.data;
  }

  public static async postTariff(
    organisation: IPostTariff
  ): Promise<string> {
    const response = await client.post(
      apiRoutes.tariff.root,
      organisation
    );
    return response.data;
  }

  public static async putTariff(
    formData: IPutTariff
  ): Promise<string> {
    const response = await client.put(apiRoutes.tariff.root, formData);
    return response.data;
  }

  public static async deleteTariff(id: string): Promise<string> {
    const response = await client.delete(apiRoutes.tariff.item(id));
    return response.data;
  }

  public static async getProductList(
    dataString?: string
  ): Promise<IGetProdListResp> {
    const response = await client.get(apiRoutes.product.root(dataString));
    return response.data;
  }

  public static async getProduct(id: string): Promise<IProduct> {
    const response = await client.get(apiRoutes.product.item(id));
    return response.data;
  }

  public static async postProduct(formData: IPostProduct): Promise<string> {
    const response = await client.post(apiRoutes.product.root(), formData);
    return response.data;
  }

  public static async putProduct(formData: IPutProduct): Promise<string> {
    const response = await client.put(apiRoutes.product.root(), formData);
    return response.data;
  }

  public static async delProduct(id: string): Promise<string> {
    const response = await client.delete(apiRoutes.product.item(id));
    return response.data;
  }

  public static async getProdVersionList(
    params: string
  ): Promise<IGetProductVersionList> {
    const response = await client.get(apiRoutes.productVersion.root(params));
    return response.data;
  }

  public static async getProdVersion(id: string): Promise<IProductVersion> {
    const response = await client.get(apiRoutes.productVersion.item(id));
    return response.data;
  }

  public static async getConfigurationList(
    params: string
  ): Promise<IGetConfigurations> {
    const response = await client.get(apiRoutes.configurations.root(params));
    const configurationListResponse = {
      ...response.data,
      data: response.data.data.map((item: IConfigurationListItem) => ({
        ...item,
        isSelected: false
      }))
    };
    return configurationListResponse;
  }

  public static async getConfigurationById(
    id: string
  ): Promise<IConfiguration> {
    const response = await client.get(apiRoutes.configurations.item(id));
    return response.data;
  }

  public static async getConfigParams(
    configurationId: string
  ): Promise<IConfigurationParamData> {
    const response = await client.get(
      apiRoutes.configurations.params(configurationId)
    );
    let test = response.data as IConfigurationParamData;
    test.parameters.forEach((x, index) => (x.level = index % 3));
    return test;
  }

  public static async getConfigurationDesignGearLog(
    configurationId: string
  ): Promise<Blob> {
    let response = await client.get(
      apiRoutes.configurations.deignGearLog(configurationId)
    );
    return response.data;
  }

  public static async getConfigurationForgeLog(
    configurationId: string
  ): Promise<Blob> {
    const response = await client.get(
      apiRoutes.configurations.forgeLog(configurationId)
    );
    return response.data;
  }

  public static async getConfigurationRequestForgeLog(
    configurationId: string
  ): Promise<Blob> {
    const response = await client.get(
      apiRoutes.configurations.requestForgeLog(configurationId)
    );
    return response.data;
  }

  public static async getSvfPath(configurationId: string): Promise<string> {
    const response = await client.get(
      apiRoutes.configurations.svf(configurationId)
    );
    return response.data;
  }

  public static async getConfigurationOutput(configurationId: string, orderId: string): Promise<any> {
    const response = await client.get(
      apiRoutes.configurations.outputforproduction(configurationId, orderId))
    return response;
  }

  public static async postConfig(
    formData: IPostConfigurations
  ): Promise<PostConfigurationResponse> {
    const response = await client.post(
      apiRoutes.configurations.root(),
      formData
    );
    return response.data;
  }

  public static async postConfigMulti(
    formData: IPostConfigurationsMulti
  ): Promise<PostConfigurationResponse> {
    const response = await client.post(
      apiRoutes.configurations.mass(),
      formData
    );
    return response.data;
  }

  public static async deleteConfigurationsByIds(formData: any): Promise<any> {
    const response = await client.post(
      apiRoutes.configurations.bulkdelete(),
      formData
    );
    return response.data;
  }

  public static async deleteConfiguration(configurationId: string, isTemplate: boolean): Promise<any> {
    const response = await client.delete(
      apiRoutes.configurations.delete(configurationId, isTemplate)
    );
    return response.data;
  }

  public static async recalculateConfigurationsByIds(
    formData: any
  ): Promise<any> {
    const response = await client.post(
      apiRoutes.configurations.recalculate(),
      formData
    );
    return response.data;
  }

  public static async generatesvfConfigurationsByIds(
    formData: any
  ): Promise<any> {
    const response = await client.post(
      apiRoutes.configurations.generatesvf(),
      formData
    );
    return response.data;
  }

  public static async getConfigByRequest(
    dataString: string
  ): Promise<IConfigurationRequests> {
    const response = await client.get(
      apiRoutes.configurations.byRequest(dataString)
    );
    const configurationRequestsResponse = {
      ...response.data,
      data: response.data.data.map(
        (item: IConfigurationRequestsDataItemResponse, index: number) => ({
          ...item,
          index: index + 1,
          indexNumber: index + 1
        })
      )
    };
    return configurationRequestsResponse;
  }

  public static async getConfigByRequestId(
    requestId: string
  ): Promise<IConfigurationRequestById> {
    const response = await client.get(
      apiRoutes.configurations.byRequestId(requestId)
    );
    return response.data;
  }

  public static async putConfig(formData: IPutConfigurations): Promise<string> {
    const response = await client.put(
      apiRoutes.configurations.root(),
      formData
    );
    return response.data;
  }

  public static async postResubmitConfigurationRequest(
    requestId: string
  ): Promise<string> {
    const response = await client.post(
      apiRoutes.configurations.resubmit(requestId)
    );
    return response.data;
  }

  public static async postProdVersion(
    formData: IPostProductVersion
  ): Promise<string> {
    const response = await client.post(
      apiRoutes.productVersion.root(),
      formData
    );
    return response.data;
  }

  public static async putProdVersion(
    formData: IPostProductVersion
  ): Promise<string> {
    const response = await client.put(
      apiRoutes.productVersion.root(),
      formData
    );
    return response.data;
  }

  public static async delProductVersion(id: string): Promise<string> {
    const response = await client.delete(apiRoutes.productVersion.root(id));
    return response.data;
  }

  public static async getAppBundle(
    dataString?: string
  ): Promise<IGetAppBundleResp> {
    const response = await client.get(apiRoutes.appBundle.root(dataString));
    return response.data;
  }
  
  public static async getAppBundleById(id: string): Promise<IAppBundle> {
    const response = await client.get(apiRoutes.appBundle.item(id));
    return response.data;
  }

  public static async postAppBundle(formData: IPostAppBundle): Promise<string> {
    const response = await client.post(apiRoutes.appBundle.root(), formData);
    return response.data;
  }

  public static async putAppBundle(formData: IPutAppBundle): Promise<string> {
    const response = await client.put(apiRoutes.appBundle.root(), formData);
    return response.data;
  }

  public static async deleteAppBundle(id: string): Promise<string> {
    const response = await client.delete(apiRoutes.appBundle.item(id));
    return response.data;
  }

  public static async getAppBundleAlias(appBundleId: string): Promise<IAppBundleAlias> {
    const response = await client.get(apiRoutes.appBundleAlias.item(appBundleId));
    return response.data;
  }

  public static async getAppBundleAliasList(
    params: string
  ): Promise<IAppBundleAliasList> {
    const response = await client.get(apiRoutes.appBundleAlias.byAppBundleId(params));
    return response.data;
  }

  public static async getAllAppBundleAliases(
    params: string
  ): Promise<IAppBundleAliasesResp> {
    const response = await client.get(apiRoutes.appBundleAlias.root(params));
    return response.data;
  }

  public static async postAppBundleAlias(formData: IPostAppBundleAlias): Promise<string> {
    const response = await client.post(apiRoutes.appBundleAlias.root(formData.AppBundleId), formData);
    return response.data;
  }

  public static async putAppBundleAlias(formData: IPutAppBundleAlias): Promise<string> {
    const response = await client.put(apiRoutes.appBundleAlias.root(formData.AppBundleId), formData);
    return response.data;
  }

  public static async deleteAppBundleAlias(id: string): Promise<string> {
    const response = await client.delete(apiRoutes.appBundleAlias.item(id));
    return response.data;
  }

  public static async getAppBundleVersion(appBundleId: string): Promise<IAppBundleVersion> {
    const response = await client.get(apiRoutes.appBundleVersion.item(appBundleId));
    return response.data;
  }

  public static async getAppBundleVersionList(
    params: string
  ): Promise<IAppBundleVersionList> {
    const response = await client.get(apiRoutes.appBundleVersion.root(params));
    return response.data;
  }

  public static async postAppBundleVersion(formData: IPostAppBundleVersion): Promise<string> {
    const response = await client.post(apiRoutes.appBundleVersion.root(formData.appBundleId), formData);
    return response.data;
  }

  public static async putAppBundleVersion(formData: IPutAppBundleVersion): Promise<string> {
    const response = await client.put(apiRoutes.appBundleVersion.root(formData.appBundleId), formData);
    return response.data;
  }

  public static async deleteAppBundleVersion(id: string): Promise<string> {
    const response = await client.delete(apiRoutes.appBundleVersion.item(id));
    return response.data;
  }

  public static async getPublicationList(
    dataString?: string
  ): Promise<IPublicationList> {
    const response = await client.get(apiRoutes.publications.root(dataString));
    return response.data;
  }

  public static async getPublicationManufacturerList(
    dataString?: string
  ): Promise<IPublicationList> {
    const response = await client.get(apiRoutes.publications.manufacturer(dataString));
    return response.data;
  }

  public static async getPublicationById(
    publicationId: string
  ): Promise<IPublication> {
    const response = await client.get(
      apiRoutes.publications.byId(publicationId)
    );
    return response.data;
  }

  public static async deletePublicationById(
    publicationId: string
  ): Promise<IPublicationList> {
    const dataString = `publicationId=${publicationId}`;
    const response = await client.delete(
      apiRoutes.publications.root(dataString)
    );
    return response.data;
  }

  public static async getPublicationOnlineStores(): Promise<IOnlineStore[]> {
    const response = await client.get(apiRoutes.publications.onlineStores());
    return response.data;
  }

  public static async putPublication(
    formData: IPutPublication
  ): Promise<any> {
    const response = await client.put(apiRoutes.publications.root(), formData);
    return response;
  }

  public static async postPublication(
    formData: IPostPublication
  ): Promise<any> {
    const response = await client.post(apiRoutes.publications.root(), formData);
    return response;
  }

  public static async getTelemetry({
    organizationId,
    startDate,
    endDate
  }: IGetDataTelemetry): Promise<ITelemetry> {
    const response = await client.get(
      apiRoutes.configurations.telemetry(organizationId, startDate, endDate)
    );
    return response.data;
  }

  public static async getOrders(dataString?: string): Promise<IOrderList> {
    const response = await client.get(apiRoutes.orders.root(dataString));
    return response.data;
  }

  public static async getOrderById(orderId: string): Promise<IOrder[]> {
    const response = await client.get(apiRoutes.orders.byId(orderId));
    return response.data;
  }

  public static async putOrder(data: IPutOrder): Promise<string> {
    const response = await client.put(apiRoutes.orders.root(), data);
    return response.data;
  }

  public static async delOrder(id: string): Promise<string> {
    const dataString = `?orderId=${id}`;
    const response = await client.delete(apiRoutes.orders.byId(dataString));
    return response.data;
  }

  public static async postPayTariff(parameters: string
  ): Promise<string> {
    const response = await client.post(
      apiRoutes.billing.payTariff(parameters)
    );
    return response.data;
  }

  public static async buyCloudPoints(parameters: string
  ): Promise<string> {
    const response = await client.post(
      apiRoutes.billing.buyCloudPoints(parameters)
    );
    return response.data;
  }

  public static async getPaymentHistory(organizationId: string
  ): Promise<IPaymentHistory[]> {
    const response = await client.get(
      apiRoutes.billing.paymentHistory(organizationId)
    );
    return response.data;
  }
}

