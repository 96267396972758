export const goBackOneLevel = (path: string) => {
  const newPath = path.split('/');
  newPath.splice(newPath.length - 2, 1);
  return newPath.join('/');
};

export const setConfigIdInPath = (path: string, configurationId: string) => {
  const newPath = path.split('/');
  newPath[newPath.length - 2] = configurationId;
  return newPath.join('/');
};

export const goToConfigurationRequests = (path: string) => {
  const newPath = path.split('/');
  newPath.splice(-4);
  return `${newPath.join('/')}/configuration-requests`;
};

export const setPath = (
  path: string,
  parameter: (string | number | undefined)[] = []
) => {
  const paramReg = new RegExp(':.*?(?=\\/)|:.*(?=$)');
  let newPath = path;
  parameter.forEach((param) => {
    newPath = newPath.replace(paramReg, param?.toString() || '');
  });
  return newPath;
};

export const getOrgIdFromUrl = (): string => {
  const url = window.location.href.split('dashboard/')
  let orgId = '';
  // UUID v4 used by newGuid() in C#
  const uuidRegex = /^[0-9A-F]{8}-[0-9A-F]{4}-[4][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$/i

  if (url[1] && url[1].substring(0, 36).match(uuidRegex)) {
    orgId = url[1].substring(0, 36)
  }
  return orgId
}