export interface IOrder {
  id: string;
  name: string;
  description: string;
  quantity: 0;
  customerEmail: string;
  externalId: string;
  status: EOrderStatus;
  manufacturingOrganizationId: string;
  onlineStoreId: string;
  publicationId: string;
  configurationId: string;
  created: string;
  onlineStoreName: string;
  noPayment: string;
}

export interface IOrderList {
  data: IOrder[];
  total: number;
}

export interface IPutOrder {
  id: string;
  status: EOrderStatus;
}

export enum EOrderStatus {
  InProgress = 0,
  Declined = 1,
  Done = 2
}

export interface IDelOrder {
  orderId: string,
  manufacturingOrganizationId: string
}