import { all, call, delay, put, select, takeLatest } from 'typed-redux-saga';
import Api from '../../services/api/api';
import {
  getAppBundleVersion,
  getAppBundleVersionList,
  postAppBundleVersion,
  putAppBundleVersion,
  deleteAppBundleVersion,
  setAppBundleVersion,
  setAppBundleVersionList,
  setAppBundleVersionLoading,
  setAppBundleVersionListLoading,
} from './reducer';
import { popFromPending, pushInPending, setError, setPostReqResp } from '../common/reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  IPostAppBundleVersion,
  IPutAppBundleVersion,
  IDeleteAppBundleVersion,
} from '../../types/appBundleVersion';
import { IState } from '../configureStore';
import { toDataSourceRequestString } from '@progress/kendo-data-query';

function* getAppBundleVersionSaga({ payload: id }: PayloadAction<string>): any {
  try {
    yield put(setAppBundleVersionLoading(true))
    const response = yield* call(Api.getAppBundleVersion, id)
    yield* call(delay, 500);
    yield put(setAppBundleVersion(response))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(setAppBundleVersionLoading(false))
  }
}

function* getAppBundleVersionListSaga({ payload: appBundleId
}: PayloadAction<string>): any {
  try {
    const dataState = yield select(
      (state: IState) => state.appBundleVersion.dataState
    );
    const dataString: string = toDataSourceRequestString({ ...dataState });
    const idParam = `appBundleId=${appBundleId}&`
    yield put(setAppBundleVersionListLoading(true))
    const response = yield* call(Api.getAppBundleVersionList, idParam + dataString);
    yield put(setAppBundleVersionList(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setAppBundleVersionListLoading(false))
  }
}

function* postAppBundleVersionSaga({ payload: formData }: PayloadAction<IPostAppBundleVersion>) {
  try {
    yield put(setAppBundleVersionLoading(true))
    yield* call(Api.postAppBundleVersion, formData)
    const appBundleId = formData.get('appBundleId') as string
    yield* put(getAppBundleVersionList(appBundleId))
    yield put(setPostReqResp(appBundleId))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(setAppBundleVersionLoading(false))
  }
}

function* putAppBundleVersionSaga({ payload: formData }: PayloadAction<IPutAppBundleVersion>) {
  try {
    yield* put(setAppBundleVersionLoading(true))
    yield* call(Api.putAppBundleVersion, formData)
    const appBundleId = formData.get('appBundleId') as string
    yield* put(getAppBundleVersionList(appBundleId))
    yield put(setPostReqResp(appBundleId))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield* put(setAppBundleVersionLoading(false))
  }
}

function* deleteAppBundleVersionSaga({ payload: formData }: PayloadAction<IDeleteAppBundleVersion>) {
  try {
    yield put(pushInPending(formData.id))
    const response = yield* call(Api.deleteAppBundleVersion, formData.id)
    yield* put(getAppBundleVersionList(formData.appBundleId))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(popFromPending(formData.id))
  }
}

function* AppBundleVersionWatcher() {
  yield all([
    takeLatest(getAppBundleVersion.type, getAppBundleVersionSaga),
    takeLatest(getAppBundleVersionList.type, getAppBundleVersionListSaga),
    takeLatest(postAppBundleVersion.type, postAppBundleVersionSaga),
    takeLatest(putAppBundleVersion.type, putAppBundleVersionSaga),
    takeLatest(deleteAppBundleVersion.type, deleteAppBundleVersionSaga),
  ]);
}

export default AppBundleVersionWatcher;
