import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGridDataFilter, IGridDataStateFilter, IGridFilterSetting, Nullable } from '../../types/common';
import {
  IAppBundleAlias,
  IAppBundleAliasList,
  IPostAppBundleAlias,
  IPutAppBundleAlias,
  IDeleteAppBundleAlias,
  IAppBundleAliasesResp,
} from '../../types/appBundleAlias';

export interface IAppBundleAliasState {
  appBundleAlias: Nullable<IAppBundleAlias>;
  appBundleAliasList: Nullable<IAppBundleAliasList>;
  allAppBundleAliases: Nullable<IAppBundleAliasesResp>;
  isAppBundleAliasListLoading: boolean;
  isAppBundleAliasLoading: boolean,
  isAllAppBundleAliasesLoading: boolean,
  dataState: IGridDataStateFilter;
}

export const initialAppBundleAliasState: IAppBundleAliasState = {
  appBundleAlias: null,
  appBundleAliasList: null,
  allAppBundleAliases: null,
  isAppBundleAliasListLoading: false,
  isAppBundleAliasLoading: false,
  isAllAppBundleAliasesLoading: false,
  dataState: {
    filter: {
      filters: [] as IGridFilterSetting[],
      logic: ''
    } as IGridDataFilter,
    group: '',
    take: 8,
    skip: 0,
    sort: [{ field: 'created', dir: 'desc' }]
  },
};

const appBundleAliasSlice = createSlice({
  name: 'appBundleAlias',
  initialState: initialAppBundleAliasState,
  reducers: {
    getAppBundleAlias: (state, action: PayloadAction<string>) => {
    },
    getAppBundleAliasList: (state, action: PayloadAction<string>) => {
    },
    getAllAppBundleAliases: (state, action: PayloadAction) => {
    },
    postAppBundleAlias: (state, action: PayloadAction<IPostAppBundleAlias>) => {
    },
    putAppBundleAlias: (state, action: PayloadAction<IPutAppBundleAlias>) => {
    },
    deleteAppBundleAlias: (state, action: PayloadAction<IDeleteAppBundleAlias>) => {
    },
    setAppBundleAlias: (state, action: PayloadAction<IAppBundleAlias | null>) => {
      state.appBundleAlias = action.payload
    },
    setAppBundleAliasList: (state, action: PayloadAction<IAppBundleAliasList>) => {
      state.appBundleAliasList = action.payload
    },
    setAllAppBundleAliases: (state, action: PayloadAction<IAppBundleAliasesResp>) => {
      state.allAppBundleAliases = action.payload
    },
    setDataState: (state, action: PayloadAction<IGridDataStateFilter>) => {
      state.dataState = action.payload;
    },
    setAppBundleAliasLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppBundleAliasLoading = action.payload
    },
    setAppBundleAliasListLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppBundleAliasListLoading = action.payload
    },
    setAllAppBundleAliasesLoading: (state, action: PayloadAction<boolean>) => {
      state.isAllAppBundleAliasesLoading = action.payload
    },
  }
});

export const {
  getAppBundleAlias,
  getAppBundleAliasList,
  getAllAppBundleAliases,
  postAppBundleAlias,
  putAppBundleAlias,
  deleteAppBundleAlias,
  setAppBundleAlias,
  setAppBundleAliasList,
  setAllAppBundleAliases,
  setAppBundleAliasListLoading,
  setAppBundleAliasLoading,
  setAllAppBundleAliasesLoading,

  setDataState
} = appBundleAliasSlice.actions;

export default appBundleAliasSlice.reducer;
