import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGridDataStateFilter,
  IGridFilterSetting,
  Nullable
} from '../../types/common';
import { IDelOrder, IOrderList, IPutOrder } from './../../types/order';
import { IGridDataFilter } from './../../types/common';

export interface IOrderState {
  isOrdersLoading: boolean;
  orderList: Nullable<IOrderList>;
  dataState: IGridDataStateFilter;
}

export const initialOrderState: IOrderState = {
  isOrdersLoading: false,
  orderList: null,
  dataState: {
    filter: {
      filters: [] as IGridFilterSetting[],
      logic: ''
    } as IGridDataFilter,
    group: '',
    take: 5,
    skip: 0,
    sort: [{ field: 'created', dir: 'desc' }]
  }
};

const orderSlice = createSlice({
  name: 'order',
  initialState: initialOrderState,
  reducers: {
    getOrderList: (state, action: PayloadAction<string>) => {},
    putOrder: (state, action: PayloadAction<IPutOrder>) => { },
    delOrder: (state, action: PayloadAction<IDelOrder>) => { },
    setOrderList: (state, action: PayloadAction<IOrderList>) => {
      state.orderList = action.payload;
    },
    setOrdersLoading: (state, action: PayloadAction<boolean>) => {
      state.isOrdersLoading = action.payload;
    },
    setDataState: (state, action: PayloadAction<IGridDataStateFilter>) => {
      state.dataState = action.payload;
    }
  }
});

export const {
  getOrderList,
  putOrder,
  delOrder,
  setOrderList,
  setOrdersLoading,
  setDataState
} = orderSlice.actions;

export default orderSlice.reducer;
