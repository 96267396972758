import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';

const useTariff = () => {
    const tariffList = useSelector((state: IState) => state.tariff.tariffList);
    const isTariffListLoading = useSelector((state: IState) => state.tariff.isTariffListLoading);
    const tariff = useSelector((state: IState) => state.tariff.tariff);
    const isTariffLoading = useSelector((state: IState) => state.tariff.isTariffLoading);
    return {
        tariffList,
        isTariffListLoading,
        tariff,
        isTariffLoading
    };
};

export default useTariff;
