import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { FC, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CInput from "../../../../../components/form-components/CInput";
import { isEmpty, passwordValidation } from "../../../../../components/form-components/helpers/validation-functions";
import ModalWrapper from "../../../../../components/ModalWrapper/ModalWrapper";
import useCommon from "../../../../../helpers/hooks/storeHooks/useCommon";
import { postChangePassword } from "../../../../../stores/authentication/reducer";
import { setError, setPostReqResp } from "../../../../../stores/common/reducer";
import { ICommonModalProps } from "../../../../../types/common";
import { IModalWrapperButton } from "../../../../../types/modal";
import { IPostChangePassword } from "../../../../../types/user";

interface IChangePasswordModal extends ICommonModalProps {
  isPasswordChanged: (status: boolean) => void;
}

const ChangePasswordModal: FC<IChangePasswordModal> = ({ isOpen, onClose, isPasswordChanged }) => {
  const dispatch = useDispatch()
  const { postReqResp, error } = useCommon()

  const headerText = 'Change password';
  const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null)
  const modalButtons: IModalWrapperButton[] = [
    { buttonText: 'close', onButtonClick: () => onClose() },
    { buttonText: 'save', onButtonClick: () => formSubmitBtnRef?.current?.click() }
  ]

  const [err, setErr] = useState<string>('');

  useEffect(() => {
    if (error !== '' && error?.response?.status === 404 && error?.response?.data?.message) {
      setErr('Old password does not match')
    }
  }, [error])

  useEffect(() => {
    return () => {
      setErr('')
      dispatch(setError(''))
    }
  }, [onClose])

  useEffect(() => {
    if (postReqResp.status === 200 && isOpen) {
      isPasswordChanged(true);
      onClose()
    }
    dispatch(setPostReqResp(''));
  }, [postReqResp]);

  const onSubmitLocal = (formData: any) => {
    const sendData = formData as IPostChangePassword
    sendData.oldPassword = formData.oldPassword;
    sendData.password = formData.password;
    dispatch(postChangePassword(sendData))
  }

  return (
    <>
      <ModalWrapper
        headerText={headerText}
        isOpen={isOpen}
        onClose={onClose}
        buttons={modalButtons}
      >
        <Form
          onSubmit={onSubmitLocal}
          validator={(val) => passwordValidation(val)}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className="k-form-fieldset">
                <div className="mb-3">
                  <Field
                    name={"oldPassword"}
                    component={CInput}
                    label={"Old password"}
                    type="password"
                    validator={isEmpty}
                  />
                  {err && (
                    <div className={'text-danger small'}>{err}</div>
                  )}
                  <Field
                    name={"password"}
                    component={CInput}
                    label={"New password"}
                    type="password"
                    validator={isEmpty}
                  />
                  <Field
                    name={"confirmPassword"}
                    component={CInput}
                    label={"Confirm password"}
                    type="password"
                    validator={isEmpty}
                  />
                </div>
              </fieldset>
              <Button
                type="submit"
                hidden={true}
                ref={formSubmitBtnRef}
              >
                submit
              </Button>
            </FormElement>
          )}
        />
      </ModalWrapper>
    </>
  )
}

export default ChangePasswordModal