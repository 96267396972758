import { all, call, put, takeLatest, select } from 'typed-redux-saga';
import Api from '../../services/api/api';
import {
    delOrder,
  getOrderList,
  putOrder,
  setOrderList,
  setOrdersLoading
} from './reducer';
import { setError } from '../common/reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { IDelOrder, IPutOrder } from '../../types/order';
import { IState } from './../configureStore';
import { toDataSourceRequestString } from '@progress/kendo-data-query';

function* getOrderListSaga({
  payload: manufacturingOrganizationId
}: PayloadAction<string>): any {
  try {
    const dataState = yield select((state: IState) => state.orders.dataState);
    yield put(setOrdersLoading(true));
    const dataString = `${toDataSourceRequestString({
      ...dataState
    })}&manufacturingOrganizationId=${manufacturingOrganizationId}`;
    const response = yield* call(Api.getOrders, dataString);
    yield put(setOrderList(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setOrdersLoading(false));
  }
}

function* putOrderSaga({ payload: putData }: PayloadAction<IPutOrder>): any {
  try {
    yield put(setOrdersLoading(true));
    yield* call(Api.putOrder, putData);
    const currentOrganization = yield select(
      (state: IState) => state.organisation.currentOrganization
    );

    yield* call(getOrderListSaga, {
      payload: currentOrganization.id,
      type: getOrderList.type
    });
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setOrdersLoading(false));
  }
}

function* delOrderSaga({ payload: { orderId, manufacturingOrganizationId } }: PayloadAction<IDelOrder>) {
  try {
    yield* call(Api.delOrder, orderId)
    yield put(getOrderList(manufacturingOrganizationId))
  }
  catch (e: any) {
    yield put(setError(e))
  }
}

function* OrderWatcher() {
  yield all([
    takeLatest(getOrderList.type, getOrderListSaga),
    takeLatest(putOrder.type, putOrderSaga),
    takeLatest(delOrder.type, delOrderSaga)
  ]);
}

export default OrderWatcher;
