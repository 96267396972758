import { useDispatch } from 'react-redux';
import React, { FC, useEffect, useRef, useState } from 'react';
import {
  Field,
  FieldWrapper,
  Form,
  FormElement
} from '@progress/kendo-react-form';
import { ICommonModalProps, Nullable } from '../../../../../../types/common';
import usePublications from './../../../../../../helpers/hooks/storeHooks/usePublcations';
import { IModalWrapperButton } from '../../../../../../types/modal';
import {
  getPublicationOnlineStores,
  postPublication,
  putPublication
} from '../../../../../../stores/publication/reducer';
import { getDesignToManufacturersOrganizations, getDesignToStores, getManufacturingOrganizationByUserId } from '../../../../../../stores/organisation/reducer';

import ModalWrapper from '../../../../../../components/ModalWrapper/ModalWrapper';
import Spinner from './../../../../../../components/Loaders/Spinner/Spinner';
import CInput from '../../../../../../components/form-components/CInput';
import {
  isDateGreaterToday,
  isEmpty,
  isExcel
} from '../../../../../../components/form-components/helpers/validation-functions';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Button } from 'react-bootstrap';
import {
  IPublication,
  IPutPublication
} from '../../../../../../types/publications';
import { DateInput } from '@progress/kendo-react-dateinputs';
import { FormDatePicker } from '../../../../../../components/form-components/FormDatePicker';
import useAuthCheck from './../../../../../../helpers/hooks/storeHooks/useAuthCheck';
import { FormFile } from '../../../../../../components/form-components/FormFile';
import { useParams } from 'react-router-dom';
import { IPostPublication } from './../../../../../../types/publications';
import useOrganizations from '../../../../../../helpers/hooks/storeHooks/useOrganizations';
import { Checkbox } from '@progress/kendo-react-inputs';
import useCommon from '../../../../../../helpers/hooks/storeHooks/useCommon';
import { setError, setPostReqResp } from '../../../../../../stores/common/reducer';

interface ICreateEditPublicationFormProps extends ICommonModalProps {
  publicationToUpdate?: Nullable<IPublication>;
}

const CreateEditPublicationForm: FC<ICreateEditPublicationFormProps> = ({
  isOpen,
  publicationToUpdate,
  onClose
}) => {
  const dispatch = useDispatch();
  const { versionId } = useParams();
  const { postReqResp, error } = useCommon()

  const { designToManufacturersOrganizations, designToStores } = useOrganizations();
  const { isPublicationLoading } = usePublications();
  const formRef = useRef<Form | null>(null);
  const excelPriceFilesRef = useRef<HTMLInputElement | null>(null);
  const excelSubstitutionFilesRef = useRef<HTMLInputElement | null>(null);
  const formSubmitButtonRef = useRef<HTMLButtonElement | null>(null);
  const [noPaymentChecked, setNoPaymentChecked] = useState(false);
  const [isActiveChecked, setIsActiveChecked] = useState(false);

  const [err, setErr] = useState<string>('');

  useEffect(() => {
    if (error !== '' && error?.response?.status === 422 && error?.response?.data?.message) {
      setErr(`${error?.response?.data?.description}`)
    }
  }, [error])

  useEffect(() => {
    return () => {
      setErr('')
      dispatch(setError(''))
    }
  }, [])

  useEffect(() => {
    if (postReqResp.status === 200) {
      onClose()
    }
    dispatch(setPostReqResp(''));
  }, [postReqResp])

  const handleOnChangeNoPayment = () => {
    setNoPaymentChecked(!noPaymentChecked);
  };

  const handleOnChangeIsActive = () => {
    setIsActiveChecked(!isActiveChecked);
  };

  const headerText = publicationToUpdate
    ? 'Update publication'
    : 'Create publication';

  const [formState, setFormState] = useState<any>({
    Name: '',
    Description: '',
    manufacturingOrganization: '',
    isActive: false,
    noPayment: false,
    expires: new Date(),
    weightPropertyName: 'Inventor User Defined Properties\\PackageWeight',
    lengthPropertyName: 'Inventor User Defined Properties\\PackageLength',
    widthPropertyName: 'Inventor User Defined Properties\\PackageWidth',
    heightPropertyName: 'Inventor User Defined Properties\\PackageHeight'
  });

  const modalButtons: IModalWrapperButton[] = [
    { buttonText: 'close', onButtonClick: () => onClose() },
    {
      buttonText: 'save',
      onButtonClick: () => formSubmitButtonRef?.current?.click(),
      buttonDisabled: isPublicationLoading
    }
  ];

  useEffect(() => {
    dispatch(getDesignToStores());
    dispatch(getDesignToManufacturersOrganizations());
  }, []);
  
  useEffect(() => {
    if (publicationToUpdate) {
      const formStateCurrent = {
        name: publicationToUpdate.name,
        description: publicationToUpdate.description,
        manufacturingOrganization:
          publicationToUpdate.manufacturingOrganizationId,
        expires: new Date(publicationToUpdate.expires),
        onlineStore: publicationToUpdate.onlineStoreId,
        noPayment: publicationToUpdate.noPayment,
        isActive: publicationToUpdate.isActive,
        weightPropertyName: publicationToUpdate.weightPropertyName,
        lengthPropertyName: publicationToUpdate.lengthPropertyName,
        widthPropertyName: publicationToUpdate.widthPropertyName,
        heightPropertyName: publicationToUpdate.heightPropertyName
      };
      setFormState(formStateCurrent);
    } else {
      const created = new Date();
      created.setUTCHours(0, 0, 0, 0);
      const expires = new Date();
      expires.setUTCHours(0, 0, 0, 0);
      const weightPropertyName= 'Inventor User Defined Properties\\PackageWeight';
      const lengthPropertyName= 'Inventor User Defined Properties\\PackageLength';
      const widthPropertyName= 'Inventor User Defined Properties\\PackageWidth';
      const heightPropertyName= 'Inventor User Defined Properties\\PackageHeight';
      const formStateCurrent = {
        created,
        expires,
        weightPropertyName,
        lengthPropertyName,
        widthPropertyName,
        heightPropertyName,
      };
      setFormState(formStateCurrent);
    }
  }, [publicationToUpdate]);

  useEffect(() => {
    if (!publicationToUpdate) {
      let formStateCurrent = {};

      if (designToStores.length) {
        formStateCurrent = {
          ...formStateCurrent,
          onlineStore: designToStores[0].id
        };
      }

      if (designToManufacturersOrganizations.length) {
        formStateCurrent = {
          ...formStateCurrent,
                manufacturingOrganization: designToManufacturersOrganizations[0].id
        };
      }

      setFormState({ ...formState, ...formStateCurrent });
    }
  }, [designToStores, designToManufacturersOrganizations]);

  const onSubmitLocal = (formData: any) => {
    if (!formRef?.current?.isValid()) return;
    const data = new FormData();

    data.append('Name', formData.name);
    data.append('Description', formData.description);
    data.append('Expires', formData.expires.toDateString());
    data.append(
      'ManufacturingOrganizationId',
      formData.manufacturingOrganization
    );
    data.append('noPayment', formData.noPayment || false);
    data.append('isActive', formData.isActive || false);
    if (formData.excelFiles?.length) {
      data.append('PriceList', formData.excelFiles[0]);
    }
    if (formData.valueSubstitution?.length) {
      data.append('ValueSubstitution', formData.valueSubstitution[0]);
    }
    data.append('OnlineStoreId', formData.onlineStore);
    data.append('WeightPropertyName', formData.weightPropertyName);
    data.append('LengthPropertyName', formData.lengthPropertyName);
    data.append('WidthPropertyName', formData.widthPropertyName);
    data.append('HeightPropertyName', formData.heightPropertyName);

    if (publicationToUpdate) {
      data.append('Id', publicationToUpdate.id);
      dispatch(putPublication(data as IPutPublication));
    } else {
      if (versionId) {
        data.append('ProductVersionId', versionId);
        dispatch(postPublication(data as IPostPublication));
      }
    }
  };

  const onPriceFileSelect = () => {
    excelPriceFilesRef?.current?.click();
  };

  const onSubstitionSelect = () => {
    excelSubstitutionFilesRef?.current?.click();
  };

  return (
    <ModalWrapper
      headerText={headerText}
      isOpen={isOpen}
      onClose={onClose}
      buttons={modalButtons}
      className={'create-version-form'}
    >
      <Form
        onSubmit={onSubmitLocal}
        initialValues={formState}
        key={JSON.stringify(formState)}
        ref={formRef}
        render={() => (
          <FormElement className={'modal-body'}>
            {isPublicationLoading ? (
              <Spinner />
            ) : (
              <>
              {err && (
                <div className={'text-danger small'}>{err}</div>
              )}
              <fieldset className={'k-form-fieldset'}>
                <div className="mb-3">
                  <Field
                    name="name"
                    component={CInput}
                    label="Name"
                    validator={isEmpty}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="description"
                    component={CInput}
                    label="Description"
                    validator={isEmpty}
                  />
                </div>
                <div className="mb-3">
                  <FieldWrapper>
                    <div>Manufacturing Organization</div>
                    <DropDownList
                      name="manufacturingOrganization"
                      data={designToManufacturersOrganizations}
                      textField="name"
                      dataItemKey="id"
                      defaultValue={designToManufacturersOrganizations.find(
                        (item) =>
                          item.id ===
                          (publicationToUpdate?.manufacturingOrganizationId ||
                            designToManufacturersOrganizations[0].id)
                      )}
                      onChange={(val) =>
                        formRef?.current?.valueSetter(
                          'manufacturingOrganization',
                          val.target.value.id
                        )
                      }
                      required={true}
                    />
                  </FieldWrapper>
                </div>
                <div className="mb-3">
                  <div>Date of creation</div>
                  <DateInput
                    value={
                      publicationToUpdate?.created
                        ? new Date(publicationToUpdate?.created)
                        : new Date()
                    }
                    disabled={true}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    id={'expires'}
                    name={'expires'}
                    label={'Select the expiration date'}
                    hint={'Hint: Should be greater than today'}
                    component={FormDatePicker}
                    validator={isDateGreaterToday}
                    wrapperStyle={{
                      width: '90%',
                      marginRight: '18px',
                    }}
                  />
                </div>
                <div className="mb-3">
                  <FieldWrapper>
                    <div>Online Store</div>
                    <DropDownList
                      name="onlineStore"
                        data={designToStores}
                      textField="name"
                      dataItemKey="id"
                      defaultValue={designToStores.find(
                        (item) =>
                          item.id ===
                          (publicationToUpdate?.onlineStoreId ||
                            designToStores[0].id)
                      )}
                      onChange={(val) => {
                        formRef?.current?.valueSetter(
                          'onlineStore',
                          val.target.value.id
                        );
                      }}
                      required={true}
                    />
                  </FieldWrapper>
                </div>
                <div className="mb-3">
                  <span className="no-payment">
                    <Field
                      onChange={handleOnChangeNoPayment}
                      component={Checkbox}
                      defaultChecked={noPaymentChecked}
                      name="noPayment"
                      className="no-payment"
                      label="No payment"
                      btnText="nopayment"
                    />
                  </span>
                  <span>
                    <Field
                      onChange={handleOnChangeIsActive}
                      component={Checkbox}
                      defaultChecked={isActiveChecked}
                      name="isActive"
                      className="is-Active"
                      label="Is active"
                      btnText="isactive"
                    />
                  </span>
                </div>
                <div className="mb-3">
                  <Field
                    name="excelFiles"
                    component={FormFile}
                    className="upload-component"
                    label="Select price"
                    btnText="select"
                    multiple={true}
                    inputRef={excelPriceFilesRef}
                    onFileSelect={onPriceFileSelect}
                    validator={(val) => isExcel(val, publicationToUpdate ? false : true)}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="valueSubstitution"
                    component={FormFile}
                    className="upload-component"
                    label="Select value substitution"
                    btnText="select"
                    multiple={false}
                    inputRef={excelSubstitutionFilesRef}
                    onFileSelect={onSubstitionSelect}
                    validator={(val) => isExcel(val, false)}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="weightPropertyName"
                    component={CInput}
                    label="Weight"
                    validator={isEmpty}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="lengthPropertyName"
                    component={CInput}
                    label="Length"
                    validator={isEmpty}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="widthPropertyName"
                    component={CInput}
                    label="Width"
                    validator={isEmpty}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="heightPropertyName"
                    component={CInput}
                    label="Height"
                    validator={isEmpty}
                  />
                </div>
              </fieldset>
              </>
            )}
            <Button type="submit" hidden={true} ref={formSubmitButtonRef}>
              submit
            </Button>
          </FormElement>
        )}
      />
    </ModalWrapper>
  );
};

export default CreateEditPublicationForm;
