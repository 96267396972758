import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from 'react-bootstrap';
import { IPublication } from '../../../../../types/publications';

interface IPublicationActionCell {
  gridProps: GridCellProps;
  onDeleteClickPublication: (publication: IPublication) => void;
  onEditClickPublication: (publicationId: IPublication) => void;
  // isInEdit: boolean,
  // onCancel: () => void
  // onUpdateUser: (userId: string) => void
}

const PublicationActionCell = ({
  gridProps,
  onDeleteClickPublication,
  onEditClickPublication
}: IPublicationActionCell) => {
  const { dataItem } = gridProps;

  return (
    <td
      className={gridProps.className}
      colSpan={gridProps.colSpan}
      role="gridcell"
      aria-colindex={gridProps.ariaColumnIndex}
      aria-selected={gridProps.isSelected}
    >
      <Button
        variant="outline-secondary"
        className="k-m-1 pt-0 pb-0"
        onClick={() => onEditClickPublication(dataItem)}
      >
        Edit
      </Button>
      <Button
        variant="outline-secondary"
        className="k-m-1 pt-0 pb-0"
        onClick={() => onDeleteClickPublication(dataItem)}
      >
        Delete
      </Button>
    </td>
  );
};
export default PublicationActionCell;
