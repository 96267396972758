import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';

const useAuthCheck = () => {
  const user = useSelector((state: IState) => state.auth.user)
  const isUserLoading = useSelector((state: IState) => state.auth.isUserLoading)
  const account = useSelector((state: IState) => state.auth.accountInfo)
  const inviteInfo = useSelector((state: IState) => state.auth.inviteInfo)
  const isInviteLoading = useSelector((state: IState) => state.auth.isInviteLoading)
  const isEmailConfirmLoading = useSelector((state: IState) => state.auth.isEmailConfirmLoading)
  
  return {
    user: user,
    authenticated: !!user,
    authLoading: isUserLoading,
    account,
    inviteInfo,
    isInviteLoading,
    isEmailConfirmLoading
  }
}

export default useAuthCheck