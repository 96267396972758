export interface IPostPayTariff {
  organizationId: string;
  tariffId: string;
}

export interface IPostBuyCloudPoints {
  organizationId: string;
  count: number;
}

export interface IPaymentHistory {
  id: string,
  organizationId: string,
  totalSeconds: number,
  created: string,
  isSvf: boolean,
  incomePoints: number,
  expired: string,
  pointType: EPointType,
  remainingDiscSpace: number
}

export enum EPointType {
  None = 0,
  TariffPoint = 1,
  AdditionalPoint = 2
}