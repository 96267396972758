import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';

const useOrganizations = () => {
  const organizations = useSelector(
    (state: IState) => state.organisation.organisations
  );
  const organizationsManufacturing = useSelector(
    (state: IState) => state.organisation.organisationsManufacturing
  );
  const organizationsDesigning = useSelector(
    (state: IState) => state.organisation.organisationsDesigning
  );
  const designToManufacturersOrganizations = useSelector(
    (state: IState) => state.organisation.designToManufacturersOrganizations
  );
  const designToStores = useSelector(
    (state: IState) => state.organisation.designToStores
  );
  const currentOrganization = useSelector(
    (state: IState) => state.organisation.currentOrganization
  );
  const assignedUsers = useSelector(
    (state: IState) => state.organisation.assignedUsers
  );
  const isLoadingAssignedUsers = useSelector(
    (state: IState) => state.organisation.isLoadingAssignedUsers
  );
  const dataStateAssignedUsers = useSelector(
    (state: IState) => state.organisation.dataStateAssignedUsers
  );
  const isLoadingOrganizationManufacturing = useSelector(
    (state: IState) => state.organisation.isLoadingOrganizationManufacturing
  );
  const isLoadingOrganizationDesigning = useSelector(
    (state: IState) => state.organisation.isLoadingOrganizationDesigning
  );
  const isLoadingDesignToManufacturersOrganizations = useSelector(
    (state: IState) => state.organisation.isLoadingDesignToManufacturersOrganizations
  );
  const isLoadingDesignToStores = useSelector(
    (state: IState) => state.organisation.isLoadingDesignToStores
  );
  const createdOrganizationId = useSelector(
    (state: IState) => state.organisation.createdNowOrganizationId
  );

  return {
    organizations,
    organizationsManufacturing,
    organizationsDesigning,
    designToManufacturersOrganizations,
    designToStores,
    currentOrganization,
    assignedUsers,
    isLoadingAssignedUsers,
    dataStateAssignedUsers,
    isLoadingOrganizationManufacturing,
    isLoadingOrganizationDesigning,
    isLoadingDesignToManufacturersOrganizations,
    isLoadingDesignToStores,
    createdOrganizationId,
  };
};

export default useOrganizations;
