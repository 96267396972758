import {
  IOrganisation,
  IPostOrganisation,
  IPutAssignedUser,
  IPutOrganisation,
  IDeleteAssignedUser,
  IAssignedUsersList,
  IOrganisationDesigning,
  IDesignToManufacturersOrganizations,
  IDesignToStores
} from '../../types/organisationPage';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGridDataFilter,
  IGridDataStateFilter,
  IGridFilterSetting,
  Nullable
} from '../../types/common';
import { IPostInviteUser } from './../../types/organisationPage';
import { IOrganisationManufacturing } from '../../types/organisationPage';

export interface IOrganisationState {
  organisations: IOrganisation[];
  organisationsManufacturing: IOrganisationManufacturing[];
  organisationsDesigning: IOrganisationDesigning[];
  designToManufacturersOrganizations: IDesignToManufacturersOrganizations[];
  designToStores: IDesignToStores[];
  currentOrganization: Nullable<IOrganisation>;
  isLoadingOrganisation: boolean;
  isLoadingOrganizationManufacturing: boolean;
  isLoadingOrganizationDesigning: boolean;
  isLoadingDesignToManufacturersOrganizations: boolean;
  isLoadingDesignToStores: boolean;
  assignedUsers: IAssignedUsersList;
  isLoadingAssignedUsers: boolean;
  dataStateAssignedUsers: IGridDataStateFilter;
  createdNowOrganizationId: string;
  organizationById: Nullable<IOrganisation>;
}

const initialState: IOrganisationState = {
  organisations: [],
  organisationsManufacturing: [],
  organisationsDesigning: [],
  designToManufacturersOrganizations: [],
  designToStores: [],
  currentOrganization: null,
  isLoadingOrganisation: false,
  isLoadingOrganizationManufacturing: false,
  isLoadingOrganizationDesigning: false,
  isLoadingDesignToManufacturersOrganizations: false,
  isLoadingDesignToStores: false,
  assignedUsers: {
    data: [],
    total: 0
  },
  isLoadingAssignedUsers: false,
  dataStateAssignedUsers: {
    filter: {
      filters: [] as IGridFilterSetting[],
      logic: ''
    } as IGridDataFilter,
    group: '',
    take: 8,
    skip: 0,
    sort: []
  },
  createdNowOrganizationId: '',
  organizationById: null
};

const organisationSlice = createSlice({
  name: 'organisation',
  initialState: initialState,
  reducers: {
    getOrganisations: (state, action: PayloadAction<string>) => {},
    getManufacturingOrganizationByUserId: (
      state,
      action: PayloadAction<string>
    ) => {},
    getAllManufacturingOrganization: (state, action: PayloadAction) => {},
    getAllDesigningOrganization: (state, action: PayloadAction) => { },
    getDesignToManufacturersOrganizations: (state, action: PayloadAction) => { },
    getDesignToStores: (state, action: PayloadAction) => { },
    getOrganisationById: (state, action: PayloadAction<string>) => {},
    getAssignedUsersOrganisationById: (
      state,
      action: PayloadAction<string>
    ) => {},
    putAssignedUser: (state, action: PayloadAction<IPutAssignedUser>) => {},
    deleteAssignedUser: (
      state,
      action: PayloadAction<IDeleteAssignedUser>
    ) => {},
    inviteUser: (state, action: PayloadAction<IPostInviteUser>) => {},
    setAssignedUsers: (state, action: PayloadAction<IAssignedUsersList>) => {
      state.assignedUsers = action.payload;
    },
    setIsLoadingAssignedUsers: (state, action: PayloadAction<boolean>) => {
      state.isLoadingAssignedUsers = action.payload;
    },
    postOrganisation: (state, action: PayloadAction<IPostOrganisation>) => {},
    postDesignToManufacturersBinding: (state, action: PayloadAction<string>) => { },
    deleteDesignToManufacturersBinding: (state, action: PayloadAction<string>) => { },
    postDesignToStoresBinding: (state, action: PayloadAction<string>) => { },
    deleteDesignToStoresBinding: (state, action: PayloadAction<string>) => { },
    putOrganisation: (state, action: PayloadAction<IPutOrganisation>) => {},
    isLoadingOrganisation: (state, action: PayloadAction<boolean>) => {
      state.isLoadingOrganisation = action.payload;
    },
    setLoadingOrganizationManufacturing: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLoadingOrganizationManufacturing = action.payload;
    },
    setLoadingOrganizationDesigning: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLoadingOrganizationDesigning = action.payload;
    },
    setLoadingDesignToManufacturersOrganizations: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLoadingDesignToManufacturersOrganizations = action.payload;
    },
    setLoadingDesignToStores: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isLoadingDesignToStores = action.payload;
    },
    saveOrganisations: (state, action: PayloadAction<IOrganisation[]>) => {
      state.organisations = action.payload;
    },
    setOrganisationsManufacturing: (
      state,
      action: PayloadAction<IOrganisationManufacturing[]>
    ) => {
      state.organisationsManufacturing = action.payload;
    },
    setOrganisationsDesigning: (
      state,
      action: PayloadAction<IOrganisationDesigning[]>
    ) => {
      state.organisationsDesigning = action.payload;
    },
    setDesignToManufacturersOrganizations: (
      state,
      action: PayloadAction<IDesignToManufacturersOrganizations[]>
    ) => {
      state.designToManufacturersOrganizations = action.payload;
    },
    setDesignToStores: (
      state,
      action: PayloadAction<IDesignToStores[]>
    ) => {
      state.designToStores = action.payload;
    },
    setCurrentOrganisation: (state, action: PayloadAction<Nullable<IOrganisation>>) => {
      state.currentOrganization = action.payload;
    },
    setCreatedNowOrganization: (state, action: PayloadAction<string>) => {
      state.createdNowOrganizationId = action.payload;
    },
    setDataStateAssignedUsers: (
      state,
      action: PayloadAction<IGridDataStateFilter>
    ) => {
      state.dataStateAssignedUsers = action.payload;
    }
  }
});

export const {
  isLoadingOrganisation,
  setLoadingOrganizationManufacturing,
  setLoadingOrganizationDesigning,
  setLoadingDesignToManufacturersOrganizations,
  setLoadingDesignToStores,
  saveOrganisations,
  setOrganisationsManufacturing,
  setOrganisationsDesigning,
  setDesignToManufacturersOrganizations,
  setDesignToStores,
  getOrganisations,
  getManufacturingOrganizationByUserId,
  getAllManufacturingOrganization,
  getAllDesigningOrganization,
  getDesignToManufacturersOrganizations,
  postDesignToManufacturersBinding,
  deleteDesignToManufacturersBinding,
  getDesignToStores,
  postDesignToStoresBinding,
  deleteDesignToStoresBinding,
  getOrganisationById,
  getAssignedUsersOrganisationById,
  putAssignedUser,
  deleteAssignedUser,
  inviteUser,
  setAssignedUsers,
  setIsLoadingAssignedUsers,
  postOrganisation,
  putOrganisation,
  setCurrentOrganisation,
  setDataStateAssignedUsers,
  setCreatedNowOrganization
} = organisationSlice.actions;

export default organisationSlice.reducer;
