import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAuthCheck from '../../../helpers/hooks/storeHooks/useAuthCheck';
import { acceptInvite } from '../../../stores/authentication/reducer';
import ModalWrapper from '../../../components/ModalWrapper/ModalWrapper';
import { IModalWrapperButton } from '../../../types/modal';
import Spinner from '../../../components/Loaders/Spinner/Spinner';
import { enumGetKey } from '../../../helpers/enumFunctions';
import { EOrgUserRoles, EUserRoles } from '../../../types/user';
import useCommon from '../../../helpers/hooks/storeHooks/useCommon';
import { setPostReqResp } from '../../../stores/common/reducer';

interface IAddUserToOrganizationModal {
  inviteId?: string;
  isOpen: boolean;
  onExit: () => void;
}

const AddUserToOrganizationModal = ({
  inviteId,
  isOpen,
  onExit
}: IAddUserToOrganizationModal) => {
  const dispatch = useDispatch();
  const { postReqResp } = useCommon();
  const { isInviteLoading, inviteInfo } = useAuthCheck();

  useEffect(() => {
    if (postReqResp === 200) {
      dispatch(setPostReqResp(''));
      onExit();
    }
  }, [postReqResp]);

  const onAgree = () => {
    if (inviteId) dispatch(acceptInvite(inviteId));
  };

  const onNotAgree = () => {
    dispatch(setPostReqResp(''));
    onExit();
  };

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'I agree',
      onButtonClick: onAgree
    },
    {
      buttonText: 'Cancel',
      onButtonClick: onNotAgree
    }
  ];

  return (
    <>
      <ModalWrapper
        headerText="You have an invitation!"
        isOpen={isOpen}
        buttons={modalButtons}
      >
        <div className="accept-user-modal">
          {isInviteLoading ? (
            <Spinner />
          ) : (
            <>
              <div>{`Do you agree to join an organization - ${
                inviteInfo?.organizationName || ''
              }?`}</div>
              <div className="mt-3">{`Your role is ${
                inviteInfo ? enumGetKey(inviteInfo?.orgUserRole, EOrgUserRoles) : ''
              }.`}</div>
              {postReqResp === 400 && (
                <div className="text-danger small mt-1">
                  Something is wrong. Try to visit this link later.
                </div>
              )}
            </>
          )}
        </div>
      </ModalWrapper>
    </>
  );
};

export default AddUserToOrganizationModal;
