import React, { useEffect,  useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  DropDownList,
  DropDownListChangeEvent,
  MultiSelect,
  MultiSelectChangeEvent,
} from '@progress/kendo-react-dropdowns';
import { Pager, PageChangeEvent } from '@progress/kendo-react-data-tools';
import { Loader } from '@progress/kendo-react-indicators';
import useConfigurations from '../../../../helpers/hooks/storeHooks/useConfigurations';
import { getConfigurationByRequest, resubmitConfigurationRequest } from '../../../../stores/productConfigurations/reducer';
import { Sortable } from '@progress/kendo-react-sortable';
import {
  IConfigurationRequestsDataItem,
  IPageSizeSetItem,
  PAGE_SIZE_SET,
} from './../../../../types/producVersionConfigurations';
import SortableItemUI from './components/SortableItemUI';
import { DateRangePicker, DateRangePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { useParams } from 'react-router-dom';
import { enumGetKey, enumToKeqValue } from '../../../../helpers/enumFunctions';
import { Nullable } from '../../../../types/common';
import ConfigurationRequestActionCell from './components/ConfigurationRequestActionCell';

const initialPageState = {
  skip: 0,
  take: +PAGE_SIZE_SET[0].id,
};

interface IDateState {
  start: Nullable<Date>;
  end: Nullable<Date>;
}

const d = new Date();
d.setMonth(d.getMonth() - 1);
d.setDate(d.getDate() + 1);

const defaultValueDateState = {
  start: new Date(d),
  end: new Date()
};

enum DateRanges {
  month = 1,
  week = 2,
  day = 3,
  custom = 4
}

enum RequestStatuses {
  InQueue = 1,
  ServiceUnavailableError = 4,
  IncorrectRequestError = 8,
  InvalidConfiguration = 16
}

interface Item {
  Numb: number;
  Status: string;
}
const statusValue = "Status";
const statutsKey = "Numb";

interface tableItems extends IConfigurationRequestsDataItem {
  resubmit: any,
}

const ConfigurationRequestsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const { configurationRequestsList, isConfigurationRequestsListLoading } = useConfigurations();

  const [dataPageConfigurationRequests, setDataPageConfigurationRequests] =
    React.useState<tableItems[]>([]);
  const [pageState, setPageState] = React.useState(initialPageState);
  const [pageSize, setPageSize] = useState<IPageSizeSetItem>(PAGE_SIZE_SET[0]);
  const [pageSizeSet, setPageSizeSet] =
    useState<IPageSizeSetItem[]>(PAGE_SIZE_SET);

  const [status, setStatus] = useState<RequestStatuses[]>([RequestStatuses.InQueue]);
  const [dates, setDates] = useState<IDateState>(defaultValueDateState);
  const [filterString, setfilterString] = useState<string>('')
  const [isSelected, setIsSelected] = useState({ month: true, week: false, day: false, custom: false });
  const onChangeDate = (event: DateRangePickerChangeEvent) => {
    setDates(event.value);
  };

  const [data, setData] = useState<Item[]>([
    { Status: enumGetKey(RequestStatuses.InQueue, RequestStatuses), Numb: RequestStatuses.InQueue },
    { Status: enumGetKey(RequestStatuses.IncorrectRequestError, RequestStatuses), Numb: RequestStatuses.IncorrectRequestError },
    { Status: enumGetKey(RequestStatuses.InvalidConfiguration, RequestStatuses), Numb: RequestStatuses.InvalidConfiguration },
    { Status: enumGetKey(RequestStatuses.ServiceUnavailableError, RequestStatuses), Numb: RequestStatuses.ServiceUnavailableError },]);

  const emptyItem: Item = data[0]
  const [value, setValue] = useState<Item[]>([emptyItem]);

  const datePickButton = (value: DateRanges) => {
    setIsSelected({
      ...isSelected,
      month: value == DateRanges.month ? true : false,
      week: value == DateRanges.week ? true : false,
      day: value == DateRanges.day ? true : false,
      custom: value == DateRanges.day ? true : false
    });

    const d = new Date();
    if (value === DateRanges.month) {
      d.setMonth(d.getMonth() - 1);
      d.setDate(d.getDate() + 1);
    } else if (value === DateRanges.week) {
      d.setDate(d.getDate() - 6);
    } else if (value === DateRanges.day) {
      d.setDate(d.getDate());
    }

    const currentDate = new Date()
    setDates(
      {
        start: d,
        end: currentDate
      })
  }

  let { skip, take } = pageState;
  
  useEffect(() => {
    if (!dates.start || !dates.end) {
      return;
    }
    
    let statusFilter = "&filter=(("

    if (value.length === 0) {
      statusFilter = statusFilter + `status~eq~'${data[0].Status}'`
    } else {
      value.forEach(x => statusFilter = statusFilter + `status~eq~'${x.Status}'~or~`)
      statusFilter = statusFilter.slice(0, -4);
    }

    var endDate = new Date();
    endDate.setDate(dates.end.getDate() + 1);

    const str = `&sort=created-desc`
                +statusFilter
                +`)~and~(created~gt~'${dates.start?.toDateString()}'~and~`
                +`created~lte~'${endDate.toDateString()}'))`

    setfilterString(str)

    dispatch(
      getConfigurationByRequest(
        `${organizationId}`
        +`&pageSize=${take}`
        +`&page=${skip / take + 1}`
        +str
      ))
    
  }, [take, skip, dates, status, value]);

  const resubmitButton = (item: IConfigurationRequestsDataItem) => {
    return (
      <ConfigurationRequestActionCell
        dataItem={item}
        onResubmit={onResubmit}
      />
      )
  }

  const onResubmit = (item: IConfigurationRequestsDataItem) => {
    if (item.status !== RequestStatuses.InQueue.toString()) {
      dispatch(resubmitConfigurationRequest(item.id))
    }
  }

  useEffect(() => {
    if (configurationRequestsList) {
      const dataPageConfigurationRequests = configurationRequestsList.data.map(
        (item: IConfigurationRequestsDataItem, index: number) => ({
          ...item,
          indexNumber: skip + index + 1,
          resubmit: resubmitButton(item),
        })
      );
      
      setDataPageConfigurationRequests(dataPageConfigurationRequests);
      const total = configurationRequestsList?.total || PAGE_SIZE_SET[0].id;
      const pageSizeNew: IPageSizeSetItem[] = PAGE_SIZE_SET.reduce(
        (accum: IPageSizeSetItem[], current: IPageSizeSetItem) => {
          if (current?.value < total) {
            accum.push(current);
          }
          return accum;
        },
        []
      );
      const pageSizeResult = pageSizeNew.length
        ? [...pageSizeNew, { id: `${total}`, value: 'all' }]
        : [PAGE_SIZE_SET[0]];
      setPageSizeSet(pageSizeResult);
    }
  }, [configurationRequestsList]);

  const onDragOver = (event: any) => {
    const dataPageConfigurationRequests = event.newState.map(
      (item: IConfigurationRequestsDataItem, index: number) => ({
        ...item,
        indexNumber: skip + index + 1
      })
    );
    setDataPageConfigurationRequests(dataPageConfigurationRequests);
  };

  const onDragStart = () => {
    const dataPageConfigurationRequestsStart = dataPageConfigurationRequests.map(item => ({ ...item, isHiddenTooltip: true }))
    setDataPageConfigurationRequests(dataPageConfigurationRequestsStart)
  };

  const onDragEnd = () => {
    const dataPageConfigurationRequestsEnd = dataPageConfigurationRequests.map(item => ({ ...item, isHiddenTooltip: false }))
    setDataPageConfigurationRequests(dataPageConfigurationRequestsEnd)
  }

  const handlePageChange = (event: PageChangeEvent) => {
    setPageState({ ...pageState, skip: event.skip, take: event.take });
    dispatch(
      getConfigurationByRequest(
        `${organizationId}&pageSize=${event.take}&page=${event.skip / event.take + 1}${filterString}`
      )
    );
  };

  const onPageSizeChange = (e: DropDownListChangeEvent) => {
    const chosenValue = e.value;

    setPageSize(chosenValue);
    setPageState({ ...pageState, take: +chosenValue.id, skip: Math.floor(skip / +chosenValue.id) * +chosenValue.id });
  };

  const onStatusChange = React.useCallback((event: MultiSelectChangeEvent) => {
    const value = event.value;
    
    if (value && value[statusValue] === emptyItem[statusValue]) {
      return;
    } else if (Array.isArray(value) && value.length === 0) {
      setValue([emptyItem]);
    }
    setValue(value);
  }, []);

  return (
    <>
      <div className="btn-group filter-row" role="group">
        <div className="filter-row-left">
          <DateRangePicker
            className={'k-flex'}
            defaultValue={defaultValueDateState}
            onChange={onChangeDate}
            value={{ start: dates.start || new Date(), end: dates.end || new Date() }}
          />
          <div className="date-buttons">
            <button
              onClick={() => datePickButton(DateRanges.month)}
              name="month"
              disabled={isSelected.month ? true : false}
              className={isSelected.month ? "btn btn-primary" : "btn btn-outline-primary"}>
              month
            </button>
            <button
              onClick={() => datePickButton(DateRanges.week)}
              name="week"
              disabled={isSelected.week ? true : false}
              className={isSelected.week ? "btn btn-primary" : "btn btn-outline-primary"}>
              week
            </button>
            <button
              onClick={() => datePickButton(DateRanges.day)}
              name="day"
              disabled={isSelected.day ? true : false}
              className={isSelected.day ? "btn btn-primary" : "btn btn-outline-primary"}>
              day
            </button>
          </div>
          <MultiSelect
            className={"status-filter-multiselect"}
            onChange={onStatusChange}
            data={data}
            dataItemKey={statutsKey}
            textField={statusValue}
            value={value}
            filterable={true}
            autoClose={true}
          />
        </div>
        <div className="filter-row-right">
          <DropDownList
            style={{
              width: '100px'
            }}
            data={pageSizeSet}
            value={pageSize}
            textField="value"
            dataItemKey="id"
            onChange={onPageSizeChange}
          />
        </div>
      </div>
      <div className="configuration-requests-header-table">
        <div className="column-first k-grid-header justify-content-center">
          №
        </div>
        <div className="column-parameteres-changes k-grid-header justify-content-center">
          Parameter changes
        </div>
        <div className="column-date k-grid-header justify-content-center">
          Product / Version
        </div>
        <div className="column-date k-grid-header justify-content-center">
          Status
        </div>
        <div className="column-date k-grid-header justify-content-center">
          Date of creation
        </div>
        <div className="column-date k-grid-header justify-content-center">
          Action
        </div>
      </div>
      {isConfigurationRequestsListLoading ? (
        <div className="d-flex justify-content-center py-2">
          <Loader type="converging-spinner" />
        </div>
      ) : (
        <Sortable
          idField={'index'}
          data={dataPageConfigurationRequests}
          itemUI={SortableItemUI}
          onDragOver={onDragOver}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
        />
      )}
      <Pager
        skip={isConfigurationRequestsListLoading ? 0 : skip}
        take={isConfigurationRequestsListLoading ? 0 : take}
        info={true}
        previousNext={true}
        onPageChange={handlePageChange}
        total={
          isConfigurationRequestsListLoading
            ? 0
            : configurationRequestsList?.total || 0
        }
      />
    </>
  );
};

export default ConfigurationRequestsPage;
