import React, { useRef, useState } from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from 'react-bootstrap';
import { IDelOrder } from '../../../../../types/order';
import { IModalWrapperButton } from '../../../../../types/modal';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import { delOrder } from '../../../../../stores/order/reducer';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setError } from '../../../../../stores/common/reducer';
import Api from '../../../../../services/api/api';

interface IOrdersActionCell {
  gridProps: GridCellProps;
  onEditOrder: (orderId: string) => void;
  onCancel: () => void;
  onUpdateOrder: (orderId: string) => void;
}

const OrdersActionCell = ({
  gridProps,
  onEditOrder,
  onCancel,
  onUpdateOrder
}: IOrdersActionCell) => {
  const { dataItem } = gridProps;
  const dispatch = useDispatch()
  const { organizationId = '' } = useParams();
  const [isShowModal, setIsShowModal] = useState(false);

  const createLink = async (url: any) => {
    let element = document.createElement('a');
    element.setAttribute('href', url.data);
    element.setAttribute('download', "");
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const getOutputPath = async (configurationId: string, orderId: string) => {
    Api.getConfigurationOutput(configurationId, orderId)
      .then(result => {
        createLink(result)
      })
      .catch((e) => {
        setError(e);
      });
  };

  const onDownloadOutput = async (configurationId: string, orderId: string) => {
    await getOutputPath(configurationId, orderId)
  }

  const onDelete = () => {
    setIsShowModal(true)
  };

  const continueOperation = async () => {
    const sendData: IDelOrder = {
      orderId: dataItem.id,
      manufacturingOrganizationId: organizationId
    }
    dispatch(delOrder(sendData));
    closeModal();
  }

  const closeModal = () => {
    setIsShowModal(false);
  };

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueOperation
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeModal
    }
  ];

  return (
    <>
    <ModalWrapper
      headerText="Confirm action"
      isOpen={isShowModal}
      buttons={modalButtons}
    >
      <div>{"Are you sure you want to continue: delete "}
        <span className={"modal-wrapper-var"}>{dataItem.name}</span>
        {"?"}
      </div>
    </ModalWrapper>
    <td
      className={gridProps.className}
      colSpan={gridProps.colSpan}
      role="gridcell"
      aria-colindex={gridProps.ariaColumnIndex}
      aria-selected={gridProps.isSelected}
    >
      {dataItem.isInEdit ? (
        <>
          <Button
            variant="outline-secondary"
            className="k-m-1 pt-0 pb-0"
            onClick={() => onUpdateOrder(dataItem.id)}
          >
            Update
          </Button>
          <Button
            variant="outline-secondary"
            className="k-m-1 pt-0 pb-0"
            onClick={onCancel}
          >
            Cancel
          </Button>
        </>
      ) : (
        <>
          <div>
            <Button
              variant="outline-secondary"
              className="k-m-1 pt-0 pb-0 outline-secondary"
              onClick={() => onDownloadOutput(dataItem.configurationId, dataItem.id)}
            >
              <span className="k-icon k-i-download"></span>
            </Button>
          </div>
          <Button
            variant="outline-secondary"
            className="k-m-1 pt-0 pb-0"
            onClick={() => onEditOrder(dataItem.id)}
          >
            Edit
          </Button>
          <Button
            variant="outline-secondary"
            className="k-m-1 pt-0 pb-0"
            onClick={() => onDelete()}
          >
            Delete
          </Button>
        </>
      )}
    </td>
    </>
  );
};
export default OrdersActionCell;