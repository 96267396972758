import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';

const usePublications = () => {
  const publicationList = useSelector(
    (state: IState) => state.publications.publicationList
  );
  const dataState = useSelector(
    (state: IState) => state.publications.dataState
  );
  const isPublicationListLoading = useSelector(
    (state: IState) => state.publications.isPublicationListLoading
  );
  const publication = useSelector(
    (state: IState) => state.publications.publication
  );
  const isPublicationLoading = useSelector(
    (state: IState) => state.publications.isPublicationLoading
  );
  const onlineStores = useSelector(
    (state: IState) => state.publications.onlineStores
  );

  return {
    publicationList,
    dataState,
    isPublicationListLoading,
    publication,
    isPublicationLoading,
    onlineStores
  };
};

export default usePublications;
