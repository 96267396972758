import { BASE_URL } from '../../constants/api';

export const apiRoutes = {
  user: {
    signUp: `${BASE_URL}/User`,
    emailConfirm: (hash: string) => `${BASE_URL}/User/confirm?hash=${hash}`,
    emailChangeConfirm: `${BASE_URL}/User/confirmemailchange`
  },
  account: {
    root: `${BASE_URL}/Account`,
    changePassword: (oldPassword: string, newPassword: string) =>
      `${BASE_URL}/Account/changepassword?oldPassword=${oldPassword}&newPassword=${newPassword}`,
    changeEmail: `${BASE_URL}/Account/emailchange`
  },
  auth: {
    signIn: `${BASE_URL}/Authentication`,
    org: (id: string) =>
      `${BASE_URL}/Authentication/organization?organizationId=${id}`,
    pwdEmail: `${BASE_URL}/Authentication/PasswordRecovery`,
    pwdConfirm: `${BASE_URL}/Authentication/PasswordRecoveryConfirm`,
  },
  organisations: {
    root: `${BASE_URL}/Organization`,
    list: (userId: string) =>
      `${BASE_URL}/Organization/byuser?userId=${userId}`,
    byId: (organizationId: string) =>
      `${BASE_URL}/Organization?id=${organizationId}`,
    assignedUsersById: (parameters: string) =>
      `${BASE_URL}/Organization/assignedusers?${parameters}`,
    assignedUser: `${BASE_URL}/Organization/assigneduser`,
    deleteAssignedUser: (parameters: string) =>
      `${BASE_URL}/Organization/deleteassigneduser?${parameters}`,
    invite: (inviteId: string = '') =>
      `${BASE_URL}/Organization/invite${inviteId ? `?id=${inviteId}` : ''}`,
    acceptInvite: (inviteId: string = '') =>
      `${BASE_URL}/Organization/acceptinvite${
        inviteId ? `?id=${inviteId}` : ''
      }`,
    manufacturingbyuser: (userId: string = '') =>
      `${BASE_URL}/Organization/manufacturingbyuser${
        userId ? `?userId=${userId}` : ''
      }`,
    manufacturing: () => `${BASE_URL}/Organization/manufacturing`,
    designing: () => `${BASE_URL}/Organization/design`,
    designToManufacturer: () => `${BASE_URL}/Organization/design2manufacturer`,
    designToManufacturerById: (id: string) =>
      `${BASE_URL}/Organization/design2manufacturer${id ? `?organizationId=${id}` : ''}`,
    designToStore: () => `${BASE_URL}/Organization/design2store`,
    designToStoreId: (id: string) =>
      `${BASE_URL}/Organization/design2store${id ? `?onlineStoreId=${id}` : ''}`,
  },  
  tariff: {
    root: `${BASE_URL}/Tariff`,
    item: (id: string) => `${BASE_URL}/Tariff/${id}`,
  },
  product: {
    root: (dataString: string = '') =>
      `${BASE_URL}/Product${dataString ? '?' + dataString : ''}`,
    item: (id: string) => `${BASE_URL}/Product?${id}`
  },
  productVersion: {
    root: (dataString: string = '') =>
      `${BASE_URL}/ProductVersion${dataString ? '?' + dataString : ''}`,
    item: (id: string) => `${BASE_URL}/ProductVersion/${id}`
  },
  configurations: {
    root: (dataString: string = '') =>
      `${BASE_URL}/Configuration${dataString ? '?' + dataString : ''}`,
    item: (id: string) => `${BASE_URL}/Configuration/${id}`,
    params: (configurationId: string) =>
      `${BASE_URL}/Configuration/${configurationId}/parameters`,
    svf: (id: string, svfName?: string) =>
      `${BASE_URL}/Configuration/${id}/svf${svfName ? '/' + svfName : ''}`,
    deignGearLog: (id: string) =>
      `${BASE_URL}/Configuration/${id}/log/designgear`,
    forgeLog: (id: string) => `${BASE_URL}/Configuration/${id}/log/forge`,
    byRequest: (dataString: string) =>
      `${BASE_URL}/Configuration/request?${dataString}`,
    byRequestId: (id: string) => `${BASE_URL}/Configuration/request/${id}`,
    mass: () => `${BASE_URL}/Configuration/mass`,
    bulkdelete: () => `${BASE_URL}/Configuration/bulkdelete`,
    delete: (id: string, isTemplate: boolean) => `${BASE_URL}/Configuration/${id}/${isTemplate}`,
    recalculate: () => `${BASE_URL}/Configuration/recalculate`,
    generatesvf: () => `${BASE_URL}/Configuration/generatesvf`,
    telemetry: (organizationId: string, startDate: string, endDate: string) =>
      `${BASE_URL}/Configuration/telemetry/${organizationId}/${startDate}/${endDate}`,
    resubmit: (id: string) => `${BASE_URL}/Configuration/request/resubmit?id=${id}`,
    requestForgeLog: (id: string) => `${BASE_URL}/Configuration/request/${id}/log/forge`,
    outputforproduction: (configurationId: string, orderId: string) =>
      `${BASE_URL}/Configuration/${configurationId}/outputforproduction/${orderId}`,
  },
  appBundle: {
    root: (dataString: string = '') =>
      `${BASE_URL}/AppBundle${dataString ? '?' + dataString : ''}`,
    item: (id: string) => `${BASE_URL}/AppBundle/${id}`,
  },
  appBundleAlias: {
    root: (dataString: string = '') =>
      `${BASE_URL}/AppBundle/alias${dataString ? `?` + dataString : ''}`,
    byAppBundleId: (dataString: string = '') =>
      `${BASE_URL}/AppBundle/aliasbyappbundle/${dataString ? `?` + dataString : ''}`,
    item: (id: string) => `${BASE_URL}/AppBundle/alias/${id}`,
  },
  appBundleVersion: {
    root: (dataString: string = '') =>
      `${BASE_URL}/AppBundle/version/${dataString ? `?` + dataString : ''}`,
    item: (id: string) => `${BASE_URL}/AppBundle/version/${id}`,
  },
  publications: {
    root: (dataString: string = '') =>
      `${BASE_URL}/Publication${dataString ? '?' + dataString : ''}`,
    byId: (publicationId: string) => `${BASE_URL}/Publication/${publicationId}`,
    onlineStores: () => `${BASE_URL}/Publication/onlinestores`,
    manufacturer: (dataString: string = '') =>
      `${BASE_URL}/Publication/manufacturer${dataString ? '?' + dataString : ''}`
  },
  orders: {
    root: (dataString: string = '') =>
      `${BASE_URL}/Order${dataString ? '?' + dataString : ''}`,
    byId: (orderId: string) => `${BASE_URL}/Order${orderId}`
  },
  billing: {
    payTariff: (parameters: string) => `${BASE_URL}/Billing/PayTariff?${parameters}`,
    buyCloudPoints: (parameters: string) => `${BASE_URL}/Billing/BuyCloudPoints?${parameters}`,
    paymentHistory: (organizationId: string) => `${BASE_URL}/Billing/paymenthistory/${organizationId}`,
  }
};
