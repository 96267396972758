import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';

const useCommon = () => {
  const error = useSelector((state: IState) => state.common.error)
  const postReqResp = useSelector((state: IState) => state.common.postReqResp)
  const isLoadingDashboard = useSelector((state: IState) => state.common.isLoadingDashboard)
  const inPendingList = useSelector((state: IState) => state.common.inPendingList)

  return {
    error,
    postReqResp,
    isLoadingDashboard,
    inPendingList
  }
}

export default useCommon