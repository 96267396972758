import { GridCellProps } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import { ITariff } from '../../../../../types/tariff';

interface IBillingActionCell extends GridCellProps {
    buttonTariffHandler: (tariffId: string) => void;
    isTariffSet: boolean;
    onEdit: (dataItem: ITariff) => void,
    onDelete: (dataItem: ITariff) => void,
    dataItem: ITariff
}

const BillingActionsCell: FC<IBillingActionCell> = ({
    buttonTariffHandler,
    isTariffSet,
    onEdit,
    onDelete,
    dataItem,
    ...others
}) => {
    const { inPendingList } = useCommon()
    const isInPending = inPendingList.includes(dataItem?.id)
    return (
        <td
          className={others.className}
          colSpan={others.colSpan}
          role="gridCell"
          aria-colindex={others.ariaColumnIndex}
          aria-selected={others.isSelected}
        >
        {/*    <Button*/}
        {/*        variant="primary"*/}
        {/*        className={isTariffSet ? "selected-tariff-button ml-2 px-2 pt-0 pb-0" : "ml-2 px-2 pt-0 pb-0 btn-with-link"} */}
        {/*        onClick={() => buttonTariffHandler(dataItem.id)}*/}
        {/*    >*/}
        {/*  {!isTariffSet ? ("set tariff" ) : ("current tariff")}    */}
        {/*</Button>*/}
        <Button variant="outline-secondary" className='k-m-1 pt-0 pb-0' onClick={() => onEdit(dataItem)} disabled={isInPending}>
          Edit
        </Button>
        <Button variant="outline-secondary" className='k-m-1 pt-0 pb-0' onClick={() => onDelete(dataItem)}
          disabled={isInPending}
        >
          {isInPending ? <Loader type="pulsing" size="small" /> : 'Delete'}
        </Button>
        </td>
    );
};

export default BillingActionsCell;
