import {
  GridCellProps
} from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import { setPath } from '../../../../../helpers/routingFunctions/routingFunctions';
import { ERoutes } from '../../../../../router/Routes';
import {
  deleteConfiguration, putConfig
} from '../../../../../stores/productConfigurations/reducer';
import { IModalWrapperButton } from '../../../../../types/modal';
import {
  IConfigurationListItem,
  IDeleteConfiguration
} from '../../../../../types/producVersionConfigurations';

const ActionsConfigCell = (props: GridCellProps) => {
  const dispatch = useDispatch();

  const dataItem = { ...(props.dataItem as IConfigurationListItem) };
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);

  const { organizationId, productId, versionId } = useParams();

  const buttonTemplateHandler = (isSetTemmplate: boolean) => {
    const dataRequest = {
      id: dataItem.id,
      name: dataItem.configurationName,
      comment: dataItem.comment,
      useAsTemplateConfiguration: isSetTemmplate
    };
    if (versionId)
      dispatch(
        putConfig({ formData: dataRequest, productVersionId: versionId })
      );
  };

  const onDeleteClickConfiguration = () => {
    setIsShowModal(true)
  };

  const onDeleteClickTemplateConfiguration = () => {
    setIsShowConfirmModal(true)
  };

  const closeModal = () => {
    setIsShowModal(false);
  };

  const closeConfirmModal = () => {
    setIsShowConfirmModal(false);
  };

  const continueDelete = () => {
    let payload: IDeleteConfiguration = {
      id: dataItem.id,
      deleteTemplateConfiguration: false,
      versionId: versionId
    } as IDeleteConfiguration

    if (!dataItem.useAsTemplateConfiguration) {
      dispatch(deleteConfiguration(payload))
    } else if (dataItem.useAsTemplateConfiguration) {
      setIsShowConfirmModal(true)
      onDeleteClickTemplateConfiguration()
    }
    setIsShowModal(false)
  };

  const deleteTemplateConfiguration = () => {
    let payload: IDeleteConfiguration = {
      id: dataItem.id,
      deleteTemplateConfiguration: true,
      versionId: versionId
    } as IDeleteConfiguration
    dispatch(deleteConfiguration(payload))
    setIsShowConfirmModal(false)
  }

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueDelete
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeModal
    }
  ];

  const confirmModalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: deleteTemplateConfiguration
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeConfirmModal
    }
  ];

  return (
    <>
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowConfirmModal}
        buttons={confirmModalButtons}
      >
        <div>{"You are trying to delete template configuration "}
          <span className={"modal-wrapper-var"}>{dataItem.configurationName}</span>
          {"."}
          <br />{`New template configuration will be assigned automatically. Proceed?`}
        </div>
      </ModalWrapper>
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowModal}
        buttons={modalButtons}
      >
        <div>{"Are you sure you want to continue: delete "}
          <span className={"modal-wrapper-var"}>{dataItem.configurationName}</span>
          {"?"}
        </div>
      </ModalWrapper>

      <td
        className={props.className}
        colSpan={props.colSpan}
        role="gridCell"
        aria-colindex={props.ariaColumnIndex}
      >

        <Button
          variant="outline-secondary"
          className="k-m-1 pt-0 pb-0 btn-with-link"
        >
          <Link
            to={setPath(ERoutes.ProdVersionConfig, [
              organizationId,
              productId,
              versionId,
              dataItem.id
            ])}
          >
            Open
          </Link>
        </Button>
        <Button
          variant="outline-secondary"
          className="k-m-1 pt-0 pb-0 btn-with-link"
          onClick={() => onDeleteClickConfiguration()}
        >
          Delete
        </Button>
        {!dataItem.useAsTemplateConfiguration && !dataItem.deletedModel &&
          <Button
            variant="primary"
            className="ml-2 px-2 pt-0 pb-0 btn-with-link"
            onClick={() => buttonTemplateHandler(true)}
          >
            set as template
          </Button>
        }
      </td>
    </>
  );
};

export default ActionsConfigCell