import { Field, Form, FormElement } from '@progress/kendo-react-form';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CInput from '../../../../../components/form-components/CInput';
import { isEmpty, isGreaterThanMaxInt32, isGreaterThanMaxInt64, isNumberErrorMsg } from '../../../../../components/form-components/helpers/validation-functions';
import Spinner from '../../../../../components/Loaders/Spinner/Spinner';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import useTariff from '../../../../../helpers/hooks/storeHooks/useTariff';
import { setPostReqResp } from '../../../../../stores/common/reducer';
import { postTariff, putTariff } from '../../../../../stores/tariff/reducer';
import { ICommonModalProps, Nullable } from '../../../../../types/common';
import { IModalWrapperButton } from '../../../../../types/modal';
import { IPostTariff, IPutTariff, ITariff } from '../../../../../types/tariff';

interface ICreateTariff extends ICommonModalProps {
  tariffToUpdate?: Nullable<ITariff>
}

const CreateTariffModal: FC<ICreateTariff> = ({
  isOpen,
  onClose,
  tariffToUpdate,
}) => {
  const dispatch = useDispatch()
  const { isTariffLoading } = useTariff()
  const { postReqResp } = useCommon()
  const formRef = useRef<Form | null>(null)
  const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null)

  const headerText = tariffToUpdate ? 'Update tariff' : 'Create tariff';
  const [formState, setFormState] = useState({
    name: '',
    description: '',
    tariffPoints: '',
    billingPeriod: '',
    availableDiskSpace: ''
  })

  const modalButtons: IModalWrapperButton[] = [
    { buttonText: 'close', onButtonClick: () => onClose() },
    { buttonText: 'save', onButtonClick: () => formSubmitBtnRef?.current?.click(), buttonDisabled: isTariffLoading }
  ]

  useEffect(() => {
    if (isOpen && tariffToUpdate) {
      setFormState({
        name: tariffToUpdate.name,
        description: tariffToUpdate.description,
        tariffPoints: tariffToUpdate.tariffPoints.toString(),
        billingPeriod: tariffToUpdate.billingPeriod.toString(),
        availableDiskSpace: tariffToUpdate.availableDiskSpace.toString()
      })
    } else if (isOpen) {
      setFormState({
        name: '',
        description: '',
        tariffPoints: '',
        billingPeriod: '',
        availableDiskSpace: ''
      })
    }
  }, [isOpen])

  useEffect(() => {
    if (postReqResp) {
      onClose()
      dispatch(setPostReqResp(''))
    }
  }, [postReqResp, isTariffLoading])

  const onSubmitLocal = (formData: any) => {
    if (!formRef?.current?.isValid()) return

    const data = formData;
    
    if (!tariffToUpdate) {
      dispatch(postTariff(data as IPostTariff))
    }
    else {
      (data as IPutTariff).id = tariffToUpdate.id
      dispatch(putTariff(data))
    }
  }

  return (
    <ModalWrapper
      headerText={headerText}
      isOpen={isOpen}
      onClose={onClose}
      buttons={modalButtons}
      className={'create-tariff-form'}
    >
      <Form
        onSubmit={onSubmitLocal}
        ignoreModified={true}
        initialValues={formState}
        key={JSON.stringify(formState)}
        ref={formRef}
        render={(formRenderProps) => (
          <FormElement className={'modal-body'}>
            {isTariffLoading ? (
              <Spinner />
            ) : (
              <fieldset className={"k-form-fieldset"}>
                <div className="mb-3">
                  <Field
                    name="name"
                    component={CInput}
                    label="Tariff name"
                    validator={isEmpty}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="description"
                    component={CInput}
                    label="Description"
                    validator={isEmpty}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="tariffPoints"
                    component={CInput}
                    label="tariffPoints"
                    validator={[isNumberErrorMsg, isGreaterThanMaxInt32]}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="billingPeriod"
                    component={CInput}
                    label="billingPeriod"
                    validator={[isNumberErrorMsg, isGreaterThanMaxInt32]}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="availableDiskSpace"
                    component={CInput}
                    label="availableDiskSpace"
                    validator={[isNumberErrorMsg, isGreaterThanMaxInt64]}
                  />
                </div>
              </fieldset>
            )}
            <Button
              type="submit"
              hidden={true}
              ref={formSubmitBtnRef}
            >
              submit
            </Button>
          </FormElement>
        )}
      />
    </ModalWrapper>
  )
}

export default CreateTariffModal