import { all, call, put, takeLatest, select } from 'typed-redux-saga';
import Api from '../../services/api/api';
import {
  setError,
  setIsLoadingDashboard,
  setPostReqResp
} from '../common/reducer';
import {
  getOrganisations,
  getManufacturingOrganizationByUserId,
  getAllManufacturingOrganization,
  getAllDesigningOrganization,
  getOrganisationById,
  getAssignedUsersOrganisationById,
  putAssignedUser,
  deleteAssignedUser,
  inviteUser,
  setAssignedUsers,
  setIsLoadingAssignedUsers,
  isLoadingOrganisation,
  setLoadingOrganizationManufacturing,
  setLoadingOrganizationDesigning,
  postOrganisation,
  putOrganisation,
  saveOrganisations,
  setCurrentOrganisation,
  setOrganisationsManufacturing,
  setOrganisationsDesigning,
  setCreatedNowOrganization,
  setLoadingDesignToManufacturersOrganizations,
  setDesignToManufacturersOrganizations,
  getDesignToManufacturersOrganizations,
  deleteDesignToManufacturersBinding,
  postDesignToManufacturersBinding,
  setLoadingDesignToStores,
  setDesignToStores,
  getDesignToStores,
  postDesignToStoresBinding,
  deleteDesignToStoresBinding,
} from './reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  IDeleteAssignedUser,
  IPostOrganisation,
  IPutAssignedUser,
  IPutOrganisation
} from '../../types/organisationPage';
import { IState } from './../configureStore';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { IPostInviteUser } from './../../types/organisationPage';

function* getOrganisationsSaga({ payload: userId }: PayloadAction<string>) {
  try {
    yield put(isLoadingOrganisation(true));
    const response = yield* call(Api.getOrganisations, userId);
    yield put(saveOrganisations(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(isLoadingOrganisation(false));
  }
}

function* getManufacturingOrganizationByUserIdSaga({
  payload: userId
}: PayloadAction<string>) {
  try {
    yield put(setLoadingOrganizationManufacturing(true));
    const response = yield* call(
      Api.getManufacturingOrganizationByUserId,
      userId
    );
    yield* put(setOrganisationsManufacturing(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setLoadingOrganizationManufacturing(false));
  }
}

function* getAllManufacturingOrganizationSaga() {
  try {
    yield put(setLoadingOrganizationManufacturing(true));
    const response = yield* call(Api.getAllManufacturingOrganization);
    yield* put(setOrganisationsManufacturing(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setLoadingOrganizationManufacturing(false));
  }
}

function* getAllDesigningOrganizationSaga() {
  try {
    yield put(setLoadingOrganizationDesigning(true));
    const response = yield* call(Api.getAllDesigningOrganization);
    yield* put(setOrganisationsDesigning(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setLoadingOrganizationDesigning(false));
  }
}

function* getDesignToManufacturersOrganizationsSaga() {
  try {
    yield put(setLoadingDesignToManufacturersOrganizations(true));
    const response = yield* call(Api.getDesignToManufacturersOrganizations);
    yield* put(setDesignToManufacturersOrganizations(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setLoadingDesignToManufacturersOrganizations(false));
  }
}

function* postDesignToManufacturersBindingSaga({ payload: organizationId
}: PayloadAction<string>) {
  try {
    yield put(setLoadingDesignToManufacturersOrganizations(true));
    const response = yield* call(Api.postDesignToManufacturersOrganizations, organizationId);
    yield* put(getDesignToManufacturersOrganizations())
    yield put(setPostReqResp(response))
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setLoadingDesignToManufacturersOrganizations(false));
  }
}

function* deleteDesignToManufacturersBindingSaga({ payload: organizationId
}: PayloadAction<string>) {
  try {
    yield put(setLoadingDesignToManufacturersOrganizations(true));
    const response = yield* call(Api.deleteDesignToManufacturersOrganizations, organizationId);
    yield* put(getDesignToManufacturersOrganizations())
    yield put(setPostReqResp(response))
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setLoadingDesignToManufacturersOrganizations(false));
  }
}

function* getDesignToStoresSaga() {
  try {
    yield put(setLoadingDesignToStores(true));
    const response = yield* call(Api.getDesignToStores);
    yield* put(setDesignToStores(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setLoadingDesignToStores(false));
  }
}

function* postDesignToStoresBindingSaga({ payload: organizationId
}: PayloadAction<string>) {
  try {
    yield put(setLoadingDesignToStores(true));
    const response = yield* call(Api.postDesignToStores, organizationId);
    yield* put(getDesignToStores())
    yield put(setPostReqResp(response))
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setLoadingDesignToStores(false));
  }
}

function* deleteDesignToStoresBindingSaga({ payload: organizationId
}: PayloadAction<string>) {
  try {
    yield put(setLoadingDesignToStores(true));
    const response = yield* call(Api.deleteDesignToStores, organizationId);
    yield* put(getDesignToStores())
    yield put(setPostReqResp(response))
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setLoadingDesignToStores(false));
  }
}

function* getOrganisationByIdSaga({
  payload: organisationId
}: PayloadAction<string>) {
  try {
    yield put(setIsLoadingDashboard(true));
    const response = yield* call(Api.getOrganisationById, organisationId);
    yield put(setCurrentOrganisation(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsLoadingDashboard(false));
  }
}

function* getAssignedUsersOrganisationByIdSaga({
  payload: organisationId
}: PayloadAction<string>): any {
  try {
    const dataState = yield select(
      (state: IState) => state.organisation.dataStateAssignedUsers
    );
    const dataString: string = toDataSourceRequestString({ ...dataState });
    const idParam = `&organizationId=${organisationId}`;
    yield put(setIsLoadingAssignedUsers(true));
    const response = yield* call(
      Api.getAssignedUsersOrganisationById,
      dataString + idParam
    );
    yield put(setAssignedUsers(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsLoadingAssignedUsers(false));
  }
}

function* putAssignedUserSaga({
  payload: formData
}: PayloadAction<IPutAssignedUser>) {
  try {
    yield put(setIsLoadingAssignedUsers(true));
    yield* call(Api.putAssignedUser, formData);
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsLoadingAssignedUsers(false));
  }
}

function* deleteAssignedUserSaga({
  payload: formData
}: PayloadAction<IDeleteAssignedUser>) {
  try {
    const parameters = `organizationId=${formData.organizationId}&userId=${formData.userId}`;
    yield put(setIsLoadingAssignedUsers(true));
    yield* call(Api.deleteAssignedUser, parameters);
    yield* call(getAssignedUsersOrganisationByIdSaga, {
      payload: formData.organizationId,
      type: getAssignedUsersOrganisationById.type
    });
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsLoadingAssignedUsers(false));
  }
}

function* inviteUserSaga({
  payload: formData
}: PayloadAction<IPostInviteUser>) {
  try {
    yield put(setIsLoadingAssignedUsers(true));
    yield* call(Api.inviteUser, formData);
    yield put(setPostReqResp(200));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsLoadingAssignedUsers(false));
  }
}

function* postOrganisationSaga({
  payload: organisation
}: PayloadAction<IPostOrganisation>) {
  try {
    yield put(isLoadingOrganisation(true));
    const createdOrganizationId = yield* call(Api.postOrganisation, organisation);
    yield put(setCreatedNowOrganization(createdOrganizationId));
    yield put(getOrganisations(organisation.userId));
    put(getOrganisations(organisation.userId));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(isLoadingOrganisation(false));
  }
}

function* putOrganisationSaga({
  payload: formData
}: PayloadAction<IPutOrganisation>) {
  try {
    yield put(setIsLoadingDashboard(true));
    yield* call(Api.putOrganisation, formData);
    yield put(setCreatedNowOrganization(''));
    yield* call(getOrganisationByIdSaga, {
      payload: formData.id,
      type: getOrganisationById.type
    });
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsLoadingDashboard(false));
  }
}

function* organisationWatcher() {
  yield all([
    takeLatest(getOrganisations.type, getOrganisationsSaga),
    takeLatest(getOrganisationById.type, getOrganisationByIdSaga),
    takeLatest(postOrganisation.type, postOrganisationSaga),
    takeLatest(putOrganisation.type, putOrganisationSaga),
    takeLatest(
      getAssignedUsersOrganisationById.type,
      getAssignedUsersOrganisationByIdSaga
    ),
    takeLatest(putAssignedUser.type, putAssignedUserSaga),
    takeLatest(deleteAssignedUser.type, deleteAssignedUserSaga),
    takeLatest(inviteUser.type, inviteUserSaga),
    takeLatest(
      getManufacturingOrganizationByUserId.type,
      getManufacturingOrganizationByUserIdSaga
    ),
    takeLatest(
      getAllManufacturingOrganization.type,
      getAllManufacturingOrganizationSaga
    ),
    takeLatest(
      getAllDesigningOrganization.type,
      getAllDesigningOrganizationSaga
    ),
    takeLatest(
      getDesignToManufacturersOrganizations.type,
      getDesignToManufacturersOrganizationsSaga
    ),
    takeLatest(
      postDesignToManufacturersBinding.type,
      postDesignToManufacturersBindingSaga
    ),
    takeLatest(
      deleteDesignToManufacturersBinding.type,
      deleteDesignToManufacturersBindingSaga
    ),
    takeLatest(
      getDesignToStores.type,
      getDesignToStoresSaga
    ),
    takeLatest(
      postDesignToStoresBinding.type,
      postDesignToStoresBindingSaga
    ),
    takeLatest(
      deleteDesignToStoresBinding.type,
      deleteDesignToStoresBindingSaga
    )

  ]);
}

export default organisationWatcher;
