import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ERoutes } from './Routes';
import ProtectedRoutes from './components/protected-routes';
import LoginPage from '../pages/AuthPages/LoginPage/LoginPage';
import OrganisationPage from '../pages/OrganisationPage/OrganisationPage';
import GuestRoutes from './components/guest-routes';
import DashboardPage from '../pages/DashboardPage/DashboardPage';
import StatisticsPage from '../pages/DashboardPage/pages/StatisticsPage/StatisticsPage';
import ProductsPage from '../pages/DashboardPage/pages/ProductsPage/ProductsPage';
import PublicationsPage from '../pages/DashboardPage/pages/PublicationsPage/PublicationsPage';
import CPThresholdPage from '../pages/DashboardPage/pages/CPThresholdPage/CPThresholdPage';
import UsersPage from '../pages/DashboardPage/pages/UsersPage/UsersPage';
import BillingPage from '../pages/DashboardPage/pages/BillingPage/BillingPage';
import RegistrationPage from '../pages/AuthPages/RegistrationPage/RegistartionPage';
import ForgotPassword from '../pages/AuthPages/ForgotPassword/ForgotPassword';
import VersionsPage from '../pages/DashboardPage/pages/ProdVersionsPage/VersionsPage';
import ProdVersionPage from '../pages/DashboardPage/pages/ProdVersionPage/ProdVersionPage';
import ProductConfigurationPage from '../pages/DashboardPage/pages/ProductConfigurationPage/ProductConfigurationPage';
import ManageAccountPage from '../pages/ManageAccountPage/ManageAccountPage';
import InfoDetailsPage from '../pages/ManageAccountPage/pages/InfoDetailsPage/InfoDetailsPage';
import SecurityPage from '../pages/ManageAccountPage/pages/SecurityPage/SecurityPage';
import ResetPasswordPage from '../pages/AuthPages/ResetPasswordPage/ResetPasswordPage';
import ConfigurationRequestsPage from '../pages/DashboardPage/pages/ConfigurationRequestsPage/ConfigurationRequestsPage';
import SettingsPage from '../pages/DashboardPage/pages/SettingsPage/SettingsPage';
import AddUserToOrganizationPage from './../pages/AddUserToOrganizationPage/AddUserToOrganizationPage';
import DevelopersPage from '../pages/DashboardPage/pages/DevelopersPage/DevelopersPage';
import ManufacturesPage from './../pages/DashboardPage/pages/ManufacturesPage/ManufacturesPage';
import OrdersPage from './../pages/DashboardPage/pages/OrdersPage/OrdersPage';
import AppBundleAliasPage from '../pages/DashboardPage/pages/AppBundleAliasPage/AppBundleAliasPage';
import AppBundlePage from '../pages/DashboardPage/pages/AppBundlePage/AppBundlePage';
import BillingDetailPage from "../pages/ManageAccountPage/pages/BillingDetailPage/BillingDetailPage";
import PageNotFound from '../pages/ErrorPage/PageNotFound';
import RedirectRoutes from './components/redirect-routes';
import OnlineStoresPage from '../pages/DashboardPage/pages/OnlineStoresPage/OnlineStoresPage';
import EmailConfirmationPage from '../pages/AuthPages/EmailConfirmation/EmailConfirmationPage';

const RouteList = () => {
  return (
    <Routes>
      {/*Guest Routes*/}
      <Route element={<GuestRoutes />}>
        <Route index element={<LoginPage />} />
        <Route path={ERoutes.SignUp} element={<RegistrationPage />} />
        <Route path={ERoutes.SignUpId} element={<RegistrationPage />} />
        <Route path={ERoutes.ForgotPwd} element={<ForgotPassword />} />
        <Route path={ERoutes.ResetPwd} element={<ResetPasswordPage />} />
        <Route path={ERoutes.EmailConfirmation} element={<EmailConfirmationPage />} />
        <Route path={ERoutes.EmailChangeConfirmation} element={<EmailConfirmationPage />} />
      </Route>

      {/*Protected Routes*/}
      <Route element={<ProtectedRoutes isAllowed={true} />}>
        <Route path={ERoutes.Organisations} element={<OrganisationPage />} />
        <Route path={ERoutes.Dashboard} element={<DashboardPage />}>

          {/* <Route index element={<StatisticsPage />} /> */}
          <Route element={<RedirectRoutes isAllowed={true} />}>          
            <Route path={ERoutes.Products} element={<ProductsPage />} />
            <Route path={ERoutes.Publications} element={<PublicationsPage />} />
            <Route path={ERoutes.Users} element={<UsersPage />} />
            <Route path={ERoutes.Orders} element={<OrdersPage />} />
          
            <Route path={ERoutes.Statistics} element={<StatisticsPage />} />
            <Route path={ERoutes.Settings} element={<SettingsPage />} />
            
            <Route path={ERoutes.ProdVersions} element={<VersionsPage />} />
            <Route path={ERoutes.ProdVersion} element={<ProdVersionPage />} />
            
            <Route path={ERoutes.CPThreshold} element={<CPThresholdPage />} />
            
            <Route path={ERoutes.Billing} element={<BillingPage />} />
            <Route path={ERoutes.AppBundles} element={<AppBundlePage />} />
            <Route path={ERoutes.AppBundleId} element={<AppBundleAliasPage />} />
            <Route path={ERoutes.Tariffs} element={<BillingPage />} />
            <Route
              path={ERoutes.ConfigurationRequests}
              element={<ConfigurationRequestsPage />}
            />
            <Route path={ERoutes.Developers} element={<DevelopersPage />} />
            <Route path={ERoutes.Manufacturers} element={<ManufacturesPage />} />
            <Route path={ERoutes.Onlinestores} element={<OnlineStoresPage />} />
          </Route>
        </Route>
        <Route
          path={ERoutes.ProdVersionConfig}
          element={<ProductConfigurationPage />}
        />

        <Route path={ERoutes.ManageAccount} element={<ManageAccountPage />}>
          <Route index element={<InfoDetailsPage />} />
          <Route path={ERoutes.AccountSecurity} element={<SecurityPage />} />
          <Route path={ERoutes.AccountBilling} element={<BillingDetailPage/>} />
        </Route>
      </Route>

      <Route
        path={ERoutes.LinkByInviteId}
        element={<AddUserToOrganizationPage />}
      />
      <Route path="*" element={<PageNotFound />} />
    </Routes >
  );
};

export default RouteList;
