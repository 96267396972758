import useAuthCheck from '../../helpers/hooks/storeHooks/useAuthCheck';
import {useDispatch, useSelector} from 'react-redux';
import {IState} from '../../stores/configureStore';
import React, {useEffect, useState} from 'react';
import {Button} from 'react-bootstrap';
import CreateNewOrganisationModal from './Modals/CreateNewOrganisationModal';
import TariffPick from '../DashboardPage/pages/BillingPage/modals/PickTariff'
import {ERoutes} from '../../router/Routes';
import {
  getManufacturingOrganizationByUserId,
  getOrganisations,
  setCurrentOrganisation
} from '../../stores/organisation/reducer';
import {authOrg, setInviteInfo} from '../../stores/authentication/reducer';
import {useNavigate} from 'react-router-dom';
import {setPostReqResp} from '../../stores/common/reducer';
import AddUserToOrganizationModal from '../AddUserToOrganizationPage/components/AddUserToOrganizationModal';
import useOrganizations from '../../helpers/hooks/storeHooks/useOrganizations';
import {setPath} from '../../helpers/routingFunctions/routingFunctions';
import {EOrganisationTypes, IPutOrganisation} from '../../types/organisationPage';

const OrganisationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {organizations, organizationsManufacturing, createdOrganizationId} =
    useOrganizations();
  const postResp = useSelector((state: IState) => state.common.postReqResp);
  const {user, authLoading, inviteInfo} = useAuthCheck();
  const [isShowInviteModal, setIsShowInviteModal] = useState(false);

  const [isShowCreateOrgModal, setShowCreateOrgModal] = useState(false);
  const [pressedOrgId, setPressedOrgId] = useState('');
  const [pressedOrgType, setPressedOrgType] = useState('');

  const [isShowPickTariffModal, setShowPickTariffModal] = useState(false);

  useEffect(() => {
    dispatch(setCurrentOrganisation(null))
  }, [])

  useEffect(() => {
    if (user) {
      dispatch(getOrganisations(user?.id));
      dispatch(getManufacturingOrganizationByUserId(user?.id));
    }
  }, [dispatch]);

  useEffect(() => {
    if (inviteInfo) {
      setIsShowInviteModal(true);
    } else {
      setIsShowInviteModal(false);
    }
  }, [inviteInfo]);

  useEffect(() => {
    if (postResp && pressedOrgId) {
      if (pressedOrgType == 'developers') {
        navigate(`${setPath(ERoutes.Dashboard, [pressedOrgId])}/products`);
      } else {
        navigate(`${setPath(ERoutes.Dashboard, [pressedOrgId])}/orders`);
      }
      dispatch(setPostReqResp(''));
    }
  }, [postResp]);

  const [orgData, setOrgData] = useState<IPutOrganisation>();

  useEffect(() => {
    const createdOrganization = organizations.find(x => x.id == createdOrganizationId && x.orgType == EOrganisationTypes.Design);
    if (createdOrganization)
      setOrgData(createdOrganization)
  }, [organizations])

  const onPickTariffClose = () => setShowPickTariffModal(false);

  const onCreateOrganisation = () => setShowCreateOrgModal(true);
  const onCreateOrganisationClose = () => {
    setShowCreateOrgModal(false);
    if (!orgData?.tariffId)
      setShowPickTariffModal(true);
  }

  const onLoginSpacePress = (id: string, orgType: string) => {
    setPressedOrgId(id);
    setPressedOrgType(orgType);
    dispatch(authOrg(id));
  };

  const onExit = () => {
    setIsShowInviteModal(false);
    if (user) {
      dispatch(getOrganisations(user?.id));
    }
    dispatch(setInviteInfo(null));
  };

  const organisationsList = () => {
    return (
      <div className="organisations-list">
        <div className="org-list-header">
          <h6>Design</h6>
        </div>
        <div className="organisation-rows">
          {organizations.map((org, index) => {
            return (
              <div className="organisation-single-row" key={index}>
                <div className="organisation-item-left">
                  <h6>{org.name}</h6>
                  <div>{org.description}</div>
                </div>
                <div>
                  <Button
                    onClick={() => onLoginSpacePress(org.id, 'developers')}
                    disabled={authLoading}
                  >
                    Log into space
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const organisationsManufacturingList = () => {
    return (
      <div className="organisations-list">
        <div className="org-list-header">
          <h6>Manufacturers</h6>
        </div>
        <div className="organisation-rows">
          {organizationsManufacturing.map((org, index) => {
            return (
              <div className="organisation-single-row" key={index}>
                <div className="organisation-item-left">
                  <h6>{org.name}</h6>
                  <div>{org.description}</div>
                </div>
                <div>
                  <Button
                    onClick={() => onLoginSpacePress(org.id, 'manufacturers')}
                    disabled={authLoading}
                  >
                    Log into space
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="organisations-page">
      <h3>Welcome back, {user?.firstName}</h3>
      <div className="mt-3">
        <h6>Workspaces for {user?.email}</h6>
      </div>
      <div className="organizations-container">
        <div className="org-list-wrapper mt-5">
          {organizations.length ? (
            organisationsList()
          ) : (
            <h6>No organisations are created for now</h6>
          )}
        </div>

        <div className="org-list-wrapper mt-5">
          {organizationsManufacturing.length ? (
            organisationsManufacturingList()
          ) : (
            <h6>No organisations are created for now</h6>
          )}
        </div>
      </div>

      <br/>
      {!!organizations.length && <h6>Or Just</h6>}
      <div>
        <br/>
        <Button variant="primary" onClick={onCreateOrganisation}>
          Create new organisation
        </Button>
      </div>
      <CreateNewOrganisationModal
        onClose={onCreateOrganisationClose}
        isOpen={isShowCreateOrgModal}
        onSubmit={onCreateOrganisationClose}
      />
      {/*<TariffPick*/}
      {/*  organization={orgData as IPutOrganisation}*/}
      {/*  onExit={onPickTariffClose}*/}
      {/*  isOpen={isShowPickTariffModal}*/}
      {/*/>*/}
      {isShowInviteModal && (
        <AddUserToOrganizationModal
          inviteId={inviteInfo?.id}
          isOpen={isShowInviteModal}
          onExit={onExit}
        />
      )}
    </div>
  );
};
export default OrganisationPage;
