import { all, call, put, select, takeLatest } from 'typed-redux-saga';
import { setError, setPostReqResp } from '../common/reducer';
import Api from '../../services/api/api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  getPublicationList,
  getPublicationById,
  deletePublicationById,
  getPublicationOnlineStores,
  putPublication,
  postPublication,
  setPublicationList,
  setPublicationListLoading,
  setPublicationLoading,
  setPublication,
  setPublicationOnlineStores,
  getPublicationManufacturerList
} from './reducer';
import { IState } from '../configureStore';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import { IPostPublication, IPutPublication } from '../../types/publications';

function* getPublicationListSaga(): any {
  try {
    const dataState = yield select(
      (state: IState) => state.publications.dataState
    );
    const dataString: string = toDataSourceRequestString({ ...dataState });
    yield put(setPublicationListLoading(true));
    const response = yield* call(Api.getPublicationList, dataString);
    yield put(setPublicationList(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setPublicationListLoading(false));
  }
}

function* getPublicationManufacturerListSaga(): any {
  try {
    const dataState = yield select(
      (state: IState) => state.publications.dataState
    );
    const dataString: string = toDataSourceRequestString({ ...dataState });
    yield put(setPublicationListLoading(true));
    const response = yield* call(Api.getPublicationManufacturerList, dataString);
    yield put(setPublicationList(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setPublicationListLoading(false));
  }
}

function* getPublicationByIdSaga({
  payload: publicationId
}: PayloadAction<string>): any {
  try {
    yield put(setPublicationLoading(true));
    const response = yield* call(Api.getPublicationById, publicationId);
    yield put(setPublication(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setPublicationListLoading(false));
  }
}

function* deletePublicationByIdSaga({
  payload: publicationId
}: PayloadAction<string>) {
  try {
    yield put(setPublicationListLoading(true));
    yield* call(Api.deletePublicationById, publicationId);
    yield* call(getPublicationListSaga, { type: getPublicationList.type });
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setPublicationListLoading(false));
  }
}

function* getPublicationOnlineStoresSaga() {
  try {
    yield put(setPublicationLoading(true));
    const response = yield* call(Api.getPublicationOnlineStores);
    yield put(setPublicationOnlineStores(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setPublicationLoading(false));
  }
}

function* putPublicationSaga({
  payload: formData
}: PayloadAction<IPutPublication>) {
  try {
    yield put(setPublicationLoading(true));
    const response = yield* call(Api.putPublication, formData);
    yield* call(getPublicationListSaga, { type: getPublicationList.type });
    yield put(setPostReqResp(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setPublicationLoading(false));
  }
}

function* postPublicationSaga({
  payload: formData
}: PayloadAction<IPostPublication>) {
  try {
    yield put(setPublicationLoading(true));
    const response = yield* call(Api.postPublication, formData);
    yield put(setPostReqResp(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setPublicationLoading(false));
  }
}

function* publicationWatcher() {
  yield all([
    takeLatest(getPublicationList.type, getPublicationListSaga),
    takeLatest(getPublicationManufacturerList.type, getPublicationManufacturerListSaga),
    takeLatest(deletePublicationById.type, deletePublicationByIdSaga),
    takeLatest(getPublicationById.type, getPublicationByIdSaga),
    takeLatest(getPublicationOnlineStores.type, getPublicationOnlineStoresSaga),
    takeLatest(putPublication.type, putPublicationSaga),
    takeLatest(postPublication.type, postPublicationSaga)
  ]);
}

export default publicationWatcher;
