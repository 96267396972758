import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';
import useAuthCheck from '../../helpers/hooks/storeHooks/useAuthCheck';
import { getInviteInfoByInviteId } from '../../stores/authentication/reducer';
import { ERoutes } from '../../router/Routes';
import AddUserToOrganizationModal from './components/AddUserToOrganizationModal';

const AddUserToOrganizationPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { inviteId } = useParams();
  const { authenticated, inviteInfo } = useAuthCheck();

  useEffect(() => {
    if (!authenticated) {
      navigate('/');
    } else {
      navigate(`${ERoutes.Organisations}`);
    }
  }, [inviteInfo]);

  useEffect(() => {
    if (inviteId) {
      dispatch(getInviteInfoByInviteId(inviteId));
    }
  }, [inviteId]);

  return (
    <div>
      <AddUserToOrganizationModal
        isOpen={authenticated}
        inviteId={inviteId}
        onExit={() => navigate(ERoutes.Organisations)}
      />
    </div>
  );
};

export default AddUserToOrganizationPage;
