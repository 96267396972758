import { useDispatch } from 'react-redux';
import React, { createContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import GridLoader from '../../../../components/Loaders/GridLoader/GridLoader';
import { IDesignToManufacturersOrganizations } from '../../../../types/organisationPage';
import useOrganizations from '../../../../helpers/hooks/storeHooks/useOrganizations';
import { getDesignToManufacturersOrganizations, setDataStateAssignedUsers } from '../../../../stores/organisation/reducer';
import CreateOrganizationBinding from './modals/CreateOrganizationBinding';
import ManufacturersActionCell from './componets/ManufacturersActionCell';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';

const ManufacturesPage = () => {
  const dispatch = useDispatch()
  const { designToManufacturersOrganizations,
    isLoadingDesignToManufacturersOrganizations,
    currentOrganization
    } = useOrganizations()
  const [isShowCreateBindingModal, setIsShowCreateBindingModal] = useState(false)
  const [gridData, setGridData] = useState<IDesignToManufacturersOrganizations[]>([])
  const [isAdministrator, setIsAdministrator] = useState(false);

  useEffect(() => {
    setIsAdministrator(currentOrganization?.orgUserRole === 1);
  }, [currentOrganization]);

  useEffect(() => {
    dispatch(getDesignToManufacturersOrganizations())
  }, [])

  useEffect(() => {
    setGridData(designToManufacturersOrganizations ?? [])
  }, [designToManufacturersOrganizations])

  const onCreateOrganizationBindingClick = () => {
    setIsShowCreateBindingModal(!isShowCreateBindingModal)
  }

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    dispatch(setDataStateAssignedUsers(e.dataState as any))
    dispatch(getDesignToManufacturersOrganizations())
  }
  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';
    const newData = gridData.map(item =>
      item.id === event.dataItem.id
        ? { ...item, [field]: event.value }
        : item
    );
    setGridData(newData)
  };

  return (
    <div>
      <div className="version-table">
          <Grid
            className="product-grid version-grid"
            data={gridData}
            pageable={true}
            onItemChange={itemChange}
            onDataStateChange={onDataStateChange}
          >
          {isAdministrator && (
            <GridToolbar>
              <Button variant="primary" onClick={onCreateOrganizationBindingClick}>
                Add binding
              </Button>
            </GridToolbar>
          )}
            <GridNoRecords>
              <GridLoader isLoading={isLoadingDesignToManufacturersOrganizations} />
            </GridNoRecords>
            <GridColumn field="name" title="Name" />
            <GridColumn field="description" title="Description" />
          {isAdministrator && (
              <GridColumn field="action" title="Action" className="action-cell" cell={ManufacturersActionCell} sortable={false} />
          )}
          </Grid>
      </div>
      {
        isShowCreateBindingModal &&
        <CreateOrganizationBinding isOpen={isShowCreateBindingModal} onClose={onCreateOrganizationBindingClick} />
      }
      <ErrorMessage style="mt-3" />
    </div>
  )
}

export default ManufacturesPage