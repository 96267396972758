import { IUser, EOrgUserRoles } from './user';

export interface IOrganisation {
  id: string;
  name: string;
  description: string;
  orgType: EOrganisationTypes;
  tariffId: string;
  orgUserRole: EOrgUserRoles;
  isActive: boolean;
}

export interface IOrganisationManufacturing {
  id: string;
  name: string;
  description: string;
  orgType: EOrganisationTypes;
  tariffId: string;
}

export interface IOrganisationDesigning {
  id: string;
  name: string;
  description: string;
  orgType: EOrganisationTypes;
  tariffId: string;
  orgUserRole: EOrgUserRoles;
}

export interface IDesignToManufacturersOrganizations {
  id: string;
  name: string;
  description: string;
  orgType: EOrganisationTypes;
}

export interface IDesignToStores {
  id: string;
  name: string;
  domain: string;
  created: string;
}

export interface IPostOrganisation {
  name: string;
  description: string;
  orgType: EOrganisationTypes;
  userId: string;
  tariffId?: string;
}

export interface IPutOrganisation {
  id: string;
  name: string;
  description: string;
  isActive: boolean;
  tariffId?: string;
}

export interface IPutAssignedUser {
  organizationId: string;
  userId: string;
  orgUserRole: EOrgUserRoles;
}

export interface IDeleteAssignedUser {
  organizationId: string;
  userId: string;
}

export interface IAssignedUsersList {
  data: IUser[];
  total: number;
}

export interface IPostInviteUser {
  organizationId: string;
  email: string;
  orgUserRole: EOrgUserRoles;
}

export enum EOrganisationTypes {
  Design = 0,
  Manufacturing = 1,
  Sales = 2
}
