import React, {createContext, useEffect, useState} from 'react';
import {useDispatch} from 'react-redux';
import {
  deletePublicationById,
  getPublicationList,
  setPublicationDataState,
  setDataState,
  getPublicationManufacturerList,
} from '../../../../stores/publication/reducer';
import usePublications from '../../../../helpers/hooks/storeHooks/usePublcations';
import {
  Grid,
  GridToolbar,
  GridNoRecords,
  GridColumn,
  GridDataStateChangeEvent,
  GridItemChangeEvent,
  GridCellProps
} from '@progress/kendo-react-grid';
import GridLoader from '../../../../components/Loaders/GridLoader/GridLoader';
import {DateCell} from '../../../../components/grid-components/DateCell';
import {IPublication} from '../../../../types/publications';
import {IGridDataState, Nullable} from '../../../../types/common';
import PublicationActionCell from './components/PublicationActionCell';
import {IModalWrapperButton} from '../../../../types/modal';
import ModalWrapper from '../../../../components/ModalWrapper/ModalWrapper';
import CreateEditPublicationForm from './components/modals/CreateEditPublicationForm';
import {IPageSizeSetItem, PAGE_SIZE_SET} from '../../../../types/producVersionConfigurations';
import {DropDownList, DropDownListChangeEvent} from '@progress/kendo-react-dropdowns';
import useOrganizations from "../../../../helpers/hooks/storeHooks/useOrganizations";
import {EOrganisationTypes} from "../../../../types/organisationPage";

const PublicationsPage = () => {
  const dispatch = useDispatch();
  const {currentOrganization} = useOrganizations();
  const {isPublicationListLoading, publicationList, dataState} =
    usePublications();
  const [gridData, setGridData] = useState<IPublication[]>([]);
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);
  const [isShowCreateEditModal, setIsShowCreateEditModal] = useState(false);
  const [editOrDeletePublication, setEditOrDeletePublication] =
    useState<Nullable<IPublication>>(null);
  const [pageSize, setPageSize] = useState<IPageSizeSetItem>(PAGE_SIZE_SET[0])
  const [pageSizeSet, setPageSizeSet] = useState<IPageSizeSetItem[]>(PAGE_SIZE_SET)

  useEffect(() => {
    const total = publicationList?.total || PAGE_SIZE_SET[0].id
    const pageSizeNew: IPageSizeSetItem[] = PAGE_SIZE_SET.reduce((accum: IPageSizeSetItem[], current: IPageSizeSetItem) => {
      if (current?.value < total) {
        accum.push(current)
      }
      return accum
    }, [])

    const pageSizeResult = pageSizeNew.length ? [...pageSizeNew, {id: `${total}`, value: 'all'}] : [PAGE_SIZE_SET[0]]
    setPageSizeSet(pageSizeResult)
    setGridData(publicationList?.data ?? []);
  }, [publicationList]);

  useEffect(() => {
    const newDataState = {
      ...dataState,
      take: +pageSize.id,
      skip: Math.floor(dataState.skip / +pageSize.id) * +pageSize.id
    }
    dispatch(setDataState(newDataState))

    if (currentOrganization?.orgType === EOrganisationTypes.Design) {
      dispatch(getPublicationList());
    } else if (currentOrganization?.orgType === EOrganisationTypes.Manufacturing) {
      dispatch(getPublicationManufacturerList());
    }
  }, [pageSize, currentOrganization])

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    dispatch(setPublicationDataState(e.dataState as any));
    if (currentOrganization?.orgType === EOrganisationTypes.Design) {
      dispatch(getPublicationList());
    } else if (currentOrganization?.orgType === EOrganisationTypes.Manufacturing) {
      dispatch(getPublicationManufacturerList());
    }
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';
    const newData = gridData.map((item) =>
      item.id === event.dataItem.id ? {...item, [field]: event.value} : item
    );
    setGridData(newData);
  };

  const continueDelete = () => {
    dispatch(deletePublicationById(editOrDeletePublication?.id || ''));
    setEditOrDeletePublication(null);
    setIsShowModalDelete(false);

  };

  const modalDeleteButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueDelete
    },
    {
      buttonText: 'Cancel',
      onButtonClick: () => setIsShowModalDelete(false)
    }
  ];

  const onDeleteClickPublication = (publication: IPublication) => {
    setIsShowModalDelete(true);
    setEditOrDeletePublication(publication);
  };

  const onEditClickPublication = (publication: IPublication) => {
    setIsShowCreateEditModal(true)
    setEditOrDeletePublication(publication);
  };

  const onPageSizeChange = (e: DropDownListChangeEvent) => {
    const chosenValue = e.value
    setPageSize(chosenValue)
  }

  const changeBooleanText = (props: GridCellProps) => {
    let text
    if (props.field === 'isActive') {
      text = props.dataItem.isActive ? 'Active' : 'Not active';
    } else if (props.field === 'noPayment') {
      text = props.dataItem.noPayment ? 'Not required' : 'Required';
    }
    
    return (
      <td>{text}</td>
    )
  }

  return (
    <>
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowModalDelete}
        className=""
        buttons={modalDeleteButtons}
      >
        <div>{"Are you sure you want to continue: delete "}
          <span className={"modal-wrapper-var"}>{editOrDeletePublication?.name || ''}</span>
          {"?"}
        </div>
      </ModalWrapper>
      {isShowCreateEditModal &&
        <CreateEditPublicationForm publicationToUpdate={editOrDeletePublication} isOpen={isShowCreateEditModal}
                                   onClose={() => setIsShowCreateEditModal(false)}/>}
      <Grid
        className="product-grid version-grid"
        data={gridData}
        {...(dataState as IGridDataState)}
        total={publicationList?.total}
        pageable={true}
        sortable={true}
        onItemChange={itemChange}
        onDataStateChange={onDataStateChange}
      >
        <GridToolbar>
          <DropDownList
            style={{width: "100px"}}
            data={pageSizeSet}
            value={pageSize}
            textField="value"
            dataItemKey="id"
            onChange={onPageSizeChange}
          />
        </GridToolbar>
        <GridNoRecords>
          <GridLoader isLoading={isPublicationListLoading}/>
        </GridNoRecords>
        <GridColumn field="name" title="Name"/>
        <GridColumn field="description" title="Description"/>
        <GridColumn field="productVersionName" title="Product Version"/>
        <GridColumn
          field="manufacturingOrganizationName"
          title="Manufacturing Organization"
        />
        <GridColumn field="onlineStoreName" title="Online Store"/>
        <GridColumn field="isActive" title="Status" cell={changeBooleanText}/>
        <GridColumn field="noPayment" title="Payment" cell={changeBooleanText}/>
        <GridColumn field="created" title="Date created" cell={DateCell}/>
        <GridColumn field="expires" title="Date expired" cell={DateCell}/>
        {
          currentOrganization?.orgType === EOrganisationTypes.Design && (
            <GridColumn
              field="action"
              title="Action"
              className="action-cell"
              cell={(gridProps: GridCellProps) => (
                <PublicationActionCell
                  gridProps={gridProps}
                  onDeleteClickPublication={onDeleteClickPublication}
                  onEditClickPublication={onEditClickPublication}
                />
              )}
              sortable={false}
            />
          )}
      </Grid>
    </>
  );
};

export default PublicationsPage;
