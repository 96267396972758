import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Grid, GridColumn, GridNoRecords } from '@progress/kendo-react-grid';
import GridLoader from '../../../../components/Loaders/GridLoader/GridLoader';
import { IDesignToManufacturersOrganizations } from '../../../../types/organisationPage';
import useOrganizations from '../../../../helpers/hooks/storeHooks/useOrganizations';
import { getDesignToManufacturersOrganizations } from '../../../../stores/organisation/reducer';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';

const DevelopersPage = () => {
  const dispatch = useDispatch()
  const { currentOrganization,
    designToManufacturersOrganizations,
    isLoadingDesignToManufacturersOrganizations
    } = useOrganizations()
  const [gridData, setGridData] = useState<IDesignToManufacturersOrganizations[]>([])

  useEffect(() => {
    dispatch(getDesignToManufacturersOrganizations())
  }, [])

  useEffect(() => {
    setGridData(designToManufacturersOrganizations ?? [])
  }, [designToManufacturersOrganizations])

  return (
    <div>
      <div className="version-table">
          <Grid
            className="product-grid version-grid"
            data={gridData}
            pageable={true}
          >
            <GridNoRecords>
              <GridLoader isLoading={isLoadingDesignToManufacturersOrganizations} />
            </GridNoRecords>
            <GridColumn field="name" title="Name" />
            <GridColumn field="description" title="Description" />
          </Grid>
      </div>
      <ErrorMessage style="mt-3" />
    </div>
  )
}

export default DevelopersPage