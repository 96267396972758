import { Grid, GridCellProps, GridColumn, GridNoRecords, GridRowProps, GridToolbar } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import GridLoader from '../../../../components/Loaders/GridLoader/GridLoader';
import ModalWrapper from '../../../../components/ModalWrapper/ModalWrapper';
import useOrganizations from '../../../../helpers/hooks/storeHooks/useOrganizations';
import { postPayTariff } from '../../../../stores/billing/reducer';
import { deleteTariff, getTariffList, setTariff, setTariffList } from '../../../../stores/tariff/reducer';
import { IPostPayTariff } from '../../../../types/billing';
import { Nullable } from '../../../../types/common';
import { IModalWrapperButton } from '../../../../types/modal';
import { ITariff } from '../../../../types/tariff';
import useTariff from './../../../../helpers/hooks/storeHooks/useTariff';
import BillingActionCell from './Components/BillingActionCell';
import CreateTariffModal from './modals/CreateTariffModal';

const BillingPage = () => {
  const dispatch = useDispatch();
  const { tariffList, isTariffListLoading } = useTariff();
  const { currentOrganization } = useOrganizations();

  const [gridData, setGridData] = useState<Nullable<ITariff[]>>([]);
  const [isShowTariffModal, setIsShowTariffModal] = useState(false)
  const [tariffToEdit, setTariffToEdit] = useState<Nullable<ITariff>>(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [deletingTariff, setDeletingTariff] = useState<Nullable<ITariff>>(null);

  useEffect(() => {
    dispatch(getTariffList())
    return () => {
      dispatch(setTariffList(null))
      dispatch(setTariff(null))
    }
  }, [dispatch])

  useEffect(() => {
    setGridData(tariffList || null)
  }, [tariffList])
  
  const buttonTariffHandler = (tariffId: string) => {
    const payTariffData: IPostPayTariff = {
      organizationId: currentOrganization?.id || '',
      tariffId: tariffId
    };
    dispatch(postPayTariff(payTariffData));
  };

  const rowRender = (trElement: React.ReactElement, props: GridRowProps) => {
    const isTariffSet = props.dataItem.id == currentOrganization?.tariffId;
    if (isTariffSet) {
      const blue = {
        backgroundColor: '#83c7fb'
      };
      const trProps = {
        style: blue
      };

      return React.cloneElement(
        trElement,
        { ...trProps },
        trElement.props.children
      );
    }
    return trElement;
  };

  const TariffButtonCell = (props: GridCellProps) => {
    const isTariffSet = props.dataItem.id == currentOrganization?.tariffId;
    return <BillingActionCell
      onEdit={onEdit}
      onDelete={onDelete}
      buttonTariffHandler={buttonTariffHandler}
      isTariffSet={isTariffSet}
      {...props}
    />;
  };

  const toggleTariffModal = () => {
    setIsShowTariffModal(!isShowTariffModal)
    setTariffToEdit(null)
  }

  const onEdit = (item: ITariff) => {
    setTariffToEdit(item)
    setIsShowTariffModal(true)
  }

  const onDelete = (item: ITariff) => {
    setDeletingTariff(item)
    setIsShowModal(true)
  };

  const continueOperation = () => {
    if (deletingTariff) {
      dispatch(deleteTariff(deletingTariff.id))
    }
    closeModal();
  }

  const closeModal = () => {
    setIsShowModal(false);
  };

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueOperation
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeModal
    }
  ];

  return (
    <>
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowModal}
        className=""
        buttons={modalButtons}
      >
        <div>
          <div>{"Are you sure you want to continue: delete "}
            <span className={"modal-wrapper-var"}>{deletingTariff?.name}</span>
            {"?"}
          </div>
        </div>
      </ModalWrapper>
      <Grid
        rowRender={rowRender}
        className="billing-grid version-grid"
        data={gridData}
      >
        <GridToolbar>
          <Button variant="primary" onClick={toggleTariffModal}>
            Add
          </Button>
        </GridToolbar>
        <GridNoRecords>
          <GridLoader isLoading={isTariffListLoading} />
        </GridNoRecords>
        <GridColumn field="name" title="Name" />
        <GridColumn field="description" title="Description" />
        <GridColumn field="availableDiskSpace" title="Available disk space" />\
        <GridColumn field="billingPeriod" title="Billing period" />
        <GridColumn field="tariffPoints" title="Tariff points" />
        <GridColumn field="action" title="Action"
          cell={(tariffProps: GridCellProps) => TariffButtonCell(tariffProps)}
        />
      </Grid>
      <CreateTariffModal isOpen={isShowTariffModal} onClose={toggleTariffModal} tariffToUpdate={tariffToEdit} />
    </>
  );
};

export default BillingPage;
