import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React from 'react';
import { ERoutes } from '../Routes';
import { EOrganisationTypes } from '../../types/organisationPage';
import useOrganizations from '../../helpers/hooks/storeHooks/useOrganizations';

export interface controlledRouteProps {
  isAllowed?: boolean,
  redirectPath?: string,
  children?: React.ReactElement,
}

interface Map<T> {
  [K: string]: T;
}

const allowedRoutes: Map<EOrganisationTypes[]> = {};
allowedRoutes[ERoutes.Products] = [EOrganisationTypes.Design]

allowedRoutes[ERoutes.Publications] = [EOrganisationTypes.Design, EOrganisationTypes.Manufacturing]

allowedRoutes[ERoutes.Manufacturers] = [EOrganisationTypes.Design]
allowedRoutes[ERoutes.Onlinestores] = [EOrganisationTypes.Design]
allowedRoutes[ERoutes.ConfigurationRequests] = [EOrganisationTypes.Design]
allowedRoutes[ERoutes.Statistics] = [EOrganisationTypes.Design]
allowedRoutes[ERoutes.CPThreshold] = [EOrganisationTypes.Design]

allowedRoutes[ERoutes.ProdVersions] = [EOrganisationTypes.Design]
allowedRoutes[ERoutes.ProdVersion] = [EOrganisationTypes.Design]
allowedRoutes[ERoutes.ProdVersionConfig] = [EOrganisationTypes.Design]

allowedRoutes[ERoutes.Orders] = [EOrganisationTypes.Manufacturing]
allowedRoutes[ERoutes.Developers] = [EOrganisationTypes.Manufacturing]

allowedRoutes[ERoutes.Users] = [EOrganisationTypes.Design, EOrganisationTypes.Manufacturing]
allowedRoutes[ERoutes.Settings] = [EOrganisationTypes.Design, EOrganisationTypes.Manufacturing]

allowedRoutes[ERoutes.Billing] = []
allowedRoutes[ERoutes.AppBundles] = []
allowedRoutes[ERoutes.AppBundleId] = []

const RedirectRoutes: React.FC<controlledRouteProps> = ({
  isAllowed,
  redirectPath,
  children,
}) => {
  const location = useLocation();
  const { currentOrganization } = useOrganizations();
  const route = location.pathname.slice(location.pathname.lastIndexOf("/") + 1)

  if (currentOrganization && !allowedRoutes[route].some(x => x === currentOrganization?.orgType)) {
    return <Navigate to={ERoutes.PageNotFound} state={{ from: location }} replace />;
  }

  return (
    children ? children : <Outlet />
  )
};

export default RedirectRoutes