import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import {
  DropDownButton,
  DropDownButtonItemClickEvent
} from '@progress/kendo-react-buttons';
import { Button } from 'react-bootstrap';
import ConfigurationsTable from './ConfigurationsTable/ConfigurationsTable';
import Spinner from '../../../../components/Loaders/Spinner/Spinner';
import ModalWrapper from './../../../../components/ModalWrapper/ModalWrapper';
import { IModalWrapperButton } from '../../../../types/modal';
import useProdVersion from '../../../../helpers/hooks/storeHooks/useProdVersion';
import { ICommonObject, Nullable } from '../../../../types/common';
import {
  getProdVersion,
  setProdVersion
} from '../../../../stores/productVersion/reducer';
import useConfigurations from '../../../../helpers/hooks/storeHooks/useConfigurations';
import {
    IDeleteBulkConfigurations,
  IPageSizeSetItem,
  IPostBulkOperations,
  PAGE_SIZE_SET
} from '../../../../types/producVersionConfigurations';
import {
  IConfigurationListItem,
  IGetConfigurations
} from './../../../../types/producVersionConfigurations';
import {
  setConfigurationsList,
  deleteConfigurationsByIds,
  recalculateConfigurationsByIds,
  generatesvfConfigurationsByIds
} from '../../../../stores/productConfigurations/reducer';
import CreateEditPublicationForm from '../PublicationsPage/components/modals/CreateEditPublicationForm';
import useOrganizations from '../../../../helpers/hooks/storeHooks/useOrganizations';
import { EOrganisationTypes } from '../../../../types/organisationPage';
import { getAppBundleAlias, setAppBundleAlias } from '../../../../stores/appBundleAlias/reducer';
import useAppBundleAlias from '../../../../helpers/hooks/storeHooks/useAppBundleAlias';

const moreOperations = [
  {
    text: 'Change'
  },
  {
    text: 'Select all on page'
  }
];

const options = [
  {
    text: 'Delete'
  },
  {
    text: 'Recalculate'
  },
  {
    text: 'Generate SVF'
  },
  {
    text: 'Cancel'
  }
];

const buttonText = {
  Configurations: 'Configurations',
  InterfaceParameterDefinitions: 'InterfaceParameterDefinitions',
  UpdateVersion: 'Update version',
  CreatePublication: 'Create publication'
};

const ProdVersionPage = () => {
  const dispatch = useDispatch();
  const { versionId } = useParams();
  const { prodVersion, isProdVersionLoading } = useProdVersion();
  const { currentOrganization } = useOrganizations();
  const { configurationsList, dataState } = useConfigurations();
  const { appBundleAlias } = useAppBundleAlias();

  const [btnPressed, setBtnPressed] = useState<ICommonObject>({
    [buttonText.Configurations]: false,
    [buttonText.InterfaceParameterDefinitions]: false,
    [buttonText.UpdateVersion]: false,
    [buttonText.CreatePublication]: false
  });
  const [pageSize, setPageSize] = useState<IPageSizeSetItem>(PAGE_SIZE_SET[0]);

  const [pageSizeSet, setPageSizeSet] =
    useState<IPageSizeSetItem[]>(PAGE_SIZE_SET);
  const [isCheckboxsBulcOperations, setIsCheckboxsBulcOperations] =
    useState(false);
  const [isDropdownOptions, setIsDropdownOptions] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowConfirmModal, setIsShowConfirmModal] = useState(false);
  const [operationName, setOperationName] = useState('');
  const [isShowCreateEditModal, setIsShowCreateEditModal] = useState(false);

  const NO_DATA_TEXT = 'N/A';

  const idsData = configurationsList?.data
    ? configurationsList?.data
      .filter((item: IConfigurationListItem) => item.isSelected)
    : [];

  const postData = {
    idsData: idsData.map((item: IConfigurationListItem) => item.id),
    versionId: versionId || ''
  } as IPostBulkOperations;

  let payload: IDeleteBulkConfigurations = {
    idsData: postData.idsData,
    versionId: postData.versionId,
    deleteTemplateConfiguration: false
  } as IDeleteBulkConfigurations

  const closeModal = () => {
    setIsShowModal(false);
    const newConfigurationList = setAllFieldIsSelected(
      configurationsList,
      false
    );
    setIsCheckboxsBulcOperations(false);
    setIsDropdownOptions(false);
    dispatch(setConfigurationsList(newConfigurationList));
  };

  const closeConfirmModal = () => {
    setIsShowConfirmModal(false);
  };

  const continueOperation = async () => {
    if (operationName === 'Delete' && idsData.length) {
      if (idsData.find(x => x.useAsTemplateConfiguration)) {
        setIsShowConfirmModal(true)
      } else {
        dispatch(deleteConfigurationsByIds(payload));
      }
      closeModal();
    }

    if (operationName === 'Recalculate' && configurationsList?.data.length) {
      dispatch(recalculateConfigurationsByIds(postData));
      closeModal();
    }

    if (operationName === 'Generate SVF' && configurationsList?.data.length) {
      dispatch(generatesvfConfigurationsByIds(postData));
      closeModal();
    }
  };

  const deleteTemplateConfiguration = () => {
    payload.deleteTemplateConfiguration = true
    dispatch(deleteConfigurationsByIds(payload));
    setIsShowConfirmModal(false)
    closeConfirmModal()
  }

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueOperation
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeModal
    }
  ];

  const confirmModalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: deleteTemplateConfiguration
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeConfirmModal
    }
  ];

  useEffect(() => {
    if (prodVersion) {
      dispatch(getAppBundleAlias(prodVersion?.aliasId || ''));
    }
    return () => {
      dispatch(setAppBundleAlias(null));
    }
  }, [prodVersion])

  useEffect(() => {
    dispatch(getProdVersion(versionId ?? ''));
    
    setPressedBtn(buttonText.Configurations);
    return () => {
      dispatch(setProdVersion(null));
      const newConfigurationList = setAllFieldIsSelected(
        configurationsList,
        false
      );
      setIsCheckboxsBulcOperations(false);
      setIsDropdownOptions(false);
      dispatch(setConfigurationsList(newConfigurationList));
    };
  }, []);

  useEffect(() => {
    setIsCheckboxsBulcOperations(false);
    setIsDropdownOptions(false);
  }, [dataState]);

  const isConfigurationsListSelected = (
    configurationListData: IConfigurationListItem[]
  ) => {
    if (!configurationListData.length) return false;

    const isConfigurationsListSelected = configurationListData.reduce(
      (accum, current) => {
        return accum || current.isSelected;
      },
      false
    );

    return isConfigurationsListSelected;
  };

  useEffect(() => {
    const total = configurationsList?.total || PAGE_SIZE_SET[0].id;
    const pageSizeNew: IPageSizeSetItem[] = PAGE_SIZE_SET.reduce(
      (accum: IPageSizeSetItem[], current: IPageSizeSetItem) => {
        if (current?.value < total) {
          accum.push(current);
        }
        return accum;
      },
      []
    );
    const pageSizeResult = pageSizeNew.length
      ? [...pageSizeNew, { id: `${total}`, value: 'all' }]
      : [PAGE_SIZE_SET[0]];
    setPageSizeSet(pageSizeResult);
    if (isConfigurationsListSelected(configurationsList?.data || [])) {
      setIsDropdownOptions(true);
    }
  }, [configurationsList]);

  const setAllFieldIsSelected = (
    configurationListData: Nullable<IGetConfigurations>,
    isSelected: boolean
  ) => {
    const newConfigurationList = {
      ...configurationListData,
      data: configurationsList?.data.length
        ? configurationsList.data.map((item: IConfigurationListItem) => ({
            ...item,
            isSelected
          }))
        : [],
      total: configurationsList?.total || 0
    };
    return newConfigurationList;
  };

  const setPressedBtn = (btn: string) => {
    const newState: ICommonObject = {};
    setBtnPressed((prev) => {
      Object.entries(prev).forEach(([k, v], i) => {
        if (k === btn) return (newState[k] = true);
        newState[k] = false;
      });
      return newState;
    });
  };
  const onPressConfig = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    setPressedBtn(target.innerText);
  };

  const onPressInterfaceParam = (e: React.MouseEvent) => {
    const target = e.target as HTMLElement;
    setPressedBtn(target.innerText);
  };

  const onPressCreatePublication = () => {
    setIsShowCreateEditModal(true);
  };

  const onPageSizeChange = (e: DropDownListChangeEvent) => {
    const chosenValue = e.value;
    setPageSize(chosenValue);
  };

  const onDropdownMoreItemClick = (e: DropDownButtonItemClickEvent) => {
    if (e.itemIndex === 0) {
      setIsCheckboxsBulcOperations(true);
    }
    if (e.itemIndex === 1) {
      setIsCheckboxsBulcOperations(true);
      const newConfigurationList = setAllFieldIsSelected(
        configurationsList,
        true
      );
      dispatch(setConfigurationsList(newConfigurationList));
    }
  };

  const onDropdownOptionsItemClick = (e: DropDownButtonItemClickEvent) => {
    if (e.itemIndex === 0 || e.itemIndex === 1 || e.itemIndex === 2) {
      setIsShowModal(true);
      setOperationName(e.item.text);
    }
    if (e.itemIndex === 3) {
      const newConfigurationList = setAllFieldIsSelected(
        configurationsList,
        false
      );
      setIsCheckboxsBulcOperations(false);
      setIsDropdownOptions(false);
      dispatch(setConfigurationsList(newConfigurationList));
    }
  };

  return (
    <div className="version-page">
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowConfirmModal}
        buttons={confirmModalButtons}
      >
        <div>{"You are trying to delete template configuration "}
          <span className={"modal-wrapper-var"}>
            {configurationsList?.data.find(item => item.useAsTemplateConfiguration)?.configurationName}
          </span>
          {"."}
          <br />{`New template configuration will be assigned automatically. Proceed?`}
        </div>
      </ModalWrapper>
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowModal}
        className=""
        buttons={modalButtons}
      >
        <div>{`Are you sure you want to continue: ${operationName.toLowerCase()} `}
          {operationName === 'Generate SVF' ? 'for ' : ''}
          <span className={"modal-wrapper-var"}>{`${configurationsList?.data.filter(
            (item: IConfigurationListItem) => item.isSelected
          ).length
            } item(s)`}
          </span>
          {"?"}
        </div>
      </ModalWrapper>
      {isShowCreateEditModal && (
        <CreateEditPublicationForm
          isOpen={isShowCreateEditModal}
          onClose={() => setIsShowCreateEditModal(false)}
        />
      )}
      <div className="version-info">
        {isProdVersionLoading ? (
          <Spinner className="version-info-spinner" />
        ) : (
          <>
            <h4>{prodVersion?.name}</h4>
            <div className="version-info-body">
              <div className="info-column">
                <div className="body-row">
                  <span>Product name:</span>
                  <div>{prodVersion?.productName}</div>
                </div>
                <div className="body-row">
                  <span>Product version:</span>
                  <div>{prodVersion?.version}</div>
                </div>
                <div className="body-row">
                  <span>Design gear version:</span>
                  <div>{prodVersion?.designGearVersion}</div>
                </div>
                <div className="body-row">
                  <span>Inventor version:</span>
                  <div>{prodVersion?.inventorVersion}</div>
                </div>
                <div className="body-row">
                  <span>Description:</span>
                  <div> {NO_DATA_TEXT} </div>
                </div>
              </div>
              <div className="info-column">
                <div className="body-row">
                    <span>AppBundleAlias: </span>
                    <div>{appBundleAlias?.name}</div>
                  </div>
                <div className="body-row">
                    <span>AppBundle: </span>
                    <div>{appBundleAlias?.appBundleName}</div>
                </div>
                <div className="body-row">
                  <span>Author: </span>
                  <div> {NO_DATA_TEXT} </div>
                </div>
                <div className="body-row">
                  <span>Company: </span>
                  <div> {NO_DATA_TEXT} </div>
                </div>
                <div className="body-row">
                  <span>Date created:</span>
                  <div>
                    {new Date(prodVersion?.created || '').toLocaleDateString()}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <div className="version-info-button-bar">
        <div className="version-info-button-bar-box">
          <Button
            variant="outline-primary"
            onClick={onPressConfig}
            active={btnPressed.Configurations}
          >
            {buttonText.Configurations}
          </Button>
          <Button
            variant="outline-primary"
            onClick={onPressInterfaceParam}
            active={btnPressed.InterfaceParameterDefinitions}
            disabled={true}
          >
            {buttonText.InterfaceParameterDefinitions}
          </Button>
          <Button variant="outline-primary" disabled={true}>
            {buttonText.UpdateVersion}
          </Button>
          {currentOrganization?.orgType ===
            EOrganisationTypes.Design && (
            <Button
              variant="outline-primary"
              onClick={onPressCreatePublication}
            >
              {buttonText.CreatePublication}
            </Button>
          )}
        </div>
        <div className="version-info-button-bar-box">
          {isDropdownOptions && (
            <DropDownButton
              popupSettings={{
                anchorAlign: { horizontal: 'right', vertical: 'bottom' },
                popupAlign: { horizontal: 'right', vertical: 'top' },
                popupClass: 'version-info-dropdown-popup'
              }}
              items={options}
              text="Options"
              onItemClick={onDropdownOptionsItemClick}
            />
          )}
          <DropDownList
            style={{ width: '100px' }}
            data={pageSizeSet}
            value={pageSize}
            textField="value"
            dataItemKey="id"
            onChange={onPageSizeChange}
          />
          <DropDownButton
            popupSettings={{
              anchorAlign: { horizontal: 'right', vertical: 'bottom' },
              popupAlign: { horizontal: 'right', vertical: 'top' },
              popupClass: 'version-info-dropdown-popup'
            }}
            size="large"
            items={moreOperations}
            icon="k-icon k-i-more-vertical"
            text=""
            onItemClick={onDropdownMoreItemClick}
          />
        </div>
      </div>
      <div className="version-info-table">
        {btnPressed[buttonText.Configurations] && (
          <ConfigurationsTable
            pageSize={+pageSize.id}
            isCheckboxsBulcOperations={isCheckboxsBulcOperations}
          />
        )}
      </div>
    </div>
  );
};

export default ProdVersionPage;
