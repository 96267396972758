import React, { useEffect, useState } from 'react';
import ConfigurationModule from '@designgear/configuration-module/moduleConfiguration/ConfigurationModule';
import { useParams, useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { getLocalStorage } from '../../../../helpers/localStorage';
import { ACCESS_TOKEN_KEY } from './../../../../types/user';
import {
  setConfigIdInPath,
  goBackOneLevel,
  goToConfigurationRequests,
  getOrgIdFromUrl
} from '../../../../helpers/routingFunctions/routingFunctions';
import { BASE_URL } from './../../../../constants/api';

const ProductConfigurationPage: React.FC = () => {
  const [token, setToken] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { configId } = useParams();
  const configurationIdCallback = (configurationId: string) => {
    navigate(setConfigIdInPath(location.pathname, configurationId));
  };
  const configurationRequestIdCallback = () => undefined;
  const onCloseCallback = () => navigate(goBackOneLevel(location.pathname));
  const goToConfigurationRequestCallback = () =>
    navigate(goToConfigurationRequests(location.pathname));

  useEffect(() => {
    const orgId = getOrgIdFromUrl()
    const tokenCurrent = getLocalStorage(ACCESS_TOKEN_KEY + '_' + orgId);
    setToken(`${tokenCurrent}`);
  }, []);

  return (
    <div className="page-body">
      {token && (
        <ConfigurationModule
          token={token}
          baseUrl={BASE_URL}
          initialConfigurationId={configId || ''}
          publicationId={''}
          onCloseCallback={onCloseCallback}
          goToConfigurationRequestCallback={goToConfigurationRequestCallback}
          onConfigurationRequestCreateCallback={configurationRequestIdCallback}
          onConfigurationCreateCallback={configurationIdCallback}
          enableMassGeneration={true}
          enableDownloadLogs={true}
          enableDownloadParamsJson={true}
          enableFormActions={true}
          showPrevious={false}
          showNext={false}
        />
      )}
    </div>
  );
};

export default ProductConfigurationPage;
