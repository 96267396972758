const PageNotFound = () => {
  return (
    <div id="error-page">
      <h1>404!</h1>
      <p>Page not found</p>
      <p>
        <i></i>
      </p>
    </div>
  );
}

export default PageNotFound;