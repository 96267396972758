import Profile from './Profile/Profile';
import useAuthCheck from '../../helpers/hooks/storeHooks/useAuthCheck';
import React from 'react';
import BtnLink from '../BtnLink';
import { ERoutes } from '../../router/Routes';

const Header = () => {
  const { user, authenticated } = useAuthCheck();
  return (
    <div className="header">
      <div className="header-left">
        <div className="logo-icon">
          <BtnLink
            to={
              user && !user.isSuperAdmin ? ERoutes.Organisations : ERoutes.Root
            }
          >
            <img
              src={process.env.PUBLIC_URL + 'DesignGearLogo/DesignGearLogoWide.svg'}
              alt="DesignGear" 
            />
          </BtnLink>
        </div>
      </div>
      {authenticated && <Profile user={user} />}
    </div>
  );
};
export default Header;
