import { useDispatch } from 'react-redux';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Field, FieldWrapper, Form, FormElement } from '@progress/kendo-react-form';
import { IModalWrapperButton } from '../../../../../types/modal';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import CInput from '../../../../../components/form-components/CInput';
import {
  isEmpty,
  isImage,
  isNumberErrorMsg,
  isZip
} from '../../../../../components/form-components/helpers/validation-functions';
import { Button } from 'react-bootstrap';
import {
  getProdVersion,
  postProdVerByProdId,
  putProdVer,
  setProdVersion,
} from '../../../../../stores/productVersion/reducer';
import { IPostProductVersion } from '../../../../../types/productVersion';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ICommonModalProps, IKendoOnChangeEvent } from '../../../../../types/common';
import { FormFile } from '../../../../../components/form-components/FormFile';
import useProdVersion from '../../../../../helpers/hooks/storeHooks/useProdVersion';
import FormCheckBox from '../../../../../components/form-components/CheckBox';
import { getAllAppBundleAliases } from '../../../../../stores/appBundleAlias/reducer';
import { IAppBundleAlias } from '../../../../../types/appBundleAlias';
import Spinner from '../../../../../components/Loaders/Spinner/Spinner';
import { setPostReqResp } from '../../../../../stores/common/reducer';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import useAppBundleAlias from '../../../../../helpers/hooks/storeHooks/useAppBundleAlias';

interface ICreateVerProps extends ICommonModalProps {
  productId: string,
  dataToUpdateId?: string,
}

//TODO: CreateVersion: доделать логику для PUT запроса,
// изменить логику для картинок , когда будут приходить настоящие данные с картинками
const CreateVersion: FC<ICreateVerProps> = ({
  isOpen,
  productId,
  dataToUpdateId,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { prodVersion, isProdVersionLoading } = useProdVersion()
  const { postReqResp } = useCommon()
  const { allAppBundleAliases }  = useAppBundleAlias()
  const formRef = useRef<Form|null>(null)
  const imageFilesRef = useRef<HTMLInputElement|null>(null)
  const modelFileRef = useRef<HTMLInputElement|null>(null)
  const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null)

  const headerText = dataToUpdateId ? 'Update version' : 'Create version';
  const [formState, setFormState] = useState<any>({
    Name: '',
    Version: '',
    ProductId: productId,
    AliasId: allAppBundleAliases?.data[0],
    UseTemplateConfiguration: false
  })
  const [chosenImgFiles, setChosenImgFiles] = useState<File[]>([])
  const [preloadedImgFiles, setPreloadedImgFiles] = useState<string[]>([])
  const [chosenModelFiles, setChosenModelFiles] = useState<File[]>([])
  const [defaultBundle, setDefaultBundle] = useState<IAppBundleAlias|null>(null)
  const modalButtons: IModalWrapperButton[] = [
    {buttonText: 'close', onButtonClick: () => onClose()},
    {buttonText: 'save', onButtonClick: () => formSubmitBtnRef?.current?.click(), buttonDisabled: isProdVersionLoading  }
  ]

  useEffect(() => {
    dispatch(getAllAppBundleAliases())
    return () => {
      dispatch(setProdVersion(null))
    }
  }, [])
  useEffect(() => {
     if (prodVersion?.aliasId && allAppBundleAliases?.data.length) {
        const presetAlias = allAppBundleAliases.data.find(item => item.id === prodVersion?.aliasId)
        const alias = presetAlias || allAppBundleAliases.data[0]
        formRef?.current?.valueSetter("AliasId", alias)
        setDefaultBundle(alias)
      }
     else {
       formRef?.current?.valueSetter("AliasId", allAppBundleAliases?.data[0])
       setDefaultBundle(allAppBundleAliases?.data[0] || null)
    }

  }, [allAppBundleAliases])
  useEffect(() => {
    if(dataToUpdateId) {
      dispatch(setProdVersion(null))
      dispatch(getProdVersion(dataToUpdateId))
    }
  }, [dataToUpdateId])
  useEffect(() => {
    if(prodVersion) {
      setFormState({
        ...formState,
        Name: prodVersion.name,
        Version: prodVersion.version,
        UseTemplateConfiguration: prodVersion.useTemplateConfiguration
      })
      if (!dataToUpdateId)
        setFormState({ ...formState})
      setPreloadedImgFiles(prodVersion.imageFiles)
      dispatch(getAllAppBundleAliases())
    }
  }, [prodVersion])
  useEffect(() => {
    if(postReqResp){
      onClose()
      dispatch(setPostReqResp(''))
    }
  }, [postReqResp, isProdVersionLoading])

  const onSubmitLocal = (formData: any) => {
    if(!formRef?.current?.isValid()) return

    const data = new FormData();
    data.append('Name', formData.Name)
    data.append('Version', formData.Version)
    data.append('ProductId', formData.ProductId)
    data.append('AliasId', formData.AliasId.id)
    if(chosenModelFiles.length)
      data.append('ModelFile', chosenModelFiles[0])
    if(chosenImgFiles.length)
      chosenImgFiles.forEach(item => data.append('ImageFiles', item))
    data.append('UseTemplateConfiguration', formData.UseTemplateConfiguration)
    if (!dataToUpdateId) {
      dispatch(postProdVerByProdId(data as IPostProductVersion))
    }
    else {
      data.append('id', dataToUpdateId)
      dispatch(putProdVer(data as IPostProductVersion))
    }
  }
  const onFileSelect = () => {
    imageFilesRef?.current?.click()
  }
  const onModelSelect = () => {
    modelFileRef?.current?.click()
  }
  const onFileChange = (event: IKendoOnChangeEvent) => {
    const fileUploaded = event?.value
    setChosenImgFiles([...fileUploaded])

    if(preloadedImgFiles.length)
      setPreloadedImgFiles([])
  }
  const onModelChange = (event: IKendoOnChangeEvent) => {
    const fileUploaded = event.value
    setChosenModelFiles([...fileUploaded])
  }
  const innerValidateImg = (val: any) => {
    if(formRef?.current)
      formRef.current.modified['ImageFiles'] = true
    return !preloadedImgFiles.length ? isImage(val) : ''
  }

  return (
    <ModalWrapper
      headerText={headerText}
      isOpen={isOpen}
      onClose={onClose}
      buttons={modalButtons}
      className={'create-version-form'}
    >
      <Form
        onSubmit={onSubmitLocal}
        initialValues={formState}
        key={JSON.stringify(formState)}
        ref={formRef}
        render={(formRenderProps) => (
          <FormElement className={'modal-body'}>
            {
              isProdVersionLoading ? <Spinner/> :
                <fieldset className={"k-form-fieldset"}>
                  <div className="mb-3">
                    <Field
                      name="Name"
                      component={CInput}
                      label="Version name"
                      validator={isEmpty}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="Version"
                      component={CInput}
                      label="Version"
                      validator={isEmpty}
                    />
                  </div>
                  <div className="mb-3">
                    <Field
                      name="UseTemplateConfiguration"
                      id="UseTemplateConfiguration"
                      component={FormCheckBox}
                      label="use template configuration"
                      defaultChecked={formState.UseTemplateConfiguration}
                    />
                  </div>
                  <div className="mb-3">
                    <div>Select app bundle</div>
                    <FieldWrapper>
                      <DropDownList
                        name="AliasId"
                        style={{width: "50%"}}
                        data={allAppBundleAliases?.data}
                        textField="fullName"
                        dataItemKey="id"
                        defaultValue={defaultBundle}
                        loading={!allAppBundleAliases?.data.length}
                        onChange={val => formRef?.current?.valueSetter("AliasId", val.target.value)}
                        required={true}
                      />
                    </FieldWrapper>

                  </div>
                  <div className="mb-3">
                    <Field
                      name="ImageFiles"
                      component={FormFile}
                      className="upload-component"
                      label="Select images"
                      btnText="select"
                      multiple={true}
                      inputRef={imageFilesRef}
                      presetFiles={preloadedImgFiles || []}
                      onFileSelect={onFileSelect}
                      onChange={onFileChange}
                      validator={innerValidateImg}
                    />
                  </div>
                  {
                    !dataToUpdateId &&
                    <div className="mb-3">
                      <Field
                        name="ModelFile"
                        component={FormFile}
                        className="upload-component"
                        label="Select model"
                        btnText="select"
                        inputRef={modelFileRef}
                        onFileSelect={onModelSelect}
                        onChange={onModelChange}
                        validator={isZip}
                      />
                    </div>
                  }
                </fieldset>
            }
            <Button
              type="submit"
              hidden={true}
              ref={formSubmitBtnRef}
            >
              submit
            </Button>
          </FormElement>
        )}
      />

    </ModalWrapper>
  )
}

export default CreateVersion