import ModalWrapper from "../../../../../components/ModalWrapper/ModalWrapper";
import {Field, Form, FormElement} from "@progress/kendo-react-form";
import CInput from "../../../../../components/form-components/CInput";
import {isEmpty} from "../../../../../components/form-components/helpers/validation-functions";
import {Button} from "react-bootstrap";
import React, {FC, useEffect, useRef, useState} from "react";
import {useDispatch} from "react-redux";
import useAuthCheck from "../../../../../helpers/hooks/storeHooks/useAuthCheck";
import {IModalWrapperButton} from "../../../../../types/modal";
import {putAccountInfo} from "../../../../../stores/authentication/reducer";
import {ICommonModalProps} from "../../../../../types/common";


const BillingInfoEditModal: FC<ICommonModalProps> = ({isOpen, onClose}) => {
  const dispatch = useDispatch()
  const headerText = 'Billing info edit';
  const {account} = useAuthCheck()
  const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null)
  const [formState, setFormState] = useState({})
  const modalButtons: IModalWrapperButton[] = [
    {buttonText: 'close', onButtonClick: () => onClose()},
    {buttonText: 'save', onButtonClick: () => formSubmitBtnRef?.current?.click()}
  ]

  useEffect(() => {
    if (account)
      setFormState({
        firstName: account.firstName,
        lastName: account.lastName,
        phone: account.phone,
      })
  }, [account])

  const onSubmitLocal = (formData: any) => {
    const data = {
      externalId: formData?.externalId,
    };

    dispatch(putAccountInfo({
      firstName: account ? account.firstName : '',
      lastName: account ? account.lastName : '',
      phone: account ? account.phone : '',
      ...data
    }))

    onClose()
  }


  return (
    <>
      <ModalWrapper
        headerText={headerText}
        isOpen={isOpen}
        onClose={onClose}
        buttons={modalButtons}
      >
        <Form
          onSubmit={onSubmitLocal}
          initialValues={formState}
          key={JSON.stringify(formState)}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className="k-form-fieldset">
                <div className="mb-3">
                  <Field
                    name={"externalId"}
                    component={CInput}
                    label={"External ID"}
                    validator={isEmpty}
                  />
                </div>
              </fieldset>

              <Button
                type="submit"
                hidden={true}
                ref={formSubmitBtnRef}
              >
                submit
              </Button>
            </FormElement>
          )}
        />
      </ModalWrapper>
    </>
  )
}

export default BillingInfoEditModal