import { Field, Form, FormElement } from '@progress/kendo-react-form';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { FormFile } from '../../../../../components/form-components/FormFile';
import { isZip } from '../../../../../components/form-components/helpers/validation-functions';
import Spinner from '../../../../../components/Loaders/Spinner/Spinner';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import useAppBundleVersion from '../../../../../helpers/hooks/storeHooks/useAppBundleVersion';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import { getAppBundleVersion, postAppBundleVersion, putAppBundleVersion } from '../../../../../stores/appBundleVersion/reducer';
import { setPostReqResp } from '../../../../../stores/common/reducer';
import { IPostAppBundleVersion, IPutAppBundleVersion } from '../../../../../types/appBundleVersion';
import { ICommonModalProps, IKendoOnChangeEvent } from '../../../../../types/common';
import { IModalWrapperButton } from '../../../../../types/modal';

interface ICreateAppBundle extends ICommonModalProps {
  dataToUpdateId?: string,
}

const CreateAppBundleVersionModal: FC<ICreateAppBundle> = ({
  isOpen,
  dataToUpdateId,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { appBundleId = '' } = useParams()
  const { appBundleVersion, isAppBundleVersionLoading } = useAppBundleVersion()
  const { postReqResp } = useCommon()
  const formRef = useRef<Form | null>(null)
  const bundleFileRef = useRef<HTMLInputElement | null>(null)
  const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null)

  const headerText = dataToUpdateId ? 'Update appbundle version' : 'Create appbundle version';
  const MAX_BUNDLE_SIZE = 10048576 // kb
  const [formState, setFormState] = useState({
    appBundleId: '',
    File: '',
  })
  const [chosenBundleFiles, setChosenBundleFiles] = useState<File[]>([])
  const [preloadedBundleFile, setPreloadedBundleFile] = useState<string>()
  const modalButtons: IModalWrapperButton[] = [
    { buttonText: 'close', onButtonClick: () => onClose() },
    { buttonText: 'save', onButtonClick: () => formSubmitBtnRef?.current?.click(), buttonDisabled: isAppBundleVersionLoading }
  ]

  useEffect(() => {
    if (isOpen && dataToUpdateId) {
      dispatch(getAppBundleVersion(dataToUpdateId))
    }
    else {
      setFormState({
        appBundleId: '',
        File: '',
      })
      setChosenBundleFiles([])
      setPreloadedBundleFile('')
    }
  }, [isOpen])
  useEffect(() => {
    if (appBundleVersion) {
      setFormState({
        appBundleId: appBundleId,
        File: '',
      })
      setPreloadedBundleFile('')
    }

  }, [appBundleVersion])
  useEffect(() => {
    if (postReqResp) {
      onClose()
      dispatch(setPostReqResp(''))
    }
  }, [postReqResp, isAppBundleVersionLoading])

  const onSubmitLocal = (formData: any) => {
    if (!formRef?.current?.isValid()) return

    const data = new FormData();
    data.append('appBundleId', appBundleId)

    if (chosenBundleFiles.length)
      data.append('File', chosenBundleFiles[0])

    if (!dataToUpdateId)
      dispatch(postAppBundleVersion(data as IPostAppBundleVersion))
    else {
      data.append('id', dataToUpdateId)
      dispatch(putAppBundleVersion(data as IPutAppBundleVersion))
    }
  }
  const onBundleFileSelect = () => {
    bundleFileRef?.current?.click()
  }
  const onBundleFileChange = (event: IKendoOnChangeEvent) => {
    const fileUploaded = event.value
    setChosenBundleFiles([...fileUploaded])
    if (preloadedBundleFile)
      setPreloadedBundleFile('')
  }
  const innerValidateFile = (val: any) => {
    if (formRef?.current)
      formRef.current.modified['File'] = true

    if (!preloadedBundleFile && chosenBundleFiles.length && chosenBundleFiles[0]?.size)
      return chosenBundleFiles[0].size <= MAX_BUNDLE_SIZE ? isZip(val) : 'file must be no larger than 10 mb'

    return !preloadedBundleFile ? isZip(val) : ''
  }

  return (
    <ModalWrapper
      headerText={headerText}
      isOpen={isOpen}
      onClose={onClose}
      buttons={modalButtons}
      className={'create-appbundle-version-form'}
    >
      <Form
        onSubmit={onSubmitLocal}
        ignoreModified={true}
        initialValues={formState}
        key={JSON.stringify(formState)}
        ref={formRef}
        render={(formRenderProps) => (
          <FormElement className={'modal-body'}>
            {isAppBundleVersionLoading ? (
              <Spinner />
            ) : (
              <fieldset className={"k-form-fieldset"}>
                <div className="mb-3">
                  <Field
                    name="File"
                    component={FormFile}
                    className="upload-component"
                    label="Select file"
                    btnText="select"
                    inputRef={bundleFileRef}
                    presetFiles={preloadedBundleFile ? [preloadedBundleFile] : []}
                    onFileSelect={onBundleFileSelect}
                    onChange={onBundleFileChange}
                    validator={innerValidateFile}
                  />
                </div>
              </fieldset>
            )}
            <Button
              type="submit"
              hidden={true}
              ref={formSubmitBtnRef}
            >
              submit
            </Button>
          </FormElement>
        )}
      />
    </ModalWrapper>
  )
}

export default CreateAppBundleVersionModal