import { Navigate, Outlet, useLocation } from 'react-router-dom';
import React from 'react';
import { ERoutes } from '../Routes';
import useAuthCheck from '../../helpers/hooks/storeHooks/useAuthCheck';
import { setPath } from '../../helpers/routingFunctions/routingFunctions';

export interface propsGuest {
  isAllowed?: boolean;
  redirectPath?: string;
  children?: React.ReactElement;
}

const GuestRoutes: React.FC<propsGuest> = ({
  redirectPath = ERoutes.Organisations,
  children
}) => {
  const { user, authenticated, authLoading } = useAuthCheck();

  const location = useLocation();

  if (authenticated && !authLoading && user?.isSuperAdmin) {
    return (
      <Navigate
        to={`${setPath(ERoutes.Dashboard, ['admin'])}/app-bundles`}
        state={{ from: location }}
        replace
      />
    );
  }

  if (authenticated && !authLoading) {
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return children ? children : <Outlet />;
};

export default GuestRoutes;
