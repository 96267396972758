import React, { FC, useRef } from 'react';
import { Button } from 'react-bootstrap';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import Api from '../../../../../services/api/api';
import { setError } from '../../../../../stores/common/reducer';
import { IConfigurationRequestsDataItem } from '../../../../../types/producVersionConfigurations';

interface IConfigurationRequestActionCellProps {
  dataItem: IConfigurationRequestsDataItem,
  onResubmit: (requestId: IConfigurationRequestsDataItem) => void,
}

const ConfigurationRequestActionCell: FC<IConfigurationRequestActionCellProps> = ({
  dataItem,
  onResubmit,
}) => {
  const { inPendingList } = useCommon()
  const isInPending = inPendingList.includes(dataItem?.id)
  const requestForgeLogLink = useRef<HTMLAnchorElement | null>(null);

  const onDownloadRequestForgeLogPress = async (item: IConfigurationRequestsDataItem) => {
    if (item) {
      Api.getConfigurationRequestForgeLog(item.id)
        .then(result => {
          const url = URL.createObjectURL(new Blob([result]));
          if (requestForgeLogLink.current) {
            requestForgeLogLink.current.href = url;
          }
          requestForgeLogLink.current?.click();
          URL.revokeObjectURL(url);
        })
    }
  };

  const onDownloadLog = (item: IConfigurationRequestsDataItem) => {
      try {
        onDownloadRequestForgeLogPress(item);
      } catch (e: any) {
        setError(e);
    }
  }

  return (
    <>
      <div className="action-buttons-group">
        <Button variant="outline-secondary" className='k-m-1 pt-0 pb-0' onClick={() => onResubmit(dataItem)} disabled={isInPending}>
          Resubmit
        </Button>
        <a download="Forge log" className="hidden" ref={requestForgeLogLink} />
        <Button variant="outline-secondary" className='k-m-1 pt-0 pb-0' onClick={() => onDownloadLog(dataItem)} disabled={isInPending}>
          Log
        </Button>
      </div>
    </>
  )
}

export default ConfigurationRequestActionCell