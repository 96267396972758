import { all, call, delay, put, select, takeLatest } from 'typed-redux-saga';
import Api from '../../services/api/api';
import {
  getAppBundleAlias,
  getAppBundleAliasList,
  postAppBundleAlias,
  putAppBundleAlias,
  deleteAppBundleAlias,
  setAppBundleAlias,
  setAppBundleAliasList,
  setAppBundleAliasLoading,
  setAppBundleAliasListLoading,
  getAllAppBundleAliases,
  setAllAppBundleAliases,
  setAllAppBundleAliasesLoading,
} from './reducer';
import { popFromPending, pushInPending, setError, setPostReqResp } from '../common/reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  IPostAppBundleAlias,
  IPutAppBundleAlias,
  IDeleteAppBundleAlias,
} from '../../types/appBundleAlias';
import { IState } from '../configureStore';
import { toDataSourceRequestString } from '@progress/kendo-data-query';

function* getAppBundleAliasSaga({ payload: id }: PayloadAction<string>): any {
  try {
    yield put(setAppBundleAliasLoading(true))
    const response = yield* call(Api.getAppBundleAlias, id)
    yield* call(delay, 500);
    yield put(setAppBundleAlias(response))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(setAppBundleAliasLoading(false))
  }
}

function* getAppBundleAliasListSaga({ payload: appBundleId
}: PayloadAction<string>): any {
  try {
    const dataState = yield select(
      (state: IState) => state.appBundleAlias.dataState
    );
    const dataString: string = toDataSourceRequestString({ ...dataState });
    const idParam = `appBundleId=${appBundleId}&`
    yield put(setAppBundleAliasListLoading(true))
    const response = yield* call(Api.getAppBundleAliasList, idParam + dataString);
    yield put(setAppBundleAliasList(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setAppBundleAliasListLoading(false))
  }
}

function* getAllAppBundleAliasesSaga(): any {
  try {
    const dataState = yield select(
      (state: IState) => state.appBundleAlias.dataState
    );
    const dataString: string = toDataSourceRequestString({ ...dataState });
    yield put(setAllAppBundleAliasesLoading(true))
    const response = yield* call(Api.getAllAppBundleAliases, dataString);
    yield put(setAllAppBundleAliases(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setAllAppBundleAliasesLoading(false))
  }
}

function* postAppBundleAliasSaga({ payload: formData }: PayloadAction<IPostAppBundleAlias>) {
  try {
    yield put(setAppBundleAliasLoading(true))
    yield* call(Api.postAppBundleAlias, formData)
    const appBundleId = formData.get('AppBundleId') as string
    yield* put(getAppBundleAliasList(appBundleId))
    yield put(setPostReqResp(appBundleId))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(setAppBundleAliasLoading(false))
  }
}

function* putAppBundleAliasSaga({ payload: formData }: PayloadAction<IPutAppBundleAlias>) {
  try {
    yield* put(setAppBundleAliasLoading(true))
    yield* call(Api.putAppBundleAlias, formData)
    const appBundleId = formData.get('AppBundleId') as string
    yield* put(getAppBundleAliasList(appBundleId))
    yield put(setPostReqResp(appBundleId))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield* put(setAppBundleAliasLoading(false))
  }
}

function* deleteAppBundleAliasSaga({ payload: formData }: PayloadAction<IDeleteAppBundleAlias>) {
  try {
    yield put(pushInPending(formData.id))
    yield* call(Api.deleteAppBundleAlias, formData.id)
    yield* put(getAppBundleAliasList(formData.AppBundleId))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(popFromPending(formData.id))
  }
}

function* AppBundleAliasWatcher() {
  yield all([
    takeLatest(getAppBundleAlias.type, getAppBundleAliasSaga),
    takeLatest(getAppBundleAliasList.type, getAppBundleAliasListSaga),
    takeLatest(getAllAppBundleAliases.type, getAllAppBundleAliasesSaga),
    takeLatest(postAppBundleAlias.type, postAppBundleAliasSaga),
    takeLatest(putAppBundleAlias.type, putAppBundleAliasSaga),
    takeLatest(deleteAppBundleAlias.type, deleteAppBundleAliasSaga),
  ]);
}

export default AppBundleAliasWatcher;
