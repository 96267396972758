import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGridDataFilter, IGridDataStateFilter, IGridFilterSetting, Nullable } from '../../types/common';
import {
  IAppBundleVersion,
  IAppBundleVersionList,
  IPostAppBundleVersion,
  IPutAppBundleVersion,
  IDeleteAppBundleVersion,
} from '../../types/appBundleVersion';

export interface IAppBundleVersionState {
  appBundleVersion: Nullable<IAppBundleVersion>;
  appBundleVersionList: Nullable<IAppBundleVersionList>;
  isAppBundleVersionListLoading: boolean;
  isAppBundleVersionLoading: boolean,
  dataState: IGridDataStateFilter;
}

export const initialAppBundleVersionState: IAppBundleVersionState = {
  appBundleVersion: null,
  appBundleVersionList: null,
  isAppBundleVersionListLoading: false,
  isAppBundleVersionLoading: false,
  dataState: {
    filter: {
      filters: [] as IGridFilterSetting[],
      logic: ''
    } as IGridDataFilter,
    group: '',
    take: 8,
    skip: 0,
    sort: [{ field: 'created', dir: 'desc' }]
  },
};

const appBundleVersionSlice = createSlice({
  name: 'appBundleVersion',
  initialState: initialAppBundleVersionState,
  reducers: {
    getAppBundleVersion: (state, action: PayloadAction<string>) => {
    },
    getAppBundleVersionList: (state, action: PayloadAction<string>) => {
    },
    postAppBundleVersion: (state, action: PayloadAction<IPostAppBundleVersion>) => {
    },
    putAppBundleVersion: (state, action: PayloadAction<IPutAppBundleVersion>) => {
    },
    deleteAppBundleVersion: (state, action: PayloadAction<IDeleteAppBundleVersion>) => {
    },
    setAppBundleVersion: (state, action: PayloadAction<IAppBundleVersion | null>) => {
      state.appBundleVersion = action.payload
    },
    setAppBundleVersionList: (state, action: PayloadAction<IAppBundleVersionList>) => {
      state.appBundleVersionList = action.payload
    },
    setDataState: (state, action: PayloadAction<IGridDataStateFilter>) => {
      state.dataState = action.payload;
    },
    setAppBundleVersionLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppBundleVersionLoading = action.payload
    },
    setAppBundleVersionListLoading: (state, action: PayloadAction<boolean>) => {
      state.isAppBundleVersionListLoading = action.payload
    },
  }
});

export const {
  getAppBundleVersion,
  getAppBundleVersionList,
  postAppBundleVersion,
  putAppBundleVersion,
  deleteAppBundleVersion,
  setAppBundleVersion,
  setAppBundleVersionList,
  setAppBundleVersionListLoading,
  setAppBundleVersionLoading,

  setDataState
} = appBundleVersionSlice.actions;

export default appBundleVersionSlice.reducer;
