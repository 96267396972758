import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';
import { apiRoutes } from '../../../services/api/routes';

const useConfigurations = () => {
  const dataState = useSelector((state: IState) => state.configurations.dataState)
  const configurationsList = useSelector((state: IState) => state.configurations.configurationsList)
  const configurationRequestsList = useSelector((state: IState) => state.configurations.configurationRequestsList)
  const configuration = useSelector((state: IState) => state.configurations.configuration)
  const postConfigurationResponse = useSelector((state:IState) => state.configurations.postConfigurationResponse)
  const paramLevelInfo = useSelector((state:IState) => state.configurations.paramLevelInfo)
  const isConfigLoading = useSelector((state: IState) => state.configurations.isConfigLoading)
  const isConfigurationRequestsListLoading = useSelector((state: IState) => state.configurations.isConfigurationRequestsListLoading)  
  const searchedConfigList = useSelector((state: IState) => state.configurations.searchedConfigList)
  const configParams = useSelector((state: IState) => state.configurations.configParams)
  const svfPath = useSelector((state: IState) => state.configurations.svfPath)
  const isParamsLoading = useSelector((state: IState) => state.configurations.isParamsLoading)
  const outputLink = useSelector((state: IState) => state.configurations.outputLink)

  return {
    dataState,
    configurationsList,
    configurationRequestsList,
    configuration,
    postConfigurationResponse,
    paramLevelInfo,
    configParams,
    searchedConfigList,
    isConfigLoading,
    isConfigurationRequestsListLoading,
    isParamsLoading,
    svfPath: { initial: svfPath, url: svfPath ? apiRoutes.configurations.svf(configuration?.id ?? '') + '/' + svfPath : '' },
    outputLink
  }
}

export default useConfigurations