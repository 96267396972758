export enum ERoutes {
  Root = '/',

  SignUp = '/signup',
  SignUpId = '/signup/:inviteId',
  LinkByInviteId = '/:inviteId',
  EmailConfirmation = '/emailConfirmation/:hash',
  EmailChangeConfirmation = '/emailchange/:hash/:email',
  ForgotPwd = '/forgot-pwd',
  ResetPwd = '/passwordrecovery/:passwordRecoveryKey',

  Organisations = '/organisations',

  Dashboard = '/dashboard/:organizationId',

  Statistics = 'statistics',
  Settings = 'settings',
  Products = 'products',
  ProdVersions = '/dashboard/:organizationId/:productId/products',
  ProdVersion = '/dashboard/:organizationId/:productId/:versionId/products',
  ProdVersionConfig = '/dashboard/:organizationId/:productId/:versionId/:configId/products',
  Publications = 'publications',
  CPThreshold = 'cp-threshold',
  Users = 'users',
  Billing = 'billing',
  AppBundles = '/dashboard/:organizationId/app-bundles',
  AppBundleId = '/dashboard/:organizationId/app-bundles/:appBundleId',
  Manufacturers = 'manufacturers',
  Onlinestores = 'onlinestores',
  Developers = 'developers',
  ConfigurationRequests = 'configuration-requests',
  Orders = 'orders',
  Tariffs = 'tariffs',

  ManageAccount = '/manage-account',
  AccountSecurity = '/manage-account/account-security',
  AccountBilling = '/manage-account/account-billing',
  AdminUserList = '/admin/users',

  PageNotFound = 'pagenotfound'
}
