import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';

const useTelemetry = () => {
  const telemetry = useSelector((state: IState) => state.telemetry.telemetry);
  const isTelemetryLoading = useSelector(
    (state: IState) => state.telemetry.isTelemetryLoading
  );

  return {
    telemetry,
    isTelemetryLoading
  };
};

export default useTelemetry;
