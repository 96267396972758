export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  token: string;
  isSuperAdmin: boolean;
  userRole?: string;
}

export interface ISignInData {
  email: string;
  password: string;
}

export interface ISignUpData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  phone: string;
  externalId?: string;
  userRole: EUserRoles;
  inviteId: string;
}

export interface IAccountInfo extends ISignUpData {}
export interface IPutAccountInfo {
  firstName: string;
  lastName: string;
  phone: string;
  externalId?: string;
}

export interface IPostPasswordRecovery {
  email: string;
}
export interface IPostPasswordRecoveryConfirm {
  newPassword: string;
  confirmPassword: string;
  passwordRecoveryKey: string;
}

export interface IPostChangePassword {
  oldPassword: string;
  password: string;
}

export interface IPostChangeEmail {
  email: string;
  password: string;
}

export interface IPostEmailChangeConfirmation {
  hash: string;
  email: string;
}

export interface IInviteInfo {
  created: string;
  email: string;
  id: string;
  organizationId: string;
  organizationName: string;
  orgUserRole: EOrgUserRoles;
}

export const ACCESS_TOKEN_KEY = 'accessToken';

export enum EUserRoles {
  User = 1,
  SuperAdmin = 2
}

export enum EOrgUserRoles {
  User = 0,
  Admin = 1
}
