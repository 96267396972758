import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IGridDataFilter,
  IGridDataStateFilter,
  IGridFilterSetting,
  Nullable
} from '../../types/common';
import {
  IOnlineStore,
  IPublication,
  IPublicationList,
  IPutPublication,
  IPostPublication
} from './../../types/publications';

export interface IPublicationState {
  isPublicationListLoading: boolean;
  isPublicationLoading: boolean;
  publicationList: Nullable<IPublicationList>;
  publication: Nullable<IPublication>;
  dataState: IGridDataStateFilter;
  onlineStores: IOnlineStore[];
}

export const initialPublicationState: IPublicationState = {
  isPublicationListLoading: false,
  isPublicationLoading: false,
  publicationList: null,
  publication: null,
  dataState: {
    filter: {
      filters: [] as IGridFilterSetting[],
      logic: ''
    } as IGridDataFilter,
    group: '',
    take: 8,
    skip: 0,
    sort: [{ field: 'created', dir: 'desc' }]
  },
  onlineStores: []
};

const publicationSlice = createSlice({
  name: 'publication',
  initialState: initialPublicationState,
  reducers: {
    getPublicationList: (state, action: PayloadAction) => { },
    getPublicationManufacturerList: (state, action: PayloadAction) => { },
    getPublicationById: (state, action: PayloadAction) => {},
    deletePublicationById: (state, action: PayloadAction<string>) => {},
    getPublicationOnlineStores: (state, action: PayloadAction) => {},
    putPublication: (state, action: PayloadAction<IPutPublication>) => {},
    postPublication: (state, action: PayloadAction<IPostPublication>) => {},
    setPublicationList: (
      state,
      action: PayloadAction<IPublicationList | null>
    ) => {
      state.publicationList = action.payload;
    },
    setPublication: (state, action: PayloadAction<IPublication | null>) => {
      state.publication = action.payload;
    },
    setPublicationListLoading: (state, action: PayloadAction<boolean>) => {
      state.isPublicationListLoading = action.payload;
    },
    setPublicationLoading: (state, action: PayloadAction<boolean>) => {
      state.isPublicationLoading = action.payload;
    },
    setPublicationDataState: (
      state,
      action: PayloadAction<IGridDataStateFilter>
    ) => {
      state.dataState = action.payload;
    },
    setPublicationOnlineStores: (
      state,
      action: PayloadAction<IOnlineStore[]>
    ) => {
      state.onlineStores = action.payload;
    },
    setDataState: (state, action: PayloadAction<IGridDataStateFilter>) => {
      state.dataState = action.payload;
    }
  }
});

export const {
  getPublicationList,
  getPublicationManufacturerList,
  getPublicationById,
  deletePublicationById,
  getPublicationOnlineStores,
  putPublication,
  postPublication,
  setPublicationList,
  setPublication,
  setPublicationListLoading,
  setPublicationLoading,
  setPublicationDataState,
  setPublicationOnlineStores,
  setDataState
} = publicationSlice.actions;

export default publicationSlice.reducer;
