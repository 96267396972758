import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPaymentHistory, IPostBuyCloudPoints, IPostPayTariff } from '../../types/billing';

export interface IBillingState {
  paymentHistory: IPaymentHistory[],
  isPaymentHistoryLoading: boolean
}

export const initialTariffState: IBillingState = {
  paymentHistory: [],
  isPaymentHistoryLoading: false
};

const tariffSlice = createSlice({
  name: 'tariff',
  initialState: initialTariffState,
  reducers: {
    postPayTariff: (state, action: PayloadAction<IPostPayTariff>) => { },
    postBuyCloudPoints: (state, action: PayloadAction<IPostBuyCloudPoints>) => { },
    getPaymentHistory: (state, action: PayloadAction<string>) => { },
    setPaymentHistory: (state, action: PayloadAction<IPaymentHistory[]>) => {
      state.paymentHistory = action.payload
    },
    setIsPaymentHistoryLoading: (state, action: PayloadAction<boolean>) => {
      state.isPaymentHistoryLoading = action.payload
    },
  }
});

export const {
  postPayTariff,
  postBuyCloudPoints,
  getPaymentHistory,
  setPaymentHistory,
  setIsPaymentHistoryLoading
} = tariffSlice.actions;

export default tariffSlice.reducer;