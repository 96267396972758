import {
    Grid,
    GridCellProps,
    GridColumn,
    GridDataStateChangeEvent,
    GridItemChangeEvent,
    GridNoRecords,
    GridRowProps
} from '@progress/kendo-react-grid';
import { Checkbox, CheckboxChangeEvent } from '@progress/kendo-react-inputs';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import GridLoader from '../../../../../components/Loaders/GridLoader/GridLoader';
import { enumGetKey } from '../../../../../helpers/enumFunctions';
import useConfigurations from '../../../../../helpers/hooks/storeHooks/useConfigurations';
import {
    getConfigurationList,
    initialConfigurationsState, setConfigurationsDataState,
    setConfigurationsList
} from '../../../../../stores/productConfigurations/reducer';
import { IGridDataState, IStdEnum } from '../../../../../types/common';
import {
    ESvfStatus, IConfigParam, IConfigurationListItem
} from '../../../../../types/producVersionConfigurations';
import ActionsConfigCell from './ActionConfigCell';

interface IConfigurationsTable {
  pageSize: number;
  isCheckboxsBulcOperations: boolean;
}

const rowRender = (trElement: React.ReactElement, props: GridRowProps) => {
  const dataItem = { ...(props.dataItem as IConfigurationListItem) };
  const useAsTemplateConfiguration = dataItem.useAsTemplateConfiguration;
  

  if (useAsTemplateConfiguration) {
    const blue = {
      backgroundColor: 'rgba(13, 110, 253, 0.1)'
    };
    const trProps = {
      style: blue
    };
    return <tr {...trProps}>{trElement.props.children}</tr>;
  }
  return trElement;
};

const EnumToCell = (props: GridCellProps, enumObj: IStdEnum) => {
  const field = props.field || '';
  const dateString = enumGetKey(props.dataItem[field], enumObj);

  return <td>{dateString}</td>;
};

const ToLocalDateTime = (props: GridCellProps) => {
  const field = props.field || '';
  const dateString = new Date(props.dataItem[field]).toLocaleString();

  return <td>{dateString}</td>;
};

const GetParams = (props: GridCellProps) => {
  const dataItem = { ...(props.dataItem as IConfigurationListItem) };

  const sortedParams = [...(dataItem.parameterDefinitions ?? [])]
    .sort((A, B) => A?.displayPriority - B?.displayPriority
                 || A?.displayPosition - B?.displayPosition
                 || A?.displayName.localeCompare(B?.displayName))

  return (
    <td className="changed-params-row">
      <div>
        {sortedParams.map(x => x.name + ": " + x.value + "; ")}
        </div>
    </td>
  );
};

const NameCell = (props: GridCellProps, isCheckboxsBulcOperations: boolean) => {
  const dispatch = useDispatch();
  const { configurationsList } = useConfigurations();

  const dataItem = { ...(props.dataItem as IConfigurationListItem) };

  const onChange = (e: CheckboxChangeEvent) => {
    const newConfigurationList = {
      ...configurationsList,
      data: configurationsList?.data.length
        ? configurationsList.data.map((item: IConfigurationListItem) =>
            item.id === dataItem.id
              ? { ...item, isSelected: !!e.target.value }
              : item
          )
        : [],
      total: configurationsList?.total || 0
    };
    dispatch(setConfigurationsList(newConfigurationList));
  };

  return (
    <td className="td-name-cell">
      <span>{dataItem.configurationName}</span>
      {isCheckboxsBulcOperations && (
        <Checkbox
          className="td-name-checkbox"
          onChange={onChange}
          value={dataItem.isSelected}
        />
      )}
    </td>
  );
};

const ConfigurationsTable: React.FC<IConfigurationsTable> = ({
  pageSize,
  isCheckboxsBulcOperations
}) => {
  const dispatch = useDispatch();
  const { isConfigLoading, configurationsList, dataState } =
    useConfigurations();
  const { versionId } = useParams();
  const [gridData, setGridData] = useState<IConfigurationListItem[]>([]);

  useEffect(() => {
    if (versionId) dispatch(getConfigurationList(versionId));
    return () => {
      dispatch(
        setConfigurationsDataState(initialConfigurationsState.dataState)
      );
    };
  }, []);

  useEffect(() => {
    setGridData(configurationsList?.data ?? []);
  }, [configurationsList]);

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';
    const newData = gridData.map((item) =>
      item.id === event.dataItem.id ? { ...item, [field]: event.value } : item
    );
    setGridData(newData);
  };

  useEffect(() => {
    const newDataSate = {
      ...dataState,
      take: pageSize,
      skip: Math.floor(dataState.skip / pageSize) * pageSize
    };
    dispatch(setConfigurationsDataState(newDataSate));
    dispatch(getConfigurationList(versionId ?? ''));
  }, [pageSize]);

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    dispatch(setConfigurationsDataState(e.dataState as any));
    dispatch(getConfigurationList(versionId ?? ''));
  };

  return (
    <div className="product-grid configurations-grid-component">
      <Grid
        className="configurations-grid"
        data={gridData}
        {...(dataState as IGridDataState)}
        total={configurationsList?.total}
        pageable={true}
        sortable={true}
        onItemChange={itemChange}
        onDataStateChange={onDataStateChange}
        rowRender={rowRender}
      >
        <GridNoRecords>
          <GridLoader isLoading={isConfigLoading} />
        </GridNoRecords>
        <GridColumn
          field="configurationName"
          title="Name"
          cell={(props: GridCellProps) =>
            NameCell(props, isCheckboxsBulcOperations)
          }
        />
        <GridColumn field="componentName" title="Component name" />
        <GridColumn field="id" title="Configuration Id"/>
        <GridColumn
          field="parameterDefinitions"
          title="Parameter Definitions"
          cell={(props: GridCellProps) => GetParams(props)} />
        <GridColumn
          field="svfStatus"
          title="SVF status"
          cell={(props: GridCellProps) => EnumToCell(props, ESvfStatus)}
        />
        <GridColumn
          field="created"
          title="Created"
          cell={(props: GridCellProps) => ToLocalDateTime(props)}
        />
        <GridColumn
          field="useAsTemplateConfiguration"
          title="Action"
          sortable={false}
          className="conf-table-actions"
          cell={ActionsConfigCell}
        />
      </Grid>
    </div>
  );
};

export default ConfigurationsTable;
