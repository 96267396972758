import { useDispatch } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Grid, GridColumn, GridDataStateChangeEvent, GridItemChangeEvent, GridNoRecords, GridToolbar } from '@progress/kendo-react-grid';
import GridLoader from '../../../../components/Loaders/GridLoader/GridLoader';
import { IDesignToStores } from '../../../../types/organisationPage';
import useOrganizations from '../../../../helpers/hooks/storeHooks/useOrganizations';
import { getDesignToStores, setDataStateAssignedUsers } from '../../../../stores/organisation/reducer';
import useAuthCheck from '../../../../helpers/hooks/storeHooks/useAuthCheck';
import { EOrgUserRoles } from '../../../../types/user';
import OnlinestoreActionCell from './componets/OnlinestoreActionCell';
import CreateOnlinestoreBinding from './modals/CreateOnlinestoreBinding';
import ErrorMessage from '../../../../components/ErrorMessage/ErrorMessage';

const OnlineStoresPage = () => {
  const dispatch = useDispatch()
  const { designToStores,
    isLoadingDesignToStores,
    currentOrganization
    } = useOrganizations()
  const [isShowCreateBindingModal, setIsShowCreateBindingModal] = useState(false)
  const [gridData, setGridData] = useState<IDesignToStores[]>([])
  const [isAdministrator, setIsAdministrator] = useState(false);

  useEffect(() => {
    setIsAdministrator(currentOrganization?.orgUserRole === 1);
  }, [currentOrganization]);

  useEffect(() => {
    dispatch(getDesignToStores())
  }, [])

  useEffect(() => {
    setGridData(designToStores ?? [])
  }, [designToStores])

  const onCreateOnlinestoreBindingClick = () => {
    setIsShowCreateBindingModal(!isShowCreateBindingModal)
  }

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    dispatch(setDataStateAssignedUsers(e.dataState as any))
    dispatch(getDesignToStores())
  }
  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';
    const newData = gridData.map(item =>
      item.id === event.dataItem.id
        ? { ...item, [field]: event.value }
        : item
    );
    setGridData(newData)
  };

  return (
    <div>
      <div className="version-table">
          <Grid
            className="product-grid version-grid"
            data={gridData}
           pageable={true}
            onItemChange={itemChange}
            onDataStateChange={onDataStateChange}
          >
          {isAdministrator && (
            <GridToolbar>
              <Button variant="primary" onClick={onCreateOnlinestoreBindingClick}>
                Add binding
              </Button>
            </GridToolbar>
          )}
            <GridNoRecords>
              <GridLoader isLoading={isLoadingDesignToStores} />
            </GridNoRecords>
            <GridColumn field="name" title="Name" />
            <GridColumn field="domain" title="Domain" />
            <GridColumn field="email" title="Email" />
          {isAdministrator && (
              <GridColumn field="action" title="Action" className="action-cell" cell={OnlinestoreActionCell} sortable={false} />
          )}
          </Grid>
      </div>
      {
        isShowCreateBindingModal &&
        <CreateOnlinestoreBinding isOpen={isShowCreateBindingModal} onClose={onCreateOnlinestoreBindingClick} />
      }
      <ErrorMessage style="mt-3" />
    </div>
  )
}

export default OnlineStoresPage