import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { enumGetKey } from '../../../../../helpers/enumFunctions';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import { enumToKeqValue } from '../../../../../helpers/enumFunctions';
import { EOrderStatus } from '../../../../../types/order';

const dataOrderStatus = enumToKeqValue(EOrderStatus);

interface IUsersRoleCell {
  gridProps: GridCellProps;
  onSelectOrderStatus: (orderStatus: number) => void;
}

const OrderStatusCell = ({
  gridProps,
  onSelectOrderStatus
}: IUsersRoleCell) => {
  const { dataItem } = gridProps;

  const handleChange = (event: DropDownListChangeEvent) => {
    onSelectOrderStatus(event.target.value.value);
    dataItem.status = event.target.value.value
  };

  return (
    <td
      colSpan={gridProps.colSpan}
      role="gridcell"
      aria-colindex={gridProps.ariaColumnIndex}
      aria-selected={gridProps.isSelected}
    >
      {dataItem.isInEdit ? (
        <DropDownList
          style={{ width: '150px' }}
          data={dataOrderStatus}
          textField="key"
          dataItemKey="value"
          defaultValue={{
            key: enumGetKey(dataItem.status, EOrderStatus),
            value: dataItem.status
          }}
          onChange={handleChange}
        />
      ) : (
        enumGetKey(dataItem.status, EOrderStatus)
      )}
    </td>
  );
};
export default OrderStatusCell;
