import { GridCellProps } from '@progress/kendo-react-grid';
import { GridEditContext } from '../ProductsPage';
import { Button } from 'react-bootstrap';
import React, { useContext, useState } from 'react';
import { FormSubmitContext } from './GridInlineFormRow';
import { Link, useParams } from 'react-router-dom';
import { ERoutes } from '../../../../../router/Routes';
import { setPath } from '../../../../../helpers/routingFunctions/routingFunctions';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import { IModalWrapperButton } from '../../../../../types/modal';
import { useDispatch } from 'react-redux';
import { delProduct } from '../../../../../stores/product/reducer';
import { IDelProduct } from '../../../../../types/product';

export const ProductsActionCell = (props: GridCellProps) => {
  const dispatch = useDispatch()
  const onSubmit = useContext(FormSubmitContext);
  const { enterEdit, cancel } = useContext(GridEditContext);
  const [isShowModal, setIsShowModal] = useState(false);
  const { organizationId } = useParams();
  const { dataItem } = props;
  const inEdit = dataItem.inEdit;

  const onSaveClick = (e: any) => {
    e.preventDefault();
    onSubmit(e);
  };
  const onEditClick = () => enterEdit(dataItem);
  const onCancelClick = () => cancel(dataItem);

  const onDelete = () => {
    setIsShowModal(true)
  };

  const continueOperation = async () => {
    const sendData = {
      prodId: dataItem.id,
      organisationId: dataItem.organizationId
    } as IDelProduct
    dispatch(delProduct(sendData))
    closeModal();
  }

  const closeModal = () => {
    setIsShowModal(false);
  };

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueOperation
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeModal
    }
  ];

  return (
    <td
      className={props.className}
      colSpan={props.colSpan}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
    >
      <>
        {inEdit ? (
          <>
            <Button
              variant="outline-secondary"
              className="k-m-1 pt-0 pb-0"
              onClick={onSaveClick}
            >
              Update
            </Button>
            <Button
              variant="outline-secondary"
              className="k-m-1 pt-0 pb-0"
              onClick={onCancelClick}
            >
              Cancel
            </Button>
          </>
        ) : (
          <>
            <ModalWrapper
              headerText="Confirm action"
              isOpen={isShowModal}
              className=""
              buttons={modalButtons}
            >
              <div>{"Are you sure you want to continue: delete "}
                  <span className={"modal-wrapper-var"}>{dataItem.name}</span>
                {"?"}
              </div>
            </ModalWrapper>
            <Button
              variant="outline-secondary"
              className="k-m-1 pt-0 pb-0 btn-with-link"
            >
              <Link
                to={setPath(ERoutes.ProdVersions, [
                  organizationId,
                  dataItem.id
                ])}
              >
                Show
              </Link>
            </Button>
            <Button
              variant="outline-secondary"
              className="k-m-1 pt-0 pb-0"
              onClick={onEditClick}
            >
              Edit
            </Button>
            <Button
              variant="outline-secondary"
              className="k-m-1 pt-0 pb-0"
              onClick={() => onDelete()
              }
            >
              Delete
            </Button>
          </>
        )}
      </>
    </td>
  );
};
