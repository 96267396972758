import React, {useRef, useEffect} from 'react';
import { Field, FieldWrapper, Form, FormElement } from '@progress/kendo-react-form';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import CInput from './../../../../../components/form-components/CInput';
import { emailValidator} from '../../../../../components/form-components/helpers/validation-functions';
import { DropDownList,  } from '@progress/kendo-react-dropdowns';
import {  enumToKeqValue } from '../../../../../helpers/enumFunctions';
import { EOrgUserRoles } from '../../../../../types/user';
import { Button } from 'react-bootstrap';
import { IPostInviteUser } from './../../../../../types/organisationPage';
import { inviteUser } from '../../../../../stores/organisation/reducer';
import ErrorMessage from './../../../../../components/ErrorMessage/ErrorMessage';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import { setPostReqResp } from '../../../../../stores/common/reducer';
import useOrganizations from './../../../../../helpers/hooks/storeHooks/useOrganizations';
import Spinner from '../../../../../components/Loaders/Spinner/Spinner';

const dataUsersRole = enumToKeqValue(EOrgUserRoles)

interface IInviteUserForm {
  closeModalInviteUser: () => void
}

const InviteUserForm = ({closeModalInviteUser}: IInviteUserForm) => {
  const formRef = useRef<Form|null>(null)
  const dispatch = useDispatch()
  const {organizationId} = useParams()
  const {postReqResp} = useCommon()
  const {isLoadingAssignedUsers} = useOrganizations()

  useEffect(() => {
    formRef?.current?.valueSetter("orgUserRole",dataUsersRole[0].value)
  },[])

  useEffect(() => {
    if (postReqResp === 200) {
      closeModalInviteUser()
    }
    return ()=> {
      dispatch(setPostReqResp(''))}
  },[postReqResp])

  const onSubmitData = (formData:any) => {

    if(!formRef?.current?.isValid()) return
  
    const postData: IPostInviteUser = { 
      organizationId: organizationId || '',
      email: formData.email,
      orgUserRole: formData.orgUserRole,
    }
    dispatch(inviteUser(postData)) 
  }

  return( 
      <div className="invite-user-modal">
        {isLoadingAssignedUsers 
        ? <Spinner/>
        : <>
            <Form 
              ref={formRef}
              ignoreModified={true}
              onSubmit={onSubmitData}
              render = { () => (
                <FormElement>
                  <div className="my-3">
                    <Field
                      component={ CInput}
                      name="email"
                      label="Email"
                      maxLength={300}
                      validator={emailValidator}
                    />
                  </div>
                  <div className="my-3">
                    <FieldWrapper>
                      <DropDownList
                        style={{width: "150px"}}
                        data={dataUsersRole}
                        name="orgUserRole"
                        textField="key"
                        dataItemKey="value"
                        onChange={val => formRef?.current?.valueSetter("orgUserRole", val.target.value.value)}
                        defaultValue={dataUsersRole[0]}
                        required={true}
                      /> 
                    </FieldWrapper>
                  </div>
                  <Button
                    type="submit"
                  >
                    Invite
                  </Button>
                </FormElement>
            )}/>
            <ErrorMessage style="mt-3"/>
          </> }       
      </div>
  )
}

export default InviteUserForm