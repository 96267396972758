import { GridCellProps } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import { IAppBundleAlias } from '../../../../../types/appBundleAlias';

interface IAppBundleAliasActionCellProps extends GridCellProps {
  onEdit: (dataItem: IAppBundleAlias) => void,
  onDelete: (dataItem: IAppBundleAlias) => void,
  dataItem: IAppBundleAlias,
}

const AppBundleActionCell: FC<IAppBundleAliasActionCellProps> = ({
  dataItem,
  onEdit,
  onDelete,
  ...others
}) => {
  const { inPendingList } = useCommon()
  const isInPending = inPendingList.includes(dataItem?.id)

  return (
    <>
      <td
        className={others.className}
        colSpan={others.colSpan}
        role="gridcell"
        aria-colindex={others.ariaColumnIndex}
        aria-selected={others.isSelected}
      >
        <Button variant="outline-secondary" className='k-m-1 pt-0 pb-0' onClick={() => onEdit(dataItem)} disabled={isInPending}>
          Edit
        </Button>
        <Button
          variant="outline-secondary"
          className='k-m-1 pt-0 pb-0'
          onClick={() => onDelete(dataItem)}
          disabled={isInPending}
        >
          {isInPending ? <Loader type="pulsing" size="small" /> : 'Delete'}
        </Button>
      </td>
    </>
  )
}

export default AppBundleActionCell