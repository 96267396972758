import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, put, takeLatest } from 'typed-redux-saga';
import Api from '../../services/api/api';
import { IPostTariff, IPutTariff, ITariff } from '../../types/tariff';
import { popFromPending, pushInPending, setError, setPostReqResp } from '../common/reducer';
import {
    deleteTariff,
    getTariffById,
    getTariffList,
    postTariff,
    putTariff,
    setIsTariffListLoading,
    setIsTariffLoading,
    setTariff,
    setTariffList
} from './reducer';

function* getTariffListSaga({
}: PayloadAction<ITariff[]>): any {
  try {
    yield put(setIsTariffListLoading(true))
    const response = yield* call(Api.getTariff);
    yield put(setTariffList(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsTariffLoading(false));
  }
}

function* getTariffSaga({ payload: tariffId
}: PayloadAction<string>) {
  try {
    yield put(setIsTariffLoading(true))
    const response = yield* call(Api.getTariffById, tariffId);
    yield put(setTariff(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsTariffLoading(false));
  }
}

function* postTariffSaga({ payload: formData }: PayloadAction<IPostTariff>) {
  try {
    yield put(setIsTariffLoading(true))
    const res = yield* call(Api.postTariff, formData)
    yield* put(getTariffList())
    yield put(setPostReqResp(res))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(setIsTariffLoading(false))
  }
}

function* putTariffSaga({ payload: formData }: PayloadAction<IPutTariff>) {
  try {
    yield* put(setIsTariffLoading(true))
    yield* call(Api.putTariff, formData)
    yield* put(getTariffList())
    yield put(setPostReqResp('200'))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield* put(setIsTariffLoading(false))
  }
}

function* deleteTariffSaga({ payload: id }: PayloadAction<string>) {
  try {
    yield put(pushInPending(id))
    const queryStr = `?tariffId=${id}`
    yield* call(Api.deleteTariff, queryStr)
    yield* put(getTariffList())
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(popFromPending(id))
  }
}

function* TariffWatcher() {
  yield all([
    takeLatest(getTariffList.type, getTariffListSaga),
    takeLatest(getTariffById.type, getTariffSaga),
    takeLatest(postTariff.type, postTariffSaga),
    takeLatest(putTariff.type, putTariffSaga),
    takeLatest(deleteTariff.type, deleteTariffSaga),
  ]);
}

export default TariffWatcher;
