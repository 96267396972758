import useJwtData from './useJwtData';
import { setUser } from '../../stores/authentication/reducer';
import { store } from '../../stores/configureStore';

const useUserFromToken = () => {
  const { isExpired, FirstName, LastName, UserId, Email, Phone, UserRole, token } =
    useJwtData();

  if (!isExpired && FirstName) {
    store.dispatch(
      setUser({
        id: UserId,
        firstName: FirstName,
        lastName: LastName,
        email: Email,
        phone: Phone,
        token: token,
        userRole: UserRole,
        isSuperAdmin: +UserRole === 2
      })
    );
  } else {
    store.dispatch(setUser(null));
  }
};

export default useUserFromToken;
