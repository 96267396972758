import { all } from 'redux-saga/effects';
import authSlice, { IAuthState } from './authentication/reducer';
import authWatcher from './authentication/sagas';
import organisationWatcher from './organisation/sagas';
import createSagaMiddleware from 'redux-saga';
import commonSlice, { ICommonState } from './common/reducer';
import organisationSlice, { IOrganisationState } from './organisation/reducer';
import productSlice, { IProductState } from './product/reducer';
import commonWatcher from './common/sagas';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import productWatcher from './product/sagas';
import prodVersionSlice, { IProdVersionState } from './productVersion/reducer';
import prodVersionWatcher from './productVersion/sagas';
import configurationsSlice, {
  IConfigurationsState
} from './productConfigurations/reducer';
import configurationsWatcher from './productConfigurations/sagas';
import appBundleWatcher from './appBundle/sagas';
import appBundleSlice, { IAppBundleState } from './appBundle/reducer';
import publicationSlice, { IPublicationState } from './publication/reducer';
import publicationWatcher from './publication/sagas';
import telemetrySlice, { ITelemetryState } from './telemetry/reducer';
import TelemetryWatcher from './telemetry/sagas';
import orderSlice, { IOrderState } from './order/reducer';
import OrderWatcher from './order/sagas';
import tariffSlice, { ITariffState } from './tariff/reducer';
import TariffWatcher from './tariff/sagas';
import billingSlice, { IBillingState } from './billing/reducer';
import BillingWatcher from './billing/sagas';
import appBundleAliasSlice, { IAppBundleAliasState } from './appBundleAlias/reducer';
import appBundleAliasWatcher from './appBundleAlias/sagas';
import appBundleVersionSlice, { IAppBundleVersionState } from './appBundleVersion/reducer';
import appBundleVersionWatcher from './appBundleVersion/sagas';

export interface IState {
  auth: IAuthState;
  common: ICommonState;
  organisation: IOrganisationState;
  product: IProductState;
  prodVersion: IProdVersionState;
  configurations: IConfigurationsState;
  appBundle: IAppBundleState;
  publications: IPublicationState;
  telemetry: ITelemetryState;
  orders: IOrderState;
  tariff: ITariffState;
  billing: IBillingState;
  appBundleAlias: IAppBundleAliasState;
  appBundleVersion: IAppBundleVersionState;
}

const reducers = combineReducers<IState>({
  auth: authSlice,
  common: commonSlice,
  organisation: organisationSlice,
  product: productSlice,
  prodVersion: prodVersionSlice,
  configurations: configurationsSlice,
  appBundle: appBundleSlice,
  publications: publicationSlice,
  telemetry: telemetrySlice,
  orders: orderSlice,
  tariff: tariffSlice,
  billing: billingSlice,
  appBundleAlias: appBundleAliasSlice,
  appBundleVersion: appBundleVersionSlice
});

function* sagas() {
  yield all([
    authWatcher(),
    commonWatcher(),
    organisationWatcher(),
    productWatcher(),
    prodVersionWatcher(),
    configurationsWatcher(),
    appBundleWatcher(),
    publicationWatcher(),
    TelemetryWatcher(),
    OrderWatcher(),
    TariffWatcher(),
    BillingWatcher(),
    appBundleAliasWatcher(),
    appBundleVersionWatcher()
  ]);
}

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ thunk: false, serializableCheck: false }).concat(
      sagaMiddleware
    )
});

sagaMiddleware.run(sagas);
