import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';

const useOrders = () => {
  const orderList = useSelector((state: IState) => state.orders.orderList);
  const isOrdersLoading = useSelector(
    (state: IState) => state.orders.isOrdersLoading
  );
  const dataState = useSelector((state: IState) => state.orders.dataState);

  return {
    orderList,
    isOrdersLoading,
    dataState
  };
};

export default useOrders;
