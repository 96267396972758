import { useDispatch } from 'react-redux';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { IModalWrapperButton } from '../../../../../types/modal';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import CInput from '../../../../../components/form-components/CInput';
import { isEmpty, isUuidErrorMsg } from '../../../../../components/form-components/helpers/validation-functions';
import { Button } from 'react-bootstrap';
import { ICommonModalProps } from '../../../../../types/common';
import { setError, setPostReqResp } from '../../../../../stores/common/reducer';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import { postDesignToManufacturersBinding } from '../../../../../stores/organisation/reducer';

const CreateOrganizationBinding: FC<ICommonModalProps> = ({
  isOpen,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { postReqResp, error } = useCommon()
  const formRef = useRef<Form | null>(null)
  const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null)

  const [formState, setFormState] = useState<any>({
    ManufacturerId: '',
  })
  const modalButtons: IModalWrapperButton[] = [
    { buttonText: 'close', onButtonClick: () => onClose() },
    { buttonText: 'save', onButtonClick: () => formSubmitBtnRef?.current?.click() }
  ]

  const [err, setErr] = useState<string>('');

  useEffect(() => {
    if (error !== '' && error?.response) {
      if (error?.response?.status === 404) {
        setErr("Id is not valid or not exists")
      }
      if (error?.response?.status === 422) {
        setErr(`${error?.response?.data?.message}`)
      }
    }
  }, [error])

  useEffect(() => {
    return () => {
      dispatch(setError(''))
    }
  }, [onClose])

  useEffect(() => {
    if (postReqResp.status === 200) {
      onClose()
      dispatch(setPostReqResp(''))
    }
  }, [postReqResp])

  const onSubmitLocal = (formData: any) => {
    if (!formRef?.current?.isValid()) return

    const data = formData;
    dispatch(postDesignToManufacturersBinding(data.ManufacturerId as string))
  }

  return (
    <ModalWrapper
      headerText={"Add binding"}
      isOpen={isOpen}
      onClose={onClose}
      buttons={modalButtons}
      className={'create-binding-form'}
    >
      <Form
        onSubmit={onSubmitLocal}
        initialValues={formState}
        key={JSON.stringify(formState)}
        ref={formRef}
        render={(formRenderProps) => (
          <FormElement className={'modal-body'}>
                <fieldset className={"k-form-fieldset"}>
                  <div className="mb-3">
                    <Field
                      name="ManufacturerId"
                      component={CInput}
                      label="Manufacturer Id"
                      validator={[isEmpty, isUuidErrorMsg]}
                      maxLength={36}
                    />
                  </div>
                  {err && (
                    <div className={'text-danger small'}>{err}</div>
                  )}
                </fieldset>
            <Button
              type="submit"
              hidden={true}
              ref={formSubmitBtnRef}
            >
              submit
            </Button>
          </FormElement>
        )}
      />

    </ModalWrapper>
  )
}

export default CreateOrganizationBinding