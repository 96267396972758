import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Checkbox } from '@progress/kendo-react-inputs';
import React, { FC, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import CInput from '../../../../../components/form-components/CInput';
import { isEmpty } from '../../../../../components/form-components/helpers/validation-functions';
import Spinner from '../../../../../components/Loaders/Spinner/Spinner';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import useAppBundle from '../../../../../helpers/hooks/storeHooks/useAppBundle';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import { getAppBundleById, postAppBundle, putAppBundle } from '../../../../../stores/appBundle/reducer';
import { setPostReqResp } from '../../../../../stores/common/reducer';
import { IPostAppBundle, IPutAppBundle } from '../../../../../types/appBundle';
import { ICommonModalProps } from '../../../../../types/common';
import { IModalWrapperButton } from '../../../../../types/modal';

interface ICreateAppBundle extends ICommonModalProps {
  dataToUpdateId?: string,
}

const CreateAppBundleModal: FC<ICreateAppBundle> = ({
  isOpen,
  dataToUpdateId,
  onClose,
}) => {
  const dispatch = useDispatch()
  const { appBundle, isBundleLoading } = useAppBundle()
  const { postReqResp } = useCommon()
  const formRef = useRef<Form | null>(null)
  const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null)
  const [isActiveChecked, setIsActiveChecked] = useState(false);

  const handleOnChangeIsActive = () => {
    setIsActiveChecked(!isActiveChecked);
  };

  const headerText = dataToUpdateId ? 'Update Bundle' : 'Create Bundle';
  const [formState, setFormState] = useState({
    Name: '',
    isActive: false,
  })
  const modalButtons: IModalWrapperButton[] = [
    { buttonText: 'close', onButtonClick: () => onClose() },
    { buttonText: 'save', onButtonClick: () => formSubmitBtnRef?.current?.click(), buttonDisabled: isBundleLoading }
  ]

  useEffect(() => {
    if (isOpen && dataToUpdateId) {
      dispatch(getAppBundleById(dataToUpdateId))
    }
    else {
      setFormState({
        Name: '',
        isActive: false,
      })
    }
  }, [isOpen])
  useEffect(() => {
    if (appBundle) {
      setFormState({
        Name: appBundle.name,
        isActive: appBundle.isActive,
      })
    }

  }, [appBundle])
  useEffect(() => {
    if (postReqResp) {
      onClose()
      dispatch(setPostReqResp(''))
    }
  }, [postReqResp, isBundleLoading])

  const onSubmitLocal = (formData: any) => {
    if (!formRef?.current?.isValid()) return

    const data = new FormData();
    data.append('Name', formData.Name)
    data.append('isActive', formData.isActive)

    if (!dataToUpdateId) {
      dispatch(postAppBundle(data as IPostAppBundle))
      onClose()
    }
    else {
      data.append('id', dataToUpdateId)
      dispatch(putAppBundle(data as IPutAppBundle))
    }
  }

  return (
    <ModalWrapper
      headerText={headerText}
      isOpen={isOpen}
      onClose={onClose}
      buttons={modalButtons}
      className={'create-appbundle-form'}
    >
      <Form
        onSubmit={onSubmitLocal}
        ignoreModified={true}
        initialValues={formState}
        key={JSON.stringify(formState)}
        ref={formRef}
        render={(formRenderProps) => (
          <FormElement className={'modal-body'}>
            {isBundleLoading ? (
              <Spinner />
            ) : (
              <fieldset className={"k-form-fieldset"}>
                <div className="mb-3">
                  <Field
                    name="Name"
                    component={CInput}
                    label="Bundle name"
                    validator={isEmpty}
                  />
                </div>
                <div className="mb-3">
                  <Field
                    name="isActive"
                    className="is-Active"
                    label="Is active"
                    component={Checkbox}
                    onChange={handleOnChangeIsActive}
                    defaultChecked={isActiveChecked}
                  />
                </div>
              </fieldset>
            )}
            <Button
              type="submit"
              hidden={true}
              ref={formSubmitBtnRef}
            >
              submit
            </Button>
          </FormElement>
        )}
      />
    </ModalWrapper>
  )
}

export default CreateAppBundleModal