import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent,
  GridItemChangeEvent,
  GridNoRecords,
  GridToolbar
} from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import GridLoader from '../../../../../components/Loaders/GridLoader/GridLoader';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import useAppBundleAlias from '../../../../../helpers/hooks/storeHooks/useAppBundleAlias';
import useAppBundleVersion from '../../../../../helpers/hooks/storeHooks/useAppBundleVersion';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import {
  initialAppBundleState,
  setAppBundleDataState
} from '../../../../../stores/appBundle/reducer';
import {
    deleteAppBundleAlias,
  getAppBundleAliasList
} from '../../../../../stores/appBundleAlias/reducer';
import { setError } from '../../../../../stores/common/reducer';
import {
  IAppBundleAlias, IDeleteAppBundleAlias,
} from '../../../../../types/appBundleAlias';
import { IGridDataState, Nullable } from '../../../../../types/common';
import { IModalWrapperButton } from '../../../../../types/modal';
import CreateAppBundleAliasModal from '../modals/CreateAppBundleAliasModal';
import AppBundleAliasActionCell from './AppBundleAliasActionCell';

const AppBundleAlias = () => {
  const dispatch = useDispatch()
  const { appBundleId = '' } = useParams()
  const { isAppBundleAliasListLoading, appBundleAliasList, dataState } = useAppBundleAlias()
  const { appBundleVersionList } = useAppBundleVersion()
  const [isShowAddBundleModal, setIsShowAddBundleModal] = useState(false)
  const [bundleIdToEdit, setBundleIdToEdit] = useState('');
  const [gridData, setGridData] = useState<IAppBundleAlias[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const { error } = useCommon()
  const [err, setErr] = useState<string>('');
  const [deletingAppBundleAlias, setDeletingAppBundleAlias] = useState<Nullable<IAppBundleAlias>>(null);

  useEffect(() => {
    dispatch(getAppBundleAliasList(appBundleId))
    return () => {
      dispatch(setAppBundleDataState(initialAppBundleState.dataState))
    }
  }, [dispatch, appBundleId])
  useEffect(() => {
    setGridData(appBundleAliasList?.data ?? [])
  }, [appBundleAliasList])

  useEffect(() => {
    if (error !== '' && error?.response?.status === 422 && error?.response?.data?.message) {
      setErr(error?.response?.data?.message)
    }
  }, [error])

  useEffect(() => {
    if (err !== '') {
      setIsShowErrorModal(true)
      setErr('')
    }
    return () => {
      dispatch(setError(''))
    }
  }, [err])

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    dispatch(setAppBundleDataState(e.dataState as any))
    dispatch(getAppBundleAliasList(appBundleId))
  }

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';
    const newData = gridData.map(item =>
      item.id === event.dataItem.id
        ? { ...item, [field]: event.value }
        : item
    );
    setGridData(newData)
  };

  const toggleAppBundleModal = () => {
    if (appBundleVersionList?.data.length) {
      setIsShowAddBundleModal(!isShowAddBundleModal)
      setBundleIdToEdit('')
    } else {
      window.alert(`To add an alias, load appbundle version first`)
    }
  }

  const onEdit = (item: IAppBundleAlias) => {
    setBundleIdToEdit(item.id)
    setIsShowAddBundleModal(true)
  }

  const onDelete = (item: IAppBundleAlias) => {
    setDeletingAppBundleAlias(item)
    setIsShowModal(true)
  };

  const continueOperation = () => {
    if (deletingAppBundleAlias) {
      const payload = {
        id: deletingAppBundleAlias.id,
        name: deletingAppBundleAlias.name,
        AppBundleId: deletingAppBundleAlias.appBundleId
      } as IDeleteAppBundleAlias
      dispatch(deleteAppBundleAlias(payload))
    }
    closeModal();
  }

  const closeModal = () => {
    setIsShowModal(false);
    setIsShowErrorModal(false);
  };

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueOperation
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeModal
    }
  ];

  const cantDeleteButton: IModalWrapperButton[] = [
    {
      buttonText: 'Ok',
      onButtonClick: closeModal
    }
  ];

  return (
    <div className="product-page app-bundle-page">
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowErrorModal}
        className=""
        buttons={cantDeleteButton}
      >
        <div>
          {"You can't delete "}
          <span className={"modal-wrapper-var"}>{deletingAppBundleAlias?.fullName}</span>
          {" because it is being used in another component"}
        </div>
      </ModalWrapper>
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowModal}
        className=""
        buttons={modalButtons}
      >
        <div>
          <div>{"Are you sure you want to continue: delete "}
            <span className={"modal-wrapper-var"}>{deletingAppBundleAlias?.fullName}</span>
            {"?"}
          </div>
        </div>
      </ModalWrapper>
      <Grid
        className="product-grid app-bundle-grid"
        data={gridData}
        {...(dataState as IGridDataState)}
        total={appBundleAliasList?.total}
        pageable={true}
        sortable={true}
        onItemChange={itemChange}
        onDataStateChange={onDataStateChange}
      >
        <GridToolbar>
          <Button variant="primary" onClick={toggleAppBundleModal}>
            Add
          </Button>
        </GridToolbar>
        <GridNoRecords>
          <GridLoader isLoading={isAppBundleAliasListLoading} />
        </GridNoRecords>
        <GridColumn field="name" title="Name" />
        <GridColumn field="fullName" title="Full Name" />
        <GridColumn field="designGearVersion" title="DesignGear version" sortable={false} editable={false} />
        <GridColumn field="inventorVersion" title="Inventor version" sortable={false} editable={false} />
        <GridColumn
          field="action"
          title="Action"
          className="action-cell"
          sortable={false}
          cell={(props: GridCellProps) =>
            <AppBundleAliasActionCell onEdit={onEdit} onDelete={onDelete} {...props} />
          }
        />
      </Grid>
      <CreateAppBundleAliasModal isOpen={isShowAddBundleModal} onClose={toggleAppBundleModal} dataToUpdateId={bundleIdToEdit} />
    </div>
  );
};

export default AppBundleAlias;
