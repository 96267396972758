import React from 'react';
import { Field } from '@progress/kendo-react-form';
import CInput from '../../../../../components/form-components/CInput';
import { isEmpty } from '../../../../../components/form-components/helpers/validation-functions';

export interface IFormText {
  isInEdit: boolean;
  text: string;
  name: string;
}
export const FormText = ({isInEdit, text, name}: IFormText ) => { 

  return (
    <div>      
      {
        isInEdit ?
          <Field
            component={ CInput}
            name={name}
            validator={isEmpty}
            rows={1}
          />
          : text
          }
    </div>
  );
};