import { IKendoResp } from './common';

export interface IGetConfigurations  {
  data: IConfigurationListItem[],
  total:number
}
export interface IConfiguration {
  id: string,
  name: string,
  componentName: string,
  comment: string,
  modelState: EModelState,
  productVersionId: string,
  targetFileId: string,
  parentId: string,
  created: string,
  status: EConfigurationStatus,
  svfStatus: ESvfStatus,
  useAsTemplateConfiguration: boolean,
  parameterDefinitions: IConfigParam[],
  hasLogFiles: boolean
}
export interface IParameterChangesItem {
  name: string;
  value: string;
}

export interface IConfigurationRequestsDataItemResponse {
  id: string,
  parameterChanges: IParameterChangesItem[],
  resultConfigurationId: string,
  sourceConfigurationId: string
}

export interface IConfigurationRequestsDataItem {
  index: number,
  indexNumber: number,
  id: string,
  parameterChanges: IParameterChangesItem[],
  resultConfigurationId: string,
  sourceConfigurationId: string,
  created: string;
  productName:string,
  productVersionName:string,
  status:string;
}

export interface IConfigurationRequests {
  total: number,
  data: IConfigurationRequestsDataItem[]
}

export interface IConfigurationRequestById {
  id: string,
  sourceConfigurationId: string,
  resultConfigurationId: string
}

export interface IPostConfigurations {
  name: string,
  comment: string,
  baseConfigurationId: string,
  parameterValues: IPostParamValues[] 
}

export interface IPostConfigurationsMulti {
	name: string,
	comment: string,
	baseConfigurationId: string,
	parameterValues: IPostParamValuesMulti[] 
  }

export interface IPostParamValues {
  parameterDefinitionId: string,
  value: string 
}

export interface IPostParamValuesMulti {
  parameterDefinitionId: string,
  values: string[]
}

export interface IParamsLevelInfo {
  currentLevel: number | null,
  maxLevel: number
}

export interface IPutConfigurations {
  id: string,
  name: string,
  comment: string,
  useAsTemplateConfiguration: boolean
}

export interface IDeleteConfiguration {
  id: string,
  deleteTemplateConfiguration: boolean,
  versionId: string
}

export interface IPutConfigurationsData {
  formData: IPutConfigurations,
  productVersionId:string
}

export interface IConfigurationListItem {
  id: string,
  configurationName: string,
  componentName: string,
  comment: string,
  parentId: string,
  created: string,
  status: EConfigurationStatus,
  svfStatus: ESvfStatus,
  useAsTemplateConfiguration:boolean
  isSelected: boolean,
  deletedModel: boolean,
  parameterDefinitions: IConfigParam[]
}

export interface IConfigurationParamData {
  childs: IConfigurationParamData[],
  componentId: string,
  componentName: string,
  parameters: IConfigParam[]
}

export interface IConfigParam {
  id: string,
  displayPriority: number,
  displayPosition: number,
  name: string,
  displayName: string,
  valueType: EParameterValueType,
  units: string,
  configurationId: string,
  isReadOnly: boolean,
  isHidden: boolean,
  allowCustomValues: boolean,
  value: string,
  valueOptions: IConfParamOptions[],
  childs: string[],
  level: number,
}

export interface IConfigParamMulti {
	id: string,
	displayPriority: number,
	name: string,
	displayName: string,
	valueType: EParameterValueType,
	units: string,
	configurationId: string,
	isReadOnly: boolean,
	isHidden: boolean,
	allowCustomValues: boolean,
	value: string[],
	valueOptions: IConfParamOptions[],
	childs: string[],
	level: number,
  }

export interface IConfParamOptions {
  id: string,
  value: string,
  parameterDefinitionId: string,
  created: string,
}

export interface IConfParamOptionsMulti {
	id: string,
	value: string[],
	parameterDefinitionId: string,
	created: string,
  }


export interface ISearchConfigPayload {
  id: string,
  value: string,
  take?: number,
  skip?: number,
}

export interface PostConfigurationResponse { 
  configurationRequestId: string | null,
  resultConfigurationId: string | null
}

export interface IPageSizeSetItem {
  id:string,
  value: string
}

export const PAGE_SIZE_SET = [{id: "5", value: "5"},{id: "10", value: "10"},{id: "30", value: "30"},{id: "100", value: "100"}]

export interface IDeleteBulkConfigurations {
  idsData: string[];
  versionId: string;
  deleteTemplateConfiguration: boolean;
}

export interface IPostBulkOperations {
  idsData: string[];
  versionId: string;
}

export interface IGetConfigurationOutput {
  configurationId: string;
  orderId: string;
}

export enum EModelState {
  Master = 0
}

export enum ESvfStatus {
  Pending = 0,
  InQueue = 1,
  InProcess = 2,
  ServiceUnavailableError = 4,
  IncorrectRequestError = 8,
  Ready = 16,
  UploadingToForge = 32,
  ReadyForDownload = 64
}

export enum EConfigurationStatus {
  InQueue = 1,
  InProcess = 2,
  ServiceUnavailableError = 4,
  IncorrectRequestError = 8,
  InvalidConfiguration = 16,
  Ready = 32
}

export enum EParameterValueType {
  String = 1,
  Int,
  Double
}