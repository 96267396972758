import { GridCellProps } from '@progress/kendo-react-grid';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import { IModalWrapperButton } from '../../../../../types/modal';
import { useDispatch } from 'react-redux';
import { deleteDesignToManufacturersBinding } from '../../../../../stores/organisation/reducer';

const ManufacturersActionCell = (props: GridCellProps) => {
  const { dataItem } = props;
  const dispatch = useDispatch()
  const [isShowModal, setIsShowModal] = useState(false);

  const onDelete = () => {
    setIsShowModal(true)
  };

  const continueOperation = async () => {
    dispatch(deleteDesignToManufacturersBinding(dataItem.id));
    closeModal();
  }

  const closeModal = () => {
    setIsShowModal(false);
  };

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueOperation
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeModal
    }
  ];

  return (
    <>
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowModal}
        buttons={modalButtons}
      >
        <div>{"Are you sure you want to continue: delete "}
          <span className={"modal-wrapper-var"}>{dataItem.name}</span>
          {"?"}
        </div>
      </ModalWrapper>
      <td
        className={props.className}
        colSpan={props.colSpan}
        role="gridcell"
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
      >
        <Button
          variant="outline-secondary"
          className="k-m-1 pt-0 pb-0"
          onClick={() => onDelete()}
        >
          Delete
        </Button>
      </td>
    </>
  );
};
export default ManufacturersActionCell;
