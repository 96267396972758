import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IGridDataFilter, IGridDataStateFilter, IGridFilterSetting, Nullable } from '../../types/common';
import {
  IConfiguration,
  IConfigurationRequests,
  IConfigurationParamData,
  IGetConfigurations,
  IParamsLevelInfo,
  IPostConfigurations, IPostConfigurationsMulti, IPutConfigurationsData,
  ISearchConfigPayload,
  PostConfigurationResponse,
  IPostBulkOperations,
  IDeleteConfiguration,
  IDeleteBulkConfigurations
} from '../../types/producVersionConfigurations';

export interface IConfigurationsState {
  isConfigLoading: boolean,
  isParamsLoading: boolean,
  isConfigurationRequestsListLoading:boolean;
  configurationsList: Nullable<IGetConfigurations>,
  configurationRequestsList: Nullable<IConfigurationRequests>,
  searchedConfigList: Nullable<IGetConfigurations>,
  configuration: Nullable<IConfiguration>,
  configParams: Nullable<IConfigurationParamData>,
  svfPath: string,
  postConfigurationResponse: PostConfigurationResponse,
  dataState: IGridDataStateFilter,
  paramLevelInfo: Nullable<IParamsLevelInfo>,
  outputLink: string;
}

export const initialConfigurationsState: IConfigurationsState = {
  isConfigLoading: false,
  isParamsLoading: false,
  isConfigurationRequestsListLoading:true,
  configurationsList: null,
  configurationRequestsList: null,
  searchedConfigList: null,
  configuration: null,
  configParams: null,
  paramLevelInfo: null,
  svfPath: '',
  outputLink: '',
  postConfigurationResponse: { resultConfigurationId: null, configurationRequestId: null },
  dataState: {
    filter: {
      filters: [] as IGridFilterSetting[],
      logic: ''
    } as IGridDataFilter,
    group: '',
    take: 5,
    skip: 0,
    sort: [{ field: 'created', dir: 'desc' }]
  }
}

const configurationsSlice = createSlice({
  name: "configurations",
  initialState: initialConfigurationsState,
  reducers: {
    getConfigurationList: (state, action: PayloadAction<string>) => {
    },
    getConfigurationById: (state, action: PayloadAction<string>) => {
    },
    getSVFStatusById:  (state, action: PayloadAction<string>) => {
    },
    getConfigurationByRequest: (state,action: PayloadAction<string>) => {
    },
    getConfigurationByRequestId: (state, action: PayloadAction<string>) => {
    },
    searchConfiguration: (state, action: PayloadAction<ISearchConfigPayload>) => {
    },
    getConfigParams: (state, action: PayloadAction<string>) => {
    },
    getSvfPath: (state, action: PayloadAction<string>) => {
    },
    postConfig: (state, action: PayloadAction<IPostConfigurations>) => {
    },
    postConfigMulti: (state, action: PayloadAction<IPostConfigurationsMulti>) => {
    },
    deleteConfigurationsByIds: (state, action: PayloadAction<IDeleteBulkConfigurations>) => {
    },
    deleteConfiguration: (state, action: PayloadAction<IDeleteConfiguration>) => {
    },
    recalculateConfigurationsByIds: (state, action: PayloadAction<IPostBulkOperations>) => {
    },
    generatesvfConfigurationsByIds: (state, action: PayloadAction<IPostBulkOperations>) => {
    },
    putConfig: (state, action: PayloadAction<IPutConfigurationsData>) => {
    },
    setConfigParams: (state, action: PayloadAction<IConfigurationParamData>) => {
      state.configParams = action.payload
    },
    setConfigurationsList: (state, action: PayloadAction<IGetConfigurations>) => {
      state.configurationsList = action.payload
    },
    setConfigurationRequestsList: (state, action: PayloadAction<IConfigurationRequests>) => {
  	state.configurationRequestsList = action.payload
    },
    setConfiguration: (state, action: PayloadAction<IConfiguration | null>) => {
      state.configuration = action.payload
    },
    setPostConfigurationResponse: (state, action: PayloadAction<PostConfigurationResponse>) => {
      state.postConfigurationResponse = action.payload
    },
    setSearchedConfigList: (state, action: PayloadAction<IGetConfigurations>) => {
      state.searchedConfigList = action.payload
    },
    setSvfPath: (state, action: PayloadAction<string>) => {
      state.svfPath = action.payload
    },
    setConfigurationsDataState: (state, action: PayloadAction<IGridDataStateFilter>) => {
      state.dataState = action.payload
    },
    setIsConfigLoading: (state, action: PayloadAction<boolean>) => {
      state.isConfigLoading = action.payload
    },	
    setIsParamsLoading: (state, action: PayloadAction<boolean>) => {
      state.isParamsLoading = action.payload
    },
	  setIsConfigurationRequestsLoading: (state, action: PayloadAction<boolean>) => {
	    state.isConfigurationRequestsListLoading = action.payload
	  },  
    setParamLevelInfo: (state, action: PayloadAction<IParamsLevelInfo>) => {
      state.paramLevelInfo = action.payload
    },
    resubmitConfigurationRequest: (state, action: PayloadAction<string>) => {
    },
    getRequestForgeLog: (state, action: PayloadAction<string>) => {
    },
    getOutputLink: (state, action: PayloadAction<string>) => { },
    setOutputLink: (state, action: PayloadAction<string>) => {
      state.outputLink = action.payload;
    },
  }
});

export const {
  getConfigurationList,
  getConfigurationById,
  getSVFStatusById,
  getConfigurationByRequest,
  getConfigurationByRequestId,
  getConfigParams,
  searchConfiguration,
  getSvfPath,
  postConfig,
  postConfigMulti,
  deleteConfigurationsByIds,
  deleteConfiguration,
  recalculateConfigurationsByIds,
  generatesvfConfigurationsByIds,
  putConfig,
  setParamLevelInfo,
  setConfiguration,
  setPostConfigurationResponse,
  setConfigParams,
  setConfigurationRequestsList,
  setConfigurationsList,
  setSearchedConfigList,
  setSvfPath,
  setConfigurationsDataState,
  setIsConfigLoading,
  setIsConfigurationRequestsLoading,
  setIsParamsLoading,
  resubmitConfigurationRequest,
  getRequestForgeLog,
  getOutputLink,
  setOutputLink
} = configurationsSlice.actions

export default configurationsSlice.reducer