import { Field, FieldProps, Form, FormElement } from "@progress/kendo-react-form";
import { FC, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import CInput from "../../../../../components/form-components/CInput";
import { isEmpty } from "../../../../../components/form-components/helpers/validation-functions";
import ModalWrapper from "../../../../../components/ModalWrapper/ModalWrapper";
import useCommon from "../../../../../helpers/hooks/storeHooks/useCommon";
import { postChangeEmail } from "../../../../../stores/authentication/reducer";
import { setError, setPostReqResp } from "../../../../../stores/common/reducer";
import { ICommonModalProps } from "../../../../../types/common";
import { IModalWrapperButton } from "../../../../../types/modal";
import { IPostChangeEmail } from "../../../../../types/user";

interface IChangeEmailModal extends ICommonModalProps {
  email: string,
  isEmailChanged: (status: boolean) => void;
}

const ChangeEmailModal: FC<IChangeEmailModal> = ({ isOpen, onClose, isEmailChanged }) => {
  const dispatch = useDispatch()
  const { postReqResp, error } = useCommon()

  const headerText = 'Change email';
  const formSubmitBtnRef = useRef<HTMLButtonElement | null>(null)
  const modalButtons: IModalWrapperButton[] = [
    { buttonText: 'close', onButtonClick: () => onClose() },
    { buttonText: 'save', onButtonClick: () => formSubmitBtnRef?.current?.click() }
  ]

  const [err, setErr] = useState<string>('');

  useEffect(() => {
    if (error !== '' && error?.response) {
      if (error?.response?.status === 404) {
        setErr('Password does not match')
      }
      if (error?.response?.status === 422) {
        setErr(`${error?.response?.data?.message}`)
      }
    }
  }, [error])

  useEffect(() => {
    return () => {
      setErr('')
      dispatch(setError(''))
    }
  }, [onClose])

  useEffect(() => {
    if (postReqResp.status === 200 && isOpen) {
      isEmailChanged(true);
      onClose()
    }
    dispatch(setPostReqResp(''));
  }, [postReqResp]);

  const onSubmitLocal = (formData: any) => {
    const sendData = formData as IPostChangeEmail
    sendData.email = formData.email;
    sendData.password = formData.password;
    dispatch(postChangeEmail(sendData))
  }

  return (
    <>
      <ModalWrapper
        headerText={headerText}
        isOpen={isOpen}
        onClose={onClose}
        buttons={modalButtons}
      >
        <Form
          onSubmit={onSubmitLocal}
          render={(formRenderProps) => (
            <FormElement>
              <fieldset className="k-form-fieldset">
                <div className="mb-3">
                  <Field
                    name={"email"}
                    component={CInput}
                    label={"Email"}
                    validator={isEmpty}
                  />
                  <Field
                    name={"password"}
                    component={CInput}
                    label={"Password"}
                    type="password"
                    validator={isEmpty}
                  />
                  {err && (
                    <div className={'mt-1 text-danger small'}>{err}</div>
                  )}
                </div>
              </fieldset>
              <Button
                type="submit"
                hidden={true}
                ref={formSubmitBtnRef}
              >
                submit
              </Button>
            </FormElement>
          )}
        />
      </ModalWrapper>
    </>
  )
}

export default ChangeEmailModal