import {
  IAccountInfo,
  IPostChangeEmail,
  IPostChangePassword,
  IPostEmailChangeConfirmation,
  IPostPasswordRecovery,
  IPostPasswordRecoveryConfirm,
  IPutAccountInfo,
  ISignInData,
  ISignUpData,
  IUser
} from '../../types/user';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../../types/common';
import { IInviteInfo } from './../../types/user';

export interface IAuthState {
  user: Nullable<IUser>;
  isSuperAdmin: boolean;
  accountInfo: Nullable<IAccountInfo>;
  isUserLoading: boolean;
  inviteInfo: Nullable<IInviteInfo>;
  isInviteLoading: boolean;
  isEmailConfirmLoading: boolean;
}

const initialState: IAuthState = {
  user: null,
  isSuperAdmin: false,
  accountInfo: null,
  isUserLoading: false,
  inviteInfo: null,
  isInviteLoading: true,
  isEmailConfirmLoading: false
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    signIn: (state, action: PayloadAction<ISignInData>) => {},
    signUp: (state, action: PayloadAction<ISignUpData>) => {},
    authOrg: (state, action: PayloadAction<string>) => {},
    getAccount: (state, action: PayloadAction) => {},
    putAccountInfo: (state, action: PayloadAction<IPutAccountInfo>) => {},
    getInviteInfoByInviteId: (state, action: PayloadAction<string>) => { },
    postEmailConfirmation: (state, action: PayloadAction<string>) => { },
    postEmailChangeConfirmation: (state, action: PayloadAction<IPostEmailChangeConfirmation>) => { },
    postChangePassword: (state, action: PayloadAction<IPostChangePassword>) => { },
    postChangeEmail: (state, action: PayloadAction<IPostChangeEmail>) => { },
    postSendEmailToRestorePassword: (
      state,
      action: PayloadAction<IPostPasswordRecovery>
    ) => {},
    postPasswordRecoveryConfirm: (
      state,
      action: PayloadAction<IPostPasswordRecoveryConfirm>
    ) => {},
    acceptInvite: (state, action: PayloadAction<string>) => {},
    setUser: (state, action: PayloadAction<Nullable<IUser>>) => {
      state.user = action.payload;
    },
    setSuperAdmin: (state, action: PayloadAction<boolean>) => {
      state.isSuperAdmin = action.payload;
    },
    setAccount: (state, action: PayloadAction<IAccountInfo | null>) => {
      state.accountInfo = action.payload;
    },
    setIsUserLoading: (state, action: PayloadAction<boolean>) => {
      state.isUserLoading = action.payload;
    },
    setInviteInfo: (state, action: PayloadAction<Nullable<IInviteInfo>>) => {
      state.inviteInfo = action.payload;
    },
    setIsInviteLoading: (state, action: PayloadAction<boolean>) => {
      state.isInviteLoading = action.payload;
    },
    setIsEmailConfirmLoading: (state, action: PayloadAction<boolean>) => {
      state.isEmailConfirmLoading = action.payload;
    }
  }
});

export const {
  signIn,
  signUp,
  authOrg,
  putAccountInfo,
  getInviteInfoByInviteId,
  postEmailConfirmation,
  postEmailChangeConfirmation,
  postChangePassword,
  postChangeEmail,
  postSendEmailToRestorePassword,
  postPasswordRecoveryConfirm,
  acceptInvite,
  getAccount,
  setUser,
  setSuperAdmin,
  setIsUserLoading,
  setAccount,
  setInviteInfo,
  setIsInviteLoading,
  setIsEmailConfirmLoading
} = authSlice.actions;

export default authSlice.reducer;
