import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, delay, put, takeLatest } from 'typed-redux-saga';
import Api from '../../services/api/api';
import { IPostBuyCloudPoints, IPostPayTariff } from '../../types/billing';
import { setError } from '../common/reducer';
import { setIsPaymentHistoryLoading, postBuyCloudPoints, postPayTariff, setPaymentHistory, getPaymentHistory } from './reducer';

function* postPayTariffSaga({
  payload: formData
}: PayloadAction<IPostPayTariff>): any {
  try {
    const parameters = `organizationId=${formData.organizationId}&tariffId=${formData.tariffId}`;
    yield* call(Api.postPayTariff, parameters);
  } catch (e: any) {
    yield put(setError(e));
  }
}

function* postBuyCloudPointsSaga({
  payload: formData
}: PayloadAction<IPostBuyCloudPoints>): any {
  try {
    const parameters = `organizationId=${formData.organizationId}&count=${formData.count}`;
    yield* call(Api.buyCloudPoints, parameters);
  } catch (e: any) {
    yield put(setError(e));
  }
}

function* getPaymentHistorySaga({ payload: organizationId
}: PayloadAction<string>) {
  try {
    yield put(setIsPaymentHistoryLoading(true))
    const response = yield* call(Api.getPaymentHistory, organizationId);
    yield put(setPaymentHistory(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsPaymentHistoryLoading(false));
  }
}

function* TariffWatcher() {
  yield all([
    takeLatest(postPayTariff.type, postPayTariffSaga),
    takeLatest(postBuyCloudPoints.type, postBuyCloudPointsSaga),
    takeLatest(getPaymentHistory.type, getPaymentHistorySaga),
  ]);
}

export default TariffWatcher;
