import { all, call, put, takeLatest } from 'typed-redux-saga';
import Api from '../../services/api/api';
import { getTelemetry, setTelemetry, setTelemetryLoading } from './reducer';
import { setError } from '../common/reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import { IGetDataTelemetry } from '../../types/telemetry';

function* getTelemetrySaga({
  payload: data
}: PayloadAction<IGetDataTelemetry>) {
  try {
    yield put(setTelemetryLoading(true));
    const response = yield* call(Api.getTelemetry, data);
    yield put(setTelemetry(response));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setTelemetryLoading(false));
  }
}

function* TelemetryWatcher() {
  yield all([takeLatest(getTelemetry.type, getTelemetrySaga)]);
}

export default TelemetryWatcher;
