import {
  Grid,
  GridCellProps,
  GridColumn,
  GridDataStateChangeEvent, GridItemChangeEvent,
  GridNoRecords, GridToolbar
} from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import GridLoader from '../../../../components/Loaders/GridLoader/GridLoader';
import ModalWrapper from '../../../../components/ModalWrapper/ModalWrapper';
import useAppBundle from '../../../../helpers/hooks/storeHooks/useAppBundle';
import useCommon from '../../../../helpers/hooks/storeHooks/useCommon';
import {
  deleteAppBundle,
  getAppBundleTableList,
  initialAppBundleState,
  setAppBundleDataState,
  setAppBundleTableList
} from '../../../../stores/appBundle/reducer';
import { setError } from '../../../../stores/common/reducer';
import { IAppBundle } from '../../../../types/appBundle';
import { IGridDataState, Nullable } from '../../../../types/common';
import { IModalWrapperButton } from '../../../../types/modal';
import AppBundleAliasPage from '../AppBundleAliasPage/AppBundleAliasPage';
import AppBundleActionCell from './components/AppBundleActionCell';
import CreateAppBundleModal from './modals/CreateAppBundleModal';

const AppBundlesPage = () => {
  const dispatch = useDispatch()
  const { isBundleListLoading, appBundleTableList, appBundleFilters } = useAppBundle()
  const [isShowAddBundleModal, setIsShowAddBundleModal] = useState(false)
  const [bundleIdToEdit, setBundleIdToEdit] = useState('');
  const [dataState, setDataState] = useState<IAppBundle[]>([]);
  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowErrorModal, setIsShowErrorModal] = useState(false);
  const { error } = useCommon()
  const [err, setErr] = useState<string>('');
  const [deletingAppBundle, setDeletingAppBundle] = useState<Nullable<IAppBundle>>(null);

  useEffect(() => {
    dispatch(getAppBundleTableList())
    return () => {
      dispatch(setAppBundleTableList(null))
      dispatch(setAppBundleDataState(initialAppBundleState.dataState))
    }
  }, [dispatch])
  useEffect(() => {
    setDataState(appBundleTableList?.data ?? [])
  }, [appBundleTableList])

  useEffect(() => {
    if (error !== '' && error?.response?.status === 422 && error?.response?.data?.message) {
      setErr(error?.response?.data?.message)
    }
  }, [error])

  useEffect(() => {
    if (err !== '') {
      setIsShowErrorModal(true)
      setErr('')
    }
    return () => {
      dispatch(setError(''))
    }
  }, [err])

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    dispatch(setAppBundleDataState(e.dataState as any))
    dispatch(getAppBundleTableList())
  }
  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';
    const newData = dataState.map(item =>
      item.id === event.dataItem.id
        ? { ...item, [field]: event.value }
        : item
    );
    setDataState(newData)
  };
  const toggleAppBundleModal = () => {
    setIsShowAddBundleModal(!isShowAddBundleModal)
    setBundleIdToEdit('')
  }
  
  const onEdit = (item: IAppBundle) => {
    setBundleIdToEdit(item.id)
    setIsShowAddBundleModal(true)
  }

  const onShow = (item: IAppBundle) => {
    return (
      <AppBundleAliasPage />
    );
  }

  const isActiveText = (props: GridCellProps) => {
    const text = props.dataItem.isActive ? 'yes' : 'no';
    return (
      <td>{text}</td>
    )
  }

  const onDelete = (item: IAppBundle) => {
    setDeletingAppBundle(item)
    setIsShowModal(true)
  };

  const continueOperation = () => {
    if (deletingAppBundle) {
      dispatch(deleteAppBundle({ id: deletingAppBundle.id }))
    }
    closeModal();
  }

  const closeModal = () => {
    setIsShowModal(false);
    setIsShowErrorModal(false);
  };

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueOperation
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeModal
    }
  ];

  const cantDeleteButton: IModalWrapperButton[] = [
    {
      buttonText: 'Ok',
      onButtonClick: closeModal
    }
  ];

  return (
    <div className="product-page app-bundle-page">
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowErrorModal}
        className=""
        buttons={cantDeleteButton}
      >
        <div>
          {"You can't delete "}
          <span className={"modal-wrapper-var"}>{deletingAppBundle?.name}</span>
          {" because it is being used in another component via its alias"}
        </div>
      </ModalWrapper>
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowModal}
        className=""
        buttons={modalButtons}
      >
        <div>
          <div>{"Are you sure you want to continue: delete "}
            <span className={"modal-wrapper-var"}>{deletingAppBundle?.name}</span>
            {"?"}
          </div>
        </div>
      </ModalWrapper>
      <Grid
        className="product-grid app-bundle-grid"
        data={dataState}
        {...(appBundleFilters as IGridDataState)}
        total={appBundleTableList?.total}
        pageable={true}
        sortable={true}
        onItemChange={itemChange}
        onDataStateChange={onDataStateChange}
      >
        <GridToolbar>
          <Button variant="primary" onClick={toggleAppBundleModal}>
            Add
          </Button>
        </GridToolbar>
        <GridNoRecords>
          <GridLoader isLoading={isBundleListLoading} />
        </GridNoRecords>
        <GridColumn field="name" title="Bundle name" />
        <GridColumn field="isActive" title="Is active" cell={isActiveText} />
        <GridColumn
          field="action"
          title="Action"
          className="action-cell"
          sortable={false}
          cell={(props: GridCellProps) =>
            <AppBundleActionCell onEdit={onEdit} onShow={onShow} onDelete={onDelete} {...props} />
          }
        />
      </Grid>
      <CreateAppBundleModal isOpen={isShowAddBundleModal} onClose={toggleAppBundleModal} dataToUpdateId={bundleIdToEdit} />
    </div>
  )
}

export default AppBundlesPage