import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../../types/common';
import { IGetDataTelemetry, ITelemetry } from '../../types/telemetry';

export interface ITelemetryState {
  isTelemetryLoading: boolean;
  telemetry: Nullable<ITelemetry>;
}

export const initialTelemetryState: ITelemetryState = {
  isTelemetryLoading: false,
  telemetry: null
};

const telemetrySlice = createSlice({
  name: 'telemetry',
  initialState: initialTelemetryState,
  reducers: {
    getTelemetry: (state, action: PayloadAction<IGetDataTelemetry>) => {},
    setTelemetry: (state, action: PayloadAction<ITelemetry>) => {
      state.telemetry = action.payload;
    },
    setTelemetryLoading: (state, action: PayloadAction<boolean>) => {
      state.isTelemetryLoading = action.payload;
    }
  }
});

export const { getTelemetry, setTelemetry, setTelemetryLoading } =
  telemetrySlice.actions;

export default telemetrySlice.reducer;
