import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';

const useAppBundleVersion = () => {
  const dataState = useSelector((state: IState) => state.appBundleVersion.dataState)
  const appBundleVersionList = useSelector((state: IState) => state.appBundleVersion.appBundleVersionList)
  const appBundleVersion = useSelector((state: IState) => state.appBundleVersion.appBundleVersion)
  const isAppBundleVersionLoading = useSelector((state: IState) => state.appBundleVersion.isAppBundleVersionLoading)
  const isAppBundleVersionListLoading = useSelector((state: IState) => state.appBundleVersion.isAppBundleVersionListLoading)

  return {
    dataState,
    appBundleVersion,
    appBundleVersionList,
    isAppBundleVersionLoading,
    isAppBundleVersionListLoading
  }
}

export default useAppBundleVersion