import { SortableItemUIProps } from '@progress/kendo-react-sortable';

import Tooltip, { TooltipProps } from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { EConfigurationStatus, IParameterChangesItem } from '../../../../../types/producVersionConfigurations';
import { enumGetKey } from './../../../../../helpers/enumFunctions';

const getBaseItemStyle = (isDragCue: boolean) => ({
  height: 50,
  fontSize: '16px',
  outline: 'none',
  border: '0.5px solid #dee2e6',
  cursor: 'move',
  displ: 'block',
  background: isDragCue ? 'rgba(0,0,0,0.8)' : 'fff',
  color: isDragCue ? '#fff' : '#000'
});


const SortableItemUI = (props: SortableItemUIProps) => {
  const {
    attributes,
    dataItem,
    forwardRef,
    isDragCue,
    style
  } = props;

  const dateString = new Date(dataItem.created).toLocaleDateString();


  const renderTooltip = (props: TooltipProps) => (
    <Tooltip id="button-tooltip" className="tooltip" {...props}>
      <div>
        <div className="tooltip-title">Parameter changes</div>
        {dataItem.parameterChanges.length ? (
          dataItem.parameterChanges.map(
            (item: IParameterChangesItem, index: number) => (
              <div key={index} className="tooltip-params">
                {`${item.name}:   ${item.value}`}
              </div>
            )
          )
        ) : (
          <div>no changes parameters</div>
        )}
      </div>
    </Tooltip>
  );

  return (
    <div
      ref={forwardRef}
      {...attributes}
      style={{ ...getBaseItemStyle(isDragCue), ...style }}
    >
      <div className="row-table">
        <div className="column-first">
          <span
            className="k-icon k-i-handler-drag"
            style={{ color: 'blue' }}
          ></span>
          {dataItem.indexNumber}
        </div>
        <OverlayTrigger
          placement="bottom"
          delay={{ show: 100, hide: 300 }}
          overlay={dataItem.isHiddenTooltip ? <></> : renderTooltip  }
          trigger={['hover', 'focus']}
        >
          <div className="column-parameteres-changes">
            {dataItem.parameterChanges.length ? (
              dataItem.parameterChanges.map(
                (item: IParameterChangesItem, index: number) => (
                  <span key={index} className="parameteres-changes">
                    <span className="parameteres-changes-name">{`${item.name}:   `}</span>
                    {`${item.value};`}
                  </span>
                )
              )
            ) : (
              <div className="no-changes-parameters">no changes parameters</div>
            )}
          </div>
        </OverlayTrigger>
       
        <div className="column-name">
           {`${dataItem.productName || ''} / ${ dataItem.productVersionName || ''}`}
        </div>
        <div className="column-date">
           {enumGetKey(dataItem.status, EConfigurationStatus)}
        </div>   
        <div className="column-date">
           {dateString}
        </div>
        <div className="column-actions">
          {dataItem.status !== EConfigurationStatus.InQueue && (
          <div>
            {dataItem.resubmit}
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SortableItemUI