import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import useAuthCheck from '../../../../helpers/hooks/storeHooks/useAuthCheck';
import ChangeEmailModal from './components/ChangeEmailModal';
import ChangePasswordModal from './components/ChangePasswordModal';

const SecurityPage = () => {
  const { user } = useAuthCheck()

  const [isShowChangePwdModal, setShowChangePwdModal] = useState(false);
  const [isShowChangeEmailModal, setShowChangeEmailModal] = useState(false);

  useEffect(() => {
      setShowChangePwdModal(false)
      setShowChangeEmailModal(false)
  }, [])

  const onChangePwd = () => {
    setShowChangePwdModal(!isShowChangePwdModal);
  }

  const onChangeEmail = () => {
    setShowChangeEmailModal(!isShowChangeEmailModal);
  }

  const [isPasswordChanged, setIsPasswordChanged] = useState(false)
  const [isEmailChanged, setIsEmailChanged] = useState(false)

  const passwordChanging = (status: boolean) => {
    setIsPasswordChanged(status)
  }

  const emailChanging = (status: boolean) => {
    setIsEmailChanged(status)
  }

  useEffect(() => {
    if (isPasswordChanged)
      setShowChangePwdModal(false)
    if (isEmailChanged)
      setShowChangeEmailModal(false)

  }, [isPasswordChanged, isEmailChanged])

  return (
    <>
      <div>
        <div className="security-button-block">
          Change password
          <br />
          <Button className={"btn btn-primary"} onClick={onChangePwd}>
            Change
          </Button>
          {isPasswordChanged && (
            <div className="text-success">Password is changed</div>
          )}
          <ChangePasswordModal
            isOpen={isShowChangePwdModal}
            onClose={onChangePwd}
            onSubmit={onChangePwd}
            isPasswordChanged={passwordChanging}
          />
        </div>
        <div className="security-button-block">
          Change email
          <br />
          {user?.email}
          <br />
          <Button className={"btn btn-primary"} onClick={onChangeEmail}>
            Change
          </Button>
          {isEmailChanged && (
            <div className="text-success">
              Email is changed. You need to confirm it after logout.
            </div>
          )}
          <ChangeEmailModal
            isOpen={isShowChangeEmailModal}
            onClose={onChangeEmail}
            onSubmit={onChangeEmail}
            email={user?.email || ''}
            isEmailChanged={emailChanging}
          />
        </div>
        <div className="security-button-block">
          Enable two-factor authentication
          <br />
          <Button disabled={true}>Enable 2FA</Button>
        </div>
      </div>
      
    </>
  )
}

export default SecurityPage