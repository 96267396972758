import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import BtnLink from "../../../components/BtnLink";
import useAuthCheck from "../../../helpers/hooks/storeHooks/useAuthCheck";
import useCommon from "../../../helpers/hooks/storeHooks/useCommon";
import { ERoutes } from "../../../router/Routes";
import { postEmailChangeConfirmation, postEmailConfirmation } from "../../../stores/authentication/reducer";
import { setError, setPostReqResp } from "../../../stores/common/reducer";

const EmailConfirmationPage = () => {
  const dispatch = useDispatch()
  const { hash, email } = useParams()
  const { postReqResp, error } = useCommon()
  const { isEmailConfirmLoading } = useAuthCheck()

  const [isEmailConfirmed, setIsEmailConfirmed] = useState(false)

  useEffect(() => {
    return () => {
      dispatch(setError(''))
    }
  }, [])

  useEffect(() => {
    if (hash && email) {
      dispatch(postEmailChangeConfirmation({ hash, email }))
    }
    if (hash) {
      dispatch(postEmailConfirmation(hash))
    }
  }, []);

  useEffect(() => {
    if (postReqResp.status === 200) {
      setIsEmailConfirmed(true);
    }
    dispatch(setPostReqResp(''));
  }, [isEmailConfirmLoading]);

  return (
    <>
      <div className="email-confirmation">
        <div></div>
        {isEmailConfirmed && !isEmailConfirmLoading && (
          <>
            <div>
              <div>Email is confirmed</div>
              <br />
              <BtnLink to={ERoutes.Root} idDisabled={!isEmailConfirmed} className='btn btn-primary mb-1'>Sign In</BtnLink>
            </div>
          </>
        )}
        {(error !== '' && error?.response?.status === 400 && error?.response?.data?.message)
          ?
          (<div className={'text-danger'}>{error?.response?.data?.message}</div>)
          :
          ('')}
        <div></div>
      </div>

    </>
  )
}

export default EmailConfirmationPage;