import { all, takeLatest, call, put, delay } from 'typed-redux-saga';
import Api from '../../services/api/api';
import { setLocalStorage } from '../../helpers/localStorage';
import {
  ACCESS_TOKEN_KEY,
  IPostEmailChangeConfirmation,
  IPostPasswordRecovery,
  IPostPasswordRecoveryConfirm,
  IPutAccountInfo,
  ISignInData,
  ISignUpData
} from '../../types/user';
import { setError, setPostReqResp } from '../common/reducer';
import {
  authOrg,
  getAccount,
  postPasswordRecoveryConfirm,
  postSendEmailToRestorePassword,
  putAccountInfo,
  setAccount,
  setIsUserLoading,
  setUser,
  setSuperAdmin,
  signIn,
  signUp,
  getInviteInfoByInviteId,
  setInviteInfo,
  acceptInvite,
  setIsInviteLoading,
  postEmailConfirmation,
  setIsEmailConfirmLoading,
  postChangePassword,
  postChangeEmail,
  postEmailChangeConfirmation
} from './reducer';
import { PayloadAction } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';

function* signInSaga({ payload: formData }: PayloadAction<ISignInData>) {
  try {
    yield put(setIsUserLoading(true));
    yield* call(delay, 300);
    const response = yield* call(Api.signIn, formData);
    yield put(setUser(response));
    yield put(setSuperAdmin(response?.isSuperAdmin || false));
    setLocalStorage(ACCESS_TOKEN_KEY, response.token);
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError;
      let msg = error.response?.data as any;
      yield put(setError(msg?.message || error.message));
      return;
    }

    yield put(setError(e?.message));
  } finally {
    yield put(setIsUserLoading(false));
  }
}

function* signUpSaga({ payload: formData }: PayloadAction<ISignUpData>) {
  try {
    yield put(setIsUserLoading(true));
    yield* call(delay, 300);
    const response = yield* call(Api.signUp, formData);
    yield put(setPostReqResp(response));
  } catch (e: any) {
    if (axios.isAxiosError(e)) {
      const error = e as AxiosError;
      let msg = error.response?.data as any;
      yield put(setError(msg?.message || error.message));
      return;
    }
    yield put(setError(e?.message));
  } finally {
    yield put(setIsUserLoading(false));
  }
}

function* authOrgSaga({ payload: orgId }: PayloadAction<string>) {
  try {
    yield put(setIsUserLoading(true));
    const response = yield* call(Api.authOrg, orgId);
    setLocalStorage(ACCESS_TOKEN_KEY + '_' + orgId, response.token);
    yield put(setPostReqResp(response.token));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsUserLoading(false));
  }
}

function* putAccountInfoSaga({
  payload: formData
}: PayloadAction<IPutAccountInfo>) {
  try {
    yield put(setIsUserLoading(true));
    yield* call(Api.putAccountInfo, formData);
    yield* call(getAccountSaga);
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsUserLoading(false));
  }
}

function* postEmailConfirmationSaga({
  payload: hash
}: PayloadAction<string>) {
  try {
    yield put(setIsEmailConfirmLoading(true));
    const resp = yield* call(Api.postEmailConfirmation, hash);
    yield put(setPostReqResp(resp));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsEmailConfirmLoading(false));
  }
}

function* postEmailChangeConfirmationSaga({
  payload: formData
}: PayloadAction<IPostEmailChangeConfirmation>) {
  try {
    yield put(setIsEmailConfirmLoading(true));
    const resp = yield* call(Api.postEmailChangeConfirmation, formData);
    yield put(setPostReqResp(resp));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
    yield put(setIsEmailConfirmLoading(false));
  }
}

function* postChangePasswordSaga({
  payload: formData
}: PayloadAction<any>) {
  try {
    const resp = yield* call(Api.postChangePassword, formData);
    yield put(setPostReqResp(resp));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
  }
}

function* postChangeEmailSaga({
  payload: formData
}: PayloadAction<any>) {
  try {
    const resp = yield* call(Api.postChangeEmail, formData);
    yield put(setPostReqResp(resp));
  } catch (e: any) {
    yield put(setError(e));
  } finally {
  }
}

function* postSendEmailToRestorePasswordSaga({
  payload: formData
}: PayloadAction<IPostPasswordRecovery>) {
  try {
    yield put(setIsUserLoading(true));
    yield* call(Api.postSendEmailToRestorePassword, formData);
    yield put(setPostReqResp(200));
  } catch (e: any) {
    yield put(setError(e?.response?.data?.message || e));
  } finally {
    yield put(setIsUserLoading(false));
  }
}

function* postPasswordRecoverySaga({
  payload: formData
}: PayloadAction<IPostPasswordRecoveryConfirm>) {
  try {
    yield put(setIsUserLoading(true));
    yield* call(Api.postPasswordRecoveryConfirm, formData);
    yield put(setPostReqResp(200));
  } catch (e: any) {
    yield put(setError(e?.message));
  } finally {
    yield put(setIsUserLoading(false));
  }
}

function* getAccountSaga() {
  try {
    const response = yield* call(Api.getAccount);
    yield put(setAccount(response));
  } catch (e: any) {
    yield put(setError(e));
  }
}

function* getInviteInfoByInviteIdSaga({
  payload: inviteId
}: PayloadAction<string>) {
  try {
    yield put(setIsInviteLoading(true));
    const response = yield* call(Api.getInviteInfoByInviteId, inviteId);
    yield put(setInviteInfo(response));
    return response;
  } catch (e: any) {
    yield put(setError(e));
    yield put(setInviteInfo(null));
  } finally {
    yield put(setIsInviteLoading(false));
  }
}

function* acceptInviteSaga({ payload: inviteId }: PayloadAction<string>) {
  try {
    yield put(setIsInviteLoading(true));
    yield* call(Api.acceptInvite, inviteId);
    yield put(setPostReqResp(200));
  } catch (e: any) {
    yield put(setError(e));
    yield put(setPostReqResp(400));
  } finally {
    yield put(setIsInviteLoading(false));
  }
}

function* authWatcher() {
  yield all([
    takeLatest(signIn.type, signInSaga),
    takeLatest(signUp.type, signUpSaga),
    takeLatest(authOrg.type, authOrgSaga),
    takeLatest(getAccount.type, getAccountSaga),
    takeLatest(putAccountInfo.type, putAccountInfoSaga),
    takeLatest(postEmailConfirmation.type, postEmailConfirmationSaga),
    takeLatest(postEmailChangeConfirmation.type, postEmailChangeConfirmationSaga),
    takeLatest(postChangePassword.type, postChangePasswordSaga),
    takeLatest(postChangeEmail.type, postChangeEmailSaga),
    takeLatest(
      postSendEmailToRestorePassword.type,
      postSendEmailToRestorePasswordSaga
    ),
    takeLatest(postPasswordRecoveryConfirm.type, postPasswordRecoverySaga),
    takeLatest(getInviteInfoByInviteId.type, getInviteInfoByInviteIdSaga),
    takeLatest(acceptInvite.type, acceptInviteSaga)
  ]);
}

export default authWatcher;
