import { GridCellProps } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import React, { FC } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import useCommon from '../../../../../helpers/hooks/storeHooks/useCommon';
import { setPath } from '../../../../../helpers/routingFunctions/routingFunctions';
import { ERoutes } from '../../../../../router/Routes';
import { IAppBundle } from '../../../../../types/appBundle';

interface IAppBundleActionCellProps extends GridCellProps {
  onShow: (dataItem: IAppBundle) => void,
  onEdit: (dataItem: IAppBundle) => void,
  onDelete: (dataItem: IAppBundle) => void,
  dataItem: IAppBundle
}

const AppBundleActionCell: FC<IAppBundleActionCellProps> = ({
  dataItem,
  onShow,
  onEdit,
  onDelete,
  ...others
}) => {
  const { inPendingList } = useCommon()
  const isInPending = inPendingList.includes(dataItem?.id)

  return (
    <>
    <td
      className={others.className}
      colSpan={others.colSpan}
      role="gridcell"
      aria-colindex={others.ariaColumnIndex}
      aria-selected={others.isSelected}
    >
      <Button
        variant="outline-secondary"
        className="k-m-1 pt-0 pb-0 btn-with-link"
      >
        <nav>
        <Link
          to={setPath(ERoutes.AppBundleId, [
            'admin',
            dataItem.id,
          ])}
          state={{ ...(dataItem as IAppBundle) }}
        >
          Show
          </Link>
        </nav>
      </Button>
      <Button variant="outline-secondary" className='k-m-1 pt-0 pb-0' onClick={() => onEdit(dataItem)} disabled={isInPending}>
        Edit
      </Button>
      <Button
        variant="outline-secondary"
        className='k-m-1 pt-0 pb-0'
        onClick={() => onDelete(dataItem)}
        disabled={isInPending}
      >
        {isInPending? <Loader type="pulsing" size="small"/> : 'Delete'}
      </Button>
    </td>
    </>
  )
}

export default AppBundleActionCell