import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';

const useOrders = () => {
  const paymentHistory = useSelector((state: IState) => state.billing.paymentHistory);
  const isPaymentHistoryLoading = useSelector(
    (state: IState) => state.billing.isPaymentHistoryLoading
  );

  return {
    paymentHistory,
    isPaymentHistoryLoading
  };
};

export default useOrders;
