import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useOrganizations from '../../../../helpers/hooks/storeHooks/useOrganizations';
import {
  deleteAssignedUser,
  getAssignedUsersOrganisationById,
  getOrganisationById,
  putAssignedUser,
  setDataStateAssignedUsers
} from '../../../../stores/organisation/reducer';
import { useParams } from 'react-router-dom';
import {
  Grid,
  GridNoRecords,
  GridColumn,
  GridToolbar,
  GridCellProps,
  GridDataStateChangeEvent,
  GridItemChangeEvent
} from '@progress/kendo-react-grid';
import { IUser } from '../../../../types/user';
import GridLoader from '../../../../components/Loaders/GridLoader/GridLoader';
import { Button } from 'react-bootstrap';
import UsersRoleCell from './components/UsersRoleCell';
import UsersActionCell from './components/UsersActionCell';
import {
  IDeleteAssignedUser,
  IPutAssignedUser
} from '../../../../types/organisationPage';
import ModalWrapper from './../../../../components/ModalWrapper/ModalWrapper';
import { IModalWrapperButton } from '../../../../types/modal';
import { Nullable } from '../../../../types/common';
import { IGridDataState } from './../../../../types/common';
import InviteUserForm from './components/InviteUserForm';
import { setError } from '../../../../stores/common/reducer';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import {
  IPageSizeSetItem,
  PAGE_SIZE_SET
} from '../../../../types/producVersionConfigurations';

const UsersPage = () => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();
  const {
    assignedUsers,
    isLoadingAssignedUsers,
    dataStateAssignedUsers,
    currentOrganization
  } = useOrganizations();

  const [gridData, setGridData] = useState<IUser[]>([]);
  const [roleSelected, setRoleSelected] = useState(-1);
  const [userDeleteSelected, setUserDeleteSelected] =
    useState<Nullable<IUser>>(null);
  const [isShowModalDelete, setIsShowModalDelete] = useState(false);
  const [isShowModalInviteUser, setIsShowModalInviteUser] = useState(false);
  const [isAdministrator, setIsAdministrator] = useState(false);

  const [pageSize, setPageSize] = useState<IPageSizeSetItem>(PAGE_SIZE_SET[0]);
  const [pageSizeSet, setPageSizeSet] =
    useState<IPageSizeSetItem[]>(PAGE_SIZE_SET);

  useEffect(() => {
    setIsAdministrator(currentOrganization?.orgUserRole === 1);
  }, [currentOrganization]);

  useEffect(() => {
    const total = assignedUsers?.total || PAGE_SIZE_SET[0].id;
    const pageSizeNew: IPageSizeSetItem[] = PAGE_SIZE_SET.reduce(
      (accum: IPageSizeSetItem[], current: IPageSizeSetItem) => {
        if (current?.value < total) {
          accum.push(current);
        }
        return accum;
      },
      []
    );
    const pageSizeResult = pageSizeNew.length
      ? [...pageSizeNew, { id: `${total}`, value: 'all' }]
      : [PAGE_SIZE_SET[0]];
    setPageSizeSet(pageSizeResult);
    const userGridData = assignedUsers.data.map((item: IUser) => ({
      ...item,
      isInEdit: false
    }));
    setGridData(userGridData);
  }, [assignedUsers]);

  useEffect(() => {
    const newDataStateAssignedUsers = {
      ...dataStateAssignedUsers,
      take: +pageSize.id,
      skip:
        Math.floor(dataStateAssignedUsers.skip / +pageSize.id) * +pageSize.id
    };
    dispatch(setDataStateAssignedUsers(newDataStateAssignedUsers));

    if (organizationId)
      dispatch(getAssignedUsersOrganisationById(organizationId));
  }, [pageSize]);

  const onInviteUser = () => {
    setIsShowModalInviteUser(true);
  };

  const itemChange = (event: GridItemChangeEvent) => {
    const field = event.field || '';
    const newData = gridData.map((item) =>
      item.id === event.dataItem.id ? { ...item, [field]: event.value } : item
    );
    setGridData(newData);
  };

  const onDataStateChange = (e: GridDataStateChangeEvent) => {
    dispatch(setDataStateAssignedUsers(e.dataState as any));
    if (organizationId)
      dispatch(getAssignedUsersOrganisationById(organizationId));
  };

  const onDeleteClickUser = (user: IUser) => {
    setIsShowModalDelete(true);
    setUserDeleteSelected(user);
  };

  const continueDelete = () => {
    const deleteData: IDeleteAssignedUser = {
      userId: userDeleteSelected?.id || '',
      organizationId: organizationId || ''
    };
    dispatch(deleteAssignedUser(deleteData));
    setUserDeleteSelected(null);
    setIsShowModalDelete(false);
  };

  const closeModalInviteUser = () => {
    setIsShowModalInviteUser(false);
    dispatch(setError(''));
  };

  const modalDeleteButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueDelete
    },
    {
      buttonText: 'Cancel',
      onButtonClick: () => setIsShowModalDelete(false)
    }
  ];

  const modalInviteUserButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Cancel',
      onButtonClick: closeModalInviteUser
    }
  ];

  const onEditUser = (userId: string) => {
    const userGridData = gridData.map((item) =>
      item.id === userId ? { ...item, isInEdit: true } : item
    );
    setGridData(userGridData);
  };

  const onCancel = () => {
    const userGridData = gridData.map((item) => ({
      ...item,
      isInEdit: false
    }));
    setGridData(userGridData);
  };

  const onUpdateUser = (userId: string) => {
    const putData: IPutAssignedUser = {
      userId,
      organizationId: organizationId || '',
      orgUserRole: roleSelected
    };
    dispatch(putAssignedUser(putData));
    setRoleSelected(-1);
    const userGridData = gridData.map((item: IUser) => (
      item.id === userId
        ? {
          ...item,
          orgUserRole: roleSelected,
          isInEdit: false
        }
        : {
          ...item,
          isInEdit: false
        }
    ));

    setGridData(userGridData);
  };

  const onSelectRole = (role: number) => {
    setRoleSelected(role);
  };

  const onPageSizeChange = (e: DropDownListChangeEvent) => {
    const chosenValue = e.value;
    setPageSize(chosenValue);
  };

  return (
    <>
      <ModalWrapper
        headerText="Confirm action"
        isOpen={isShowModalDelete}
        className=""
        buttons={modalDeleteButtons}
      >
        <div>{"Are you sure you want to continue: delete "}
          <span className={"modal-wrapper-var"}>{userDeleteSelected?.firstName || ''} {userDeleteSelected?.lastName || ''}</span>
          {"?"}
        </div>
      </ModalWrapper>
      <ModalWrapper
        headerText="Invite User"
        isOpen={isShowModalInviteUser}
        className=""
        buttons={modalInviteUserButtons}
      >
        <InviteUserForm closeModalInviteUser={closeModalInviteUser} />
      </ModalWrapper>
      <Grid
        className="product-grid version-grid"
        data={gridData}
        {...(dataStateAssignedUsers as IGridDataState)}
        total={assignedUsers?.total}
        pageable={true}
        sortable={true}
        onItemChange={itemChange}
        onDataStateChange={onDataStateChange}
      >
        <GridToolbar>
          <DropDownList
            style={{ width: '100px' }}
            data={pageSizeSet}
            value={pageSize}
            textField="value"
            dataItemKey="id"
            onChange={onPageSizeChange}
          />
          {isAdministrator && (
            <Button variant="primary" onClick={onInviteUser}>
              Invite user
            </Button>
          )}
        </GridToolbar>
        <GridNoRecords>
          <GridLoader isLoading={isLoadingAssignedUsers} />
        </GridNoRecords>
        <GridColumn field="firstName" title="First Name" />
        <GridColumn field="lastName" title="Last Name" />
        <GridColumn field="phone" title="Phone" />
        <GridColumn field="email" title="Email" />
        <GridColumn
          field="orgUserRole"
          title="Role"
          cell={(gridProps: GridCellProps) => (
            <UsersRoleCell gridProps={gridProps} onSelectRole={onSelectRole} />
          )}
        />
        {isAdministrator && (
          <GridColumn
            field="action"
            title="Action"
            className="action-cell"
            cell={(gridProps: GridCellProps) => (
              <UsersActionCell
                gridProps={gridProps}
                onDeleteClickUser={onDeleteClickUser}
                onEditUser={onEditUser}
                onCancel={onCancel}
                onUpdateUser={onUpdateUser}
              />
            )}
            sortable={false}
          />
        )}
      </Grid>
    </>
  );
};

export default UsersPage;
