import { all, call, delay, put, select, takeLatest, throttle } from 'typed-redux-saga';
import { PayloadAction } from '@reduxjs/toolkit';
import { IState } from '../configureStore';
import { toDataSourceRequestString } from '@progress/kendo-data-query';
import Api from '../../services/api/api';
import { setError, setPostReqResp } from '../common/reducer';
import {
  getConfigParams,
  getConfigurationById,
  getSVFStatusById,
  getConfigurationList,
  getSvfPath,
  postConfig,
  postConfigMulti,
  deleteConfigurationsByIds,
  recalculateConfigurationsByIds,
  generatesvfConfigurationsByIds,
  putConfig,
  searchConfiguration,
  setConfigParams, setConfiguration,
  setConfigurationsList,  
  setIsConfigLoading,
  setIsConfigurationRequestsLoading,
  setIsParamsLoading,
  setSearchedConfigList,
  setSvfPath,
  getConfigurationByRequest,
  getConfigurationByRequestId,
  setPostConfigurationResponse,
  setConfigurationRequestsList,
  deleteConfiguration,
  resubmitConfigurationRequest,
  getRequestForgeLog,
  getOutputLink,
  setOutputLink
} from './reducer';
import {
  IPostConfigurations,
  IPostConfigurationsMulti,
  IPutConfigurationsData,
  ISearchConfigPayload,
  IPostBulkOperations,
  IDeleteConfiguration,
  IDeleteBulkConfigurations,
  IGetConfigurationOutput
} from '../../types/producVersionConfigurations';
import { IGridFilterSetting } from '../../types/common';

function* getConfigurationListSaga({ payload: productVersionId }: PayloadAction<string>): any {
  try {
    const dataState = yield select((state: IState) => state.configurations.dataState)
    const dataString: string = toDataSourceRequestString({ ...dataState })
    const idParam = `&productVersionId=${productVersionId}`
    yield put(setIsConfigLoading(true))
    const response = yield* call(Api.getConfigurationList, dataString + idParam)
    yield put(setConfigurationsList(response))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(setIsConfigLoading(false))
  }
}

function* searchConfigurationsSaga({ payload: { id, value, skip, take } }: PayloadAction<ISearchConfigPayload>): any {
  try {
    const dataState = yield select((state: IState) => state.configurations.dataState)
    const searchDataState = {
      ...dataState,
      filter: {
        filters: [{ value: value, operator: 'contains', field: 'configurationName' }] as IGridFilterSetting[],
        logic: ''
      },
      skip: skip ?? dataState.skip,
      take: take ?? dataState.take
    }
    const dataString: string = toDataSourceRequestString({ ...searchDataState })
    const idParam = `&productVersionId=${id}`
    yield put(setIsConfigLoading(true))
    const response = yield* call(Api.getConfigurationList, dataString + idParam)
    yield put(setSearchedConfigList(response))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(setIsConfigLoading(false))
  }
}

function* getConfigurationByIdSaga({ payload: configId }: PayloadAction<string>) {
  try {
    yield put(setIsConfigLoading(true))
    const response = yield* call(Api.getConfigurationById, configId)
    yield* call(getConfigParamsSaga, { payload: configId, type: getConfigParams.type })
    yield put(setConfiguration(response))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(setIsConfigLoading(false))
  }
}

function* getSVFStatusByIdSaga({ payload: configId }: PayloadAction<string>) {
	try {
	  const response = yield* call(Api.getConfigurationById, configId)
	  yield put(setConfiguration(response))
	}
	catch (e: any) {
	  yield put(setError(e))
	}	
  }

function* getConfigurationByRequestIdSaga({ payload: configRequestId }: PayloadAction<string>) {
  try {
    // yield put(setIsConfigLoading(true))	
    const configRequestResponse = yield* call(Api.getConfigByRequestId, configRequestId)
    if (configRequestResponse && configRequestResponse.resultConfigurationId) {
      const response = yield* call(Api.getConfigurationById, configRequestResponse.resultConfigurationId)
      if (response) {
        yield put(setPostConfigurationResponse({
          resultConfigurationId: response.id,
          configurationRequestId: configRequestId
        }))
        // yield* call(getConfigParamsSaga, {payload: response.id, type: getConfigParams.type})
        // yield put(setConfiguration(response))
      }
    }
  }
  catch (e: any) {
    yield put(setError(e))
  }
//   finally {
//     yield put(setIsConfigLoading(false))
//   }
}

function* getConfigurationByRequestSaga({ payload: organizationId }: PayloadAction<string>) {
	try {
    yield put(setIsConfigurationRequestsLoading(true))
    const idParam = `organizationId=${organizationId}`
    const configigurationRequestsResponse = yield* call(Api.getConfigByRequest, idParam)
	  yield put(setConfigurationRequestsList(configigurationRequestsResponse))	
	}
	catch (e: any) {
	  yield put(setError(e))
	}
  finally {
      yield put(setIsConfigurationRequestsLoading(false))
  }
}  

function* getConfigParamsSaga({ payload: productVersionId }: PayloadAction<string>) {
  try {
    yield put(setIsParamsLoading(true))
    const response = yield* call(Api.getConfigParams, productVersionId)
    yield* call(delay, 500)
    yield put(setConfigParams(response))
  }
  catch (e: any) {
    yield put(setError(e))
  }
  finally {
    yield put(setIsParamsLoading(false))
  }
}

function* getSvfPathSaga({ payload: configId }: PayloadAction<string>) {
  try {	
		const response = yield* call(Api.getSvfPath, configId)
		yield put(setSvfPath(response))	   
  }
  catch (e: any) {
    yield put(setError(e))
  }
}

function* postConfigSaga({ payload: formData }: PayloadAction<IPostConfigurations>) {
  try {
    const responce = yield* call(Api.postConfig, formData)
    yield put(setPostConfigurationResponse(responce))
    yield put(setPostReqResp(responce))
  }
  catch (e: any) {
    yield put(setError(e))
  }
}

function* postConfigMultiSaga({ payload: formData }: PayloadAction<IPostConfigurationsMulti>) {
	try {
	 yield* call(Api.postConfigMulti, formData)
	}
	catch (e: any) {
	  yield put(setError(e))
	}
}

function* deleteConfigurationsByIdsSaga({ payload: formData}: PayloadAction<IDeleteBulkConfigurations>) {
	try {
    const payload = {
      ids: formData.idsData,
      deleteTemplateConfiguration: formData.deleteTemplateConfiguration
    }
    yield put(setIsConfigLoading(true))
    yield* call(Api.deleteConfigurationsByIds, payload)
    yield* call(getConfigurationListSaga, { payload: formData.versionId, type: getConfigurationList.type }) 
	}
	catch (e: any) {
	  yield put(setError(e))
	}
}

function* deleteConfigurationSaga({ payload: { id, deleteTemplateConfiguration, versionId } }: PayloadAction<IDeleteConfiguration>) {
  try {

    yield put(setIsConfigLoading(true))
    yield* call(Api.deleteConfiguration, id, deleteTemplateConfiguration)
    yield* call(getConfigurationListSaga, { payload: versionId, type: getConfigurationList.type })
  }
  catch (e: any) {
    yield put(setError(e))
  } finally {
    yield put(setIsConfigLoading(false))
  }
}

function* recalculateConfigurationsByIdsSaga({ payload: formData }: PayloadAction<IPostBulkOperations>) {
	try {
    yield put(setIsConfigLoading(true))

	 yield* call(Api.recalculateConfigurationsByIds, formData.idsData)
   yield* call(getConfigurationListSaga, { payload: formData.versionId, type: getConfigurationList.type }) 

	}
	catch (e: any) {
	  yield put(setError(e))
	}
}

function* generatesvfConfigurationsByIdsSaga({ payload: formData }: PayloadAction<IPostBulkOperations>) {
	try {
    yield put(setIsConfigLoading(true))

	 yield* call(Api.generatesvfConfigurationsByIds, formData.idsData)
   yield* call(getConfigurationListSaga, { payload: formData.versionId, type: getConfigurationList.type }) 

	}
	catch (e: any) {
	  yield put(setError(e))
	}
}


function* putConfigSaga({ payload: {formData,productVersionId} }:PayloadAction<IPutConfigurationsData>):any {
  try {
    const dataState = yield select((state: IState) => state.configurations.dataState) 
    const dataString: string = toDataSourceRequestString({ ...dataState })
    const idParam = `&productVersionId=${productVersionId}`
    yield* call(Api.putConfig, formData)   
    yield put(setIsConfigLoading(true))
    const response = yield* call(Api.getConfigurationList, dataString + idParam)
    yield put(setConfigurationsList(response))
  }
  catch (e: any) {
    yield put(setError(e))
  }
}

function* postResubmitRequestSaga({ payload: configRequestId }: PayloadAction<string>) {
  try {
    yield* call(Api.postResubmitConfigurationRequest, configRequestId)
  }
  catch (e: any) {
    yield put(setError(e))
  }
}

function* getRequestForgeLogSaga({ payload: configRequestId }: PayloadAction<string>) {
  try {
    yield* call(Api.getConfigurationRequestForgeLog, configRequestId)
  }
  catch (e: any) {
    yield put(setError(e))
  }
}

function* getOutputLinkSaga({ payload: { configurationId, orderId } }: PayloadAction<IGetConfigurationOutput>) {
  try {
    const response = yield* call(Api.getConfigurationOutput, configurationId, orderId)
    yield put(setOutputLink(response))
  } catch (e: any) {
    yield put(setError(e))
  }
}

function* configurationsWatcher() {
  yield all([
    takeLatest(getConfigurationList.type, getConfigurationListSaga),
    takeLatest(getConfigurationById.type, getConfigurationByIdSaga),
    takeLatest(getConfigParams.type, getConfigParamsSaga),
    takeLatest(getConfigurationByRequest.type, getConfigurationByRequestSaga),
    takeLatest(getConfigurationByRequestId.type, getConfigurationByRequestIdSaga),
    throttle(1000, searchConfiguration.type, searchConfigurationsSaga),
    takeLatest(getSVFStatusById.type, getSVFStatusByIdSaga),
    takeLatest(getSvfPath.type, getSvfPathSaga),
    takeLatest(postConfig.type, postConfigSaga),
    takeLatest(postConfigMulti.type, postConfigMultiSaga),
    takeLatest(deleteConfigurationsByIds.type, deleteConfigurationsByIdsSaga),
    takeLatest(deleteConfiguration.type, deleteConfigurationSaga),
    takeLatest(recalculateConfigurationsByIds.type, recalculateConfigurationsByIdsSaga),
    takeLatest(generatesvfConfigurationsByIds.type, generatesvfConfigurationsByIdsSaga),
    takeLatest(putConfig.type, putConfigSaga),
    takeLatest(resubmitConfigurationRequest.type, postResubmitRequestSaga),
    takeLatest(getRequestForgeLog.type, getRequestForgeLogSaga),
    takeLatest(getOutputLink.type, getOutputLinkSaga),
  ])
}

export default configurationsWatcher