import { GridCellProps } from '@progress/kendo-react-grid';
import React, { useContext, useState } from 'react';
import { ProdVersionContext } from '../VersionsPage';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { ERoutes } from '../../../../../router/Routes';
import { IProduct } from '../../../../../types/product';
import { setPath } from './../../../../../helpers/routingFunctions/routingFunctions';
import ModalWrapper from '../../../../../components/ModalWrapper/ModalWrapper';
import { IModalWrapperButton } from '../../../../../types/modal';
import { useDispatch } from 'react-redux';
import { delProdVer } from '../../../../../stores/productVersion/reducer';

const VersionsActionCell = (props: GridCellProps) => {
  const { dataItem } = props;
  const { organizationId } = useParams();
  const { onEdit } = useContext(ProdVersionContext);
  const dispatch = useDispatch()
  const [isShowModal, setIsShowModal] = useState(false);

  const onDelete = () => {
    setIsShowModal(true)
  };

  const continueOperation = async () => {
    dispatch(delProdVer(dataItem));
    closeModal();
  }

  const closeModal = () => {
    setIsShowModal(false);
  };

  const modalButtons: IModalWrapperButton[] = [
    {
      buttonText: 'Continue',
      onButtonClick: continueOperation
    },
    {
      buttonText: 'Cancel',
      onButtonClick: closeModal
    }
  ];

  return (
    <>
    <ModalWrapper
      headerText="Confirm action"
      isOpen={isShowModal}
      buttons={modalButtons}
    >
      <div>{"Are you sure you want to continue: delete "}
        <span className={"modal-wrapper-var"}>{dataItem.name}</span>
        {"?"}
      </div>
    </ModalWrapper>
    <td
      className={props.className}
      colSpan={props.colSpan}
      role="gridcell"
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
    >
      <Button
        variant="outline-secondary"
        className="k-m-1 pt-0 pb-0 btn-with-link"
      >
        <Link
          to={setPath(ERoutes.ProdVersion, [
            organizationId,
            dataItem.productId,
            dataItem.id
          ])}
          state={{ ...(dataItem as IProduct) }}
        >
          Show
        </Link>
      </Button>
      <Button
        variant="outline-secondary"
        className="k-m-1 pt-0 pb-0"
        onClick={() => onEdit(dataItem)}
      >
        Edit
      </Button>
      <Button
        variant="outline-secondary"
        className="k-m-1 pt-0 pb-0"
        onClick={() => onDelete()}
      >
        Delete
      </Button>
    </td>
    </>
  );
};
export default VersionsActionCell;
