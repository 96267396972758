import useAuthCheck from "../../../../helpers/hooks/storeHooks/useAuthCheck";
import React, {useState} from "react";
import {Button} from "react-bootstrap";
import BillingInfoEditModal from "./Modals/BillingInfoEditModal";


const BillingDetailPage = () => {
  const {account} = useAuthCheck()
  const [isShowInfoEdit, setIsShowInfoEdit] = useState(false)
  
  const onToggleInfoEditClose = () => setIsShowInfoEdit(!isShowInfoEdit)

  return (
    <>
      <h6>Billing details</h6>
      <div className="account-info-details">
        <div>
          <p><b>External ID: </b>{account ? account.externalId : ''} </p>
          <div className="details-button-container">
            <Button onClick={onToggleInfoEditClose}>Edit details</Button>
          </div>
        </div>
      </div>
      <BillingInfoEditModal isOpen={isShowInfoEdit} onClose={onToggleInfoEditClose}/>
    </>
  )

}

export default BillingDetailPage