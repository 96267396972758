import {Outlet, useLocation, useParams} from 'react-router-dom';
import {ERoutes} from '../../router/Routes';
import React, {useEffect, useState} from 'react';
import useAuthCheck from '../../helpers/hooks/storeHooks/useAuthCheck';
import BtnLink from '../../components/BtnLink';
import {useDispatch} from 'react-redux';
import {
  initialProdVerState,
  setProdVersionDataState
} from '../../stores/productVersion/reducer';
import {isAdminNavLinkActive, isNavLikActive} from '../../helpers/navLink';
import useCommon from '../../helpers/hooks/storeHooks/useCommon';
import ModalWrapper from '../../components/ModalWrapper/ModalWrapper';
import {setError} from '../../stores/common/reducer';
import useOrganizations from './../../helpers/hooks/storeHooks/useOrganizations';
import {getOrganisationById} from '../../stores/organisation/reducer';
import {EOrganisationTypes} from '../../types/organisationPage';
import {setPath} from '../../helpers/routingFunctions/routingFunctions';
import { enumGetKey } from '../../helpers/enumFunctions';

const DashboardPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const {organizationId, productId} = useParams();
  const {currentOrganization} = useOrganizations();
  const {user} = useAuthCheck();
  const {error} = useCommon();
  const [isAdministrator, setIsAdministrator] = useState(false);
  let orgType: string = '';
  if (currentOrganization) {
    orgType = enumGetKey(currentOrganization?.orgType, EOrganisationTypes)
  }

  const dashboardRootRegExp = ERoutes.Dashboard.match(/(?!\/).*(?=\/)/)?.[0];
  const insideSubPageRegExp = new RegExp(
    `(${dashboardRootRegExp}\/)([A-z\\d\\-]+\/){2,}`
  );
  const onProductVersionListPageRegExp = new RegExp(
    `(${dashboardRootRegExp}\/)([A-z\\d\\-]+\/){2}products`
  );
  const onProductVersionPageRegExp = new RegExp(
    `(${dashboardRootRegExp}\/)([A-z\\d\\-]+\/){3}products`
  );

  useEffect(() => {
    if (organizationId && !user?.isSuperAdmin) {
      dispatch(getOrganisationById(organizationId));
    }
  }, [organizationId]);

  useEffect(() => {
    setIsAdministrator(currentOrganization?.orgUserRole === 1);
  }, [currentOrganization]);

  useEffect(() => {
    return () => {
      dispatch(setProdVersionDataState(initialProdVerState.dataState));
    };
  }, [dispatch]);

  const returnBack = () => {
    if (location.pathname.match(onProductVersionListPageRegExp))
      return setPath(ERoutes.Products, [organizationId]);
    else if (location.pathname.match(onProductVersionPageRegExp) && productId)
      return setPath(ERoutes.ProdVersions, [organizationId, productId]);

    return -1 as any;
  };

  return (
    <div className="page-body">
      <ModalWrapper
        headerText=""
        isOpen={error !== '' && error?.response?.status !== 422}
        className=""
        buttons={[
          {buttonText: 'Ok', onButtonClick: () => dispatch(setError(''))}
        ]}
      >
        <div>something wrong</div>
      </ModalWrapper>

      <div className="dash-board-header">
        <div>
          <h6>Welcome, {user?.firstName}</h6>
          <h6>Your id: {user?.id} 
            {process.env.REACT_APP_DEVELOPMENT === 'true' && (<span> | Organization: {currentOrganization?.name} | 
            orgType: {orgType}</span>
          )}</h6>
        </div>
        <div>
          {location.pathname.match(insideSubPageRegExp) && (
            <BtnLink to={returnBack()} className="btn btn-outline-primary">
              Return
            </BtnLink>
          )}
        </div>
      </div>

      <div className="dash-board-body">
        <div className="dash-board-navigation">
          {user?.isSuperAdmin ? (
            <>
              <BtnLink
                to={setPath(ERoutes.AppBundles, ['admin'])}
                isActive={isAdminNavLinkActive(
                  setPath(ERoutes.AppBundles, ['admin']),
                  location.pathname)}
                className="btn btn-outline-primary mb-2"
              >
                App bundle
              </BtnLink>
              <BtnLink
                to={setPath(ERoutes.Statistics, ['admin'])}
                isActive={isNavLikActive(ERoutes.Statistics, location.pathname)}
                className="btn btn-outline-primary mb-2"
              >
                Statistics
              </BtnLink>
              <BtnLink
                to={setPath(ERoutes.Tariffs, ['admin'])}
                isActive={isNavLikActive(ERoutes.Tariffs, location.pathname)}
                className="btn btn-outline-primary mb-2"
              >
                Tariffs
              </BtnLink>
            </>
          ) : (
            <>
              {currentOrganization?.orgType ===
                EOrganisationTypes.Manufacturing && (
                  <>
                    <BtnLink
                      to={setPath(ERoutes.Orders, [organizationId])}
                      isActive={isNavLikActive(ERoutes.Orders, location.pathname)}
                      className="btn btn-outline-primary mb-2"
                    >
                      Orders
                    </BtnLink>

                    <BtnLink
                      to={setPath(ERoutes.Publications, [organizationId])}
                      isActive={isNavLikActive(
                        ERoutes.Publications,
                        location.pathname
                      )}
                      className="btn btn-outline-primary mb-2"
                    >
                      Publications
                    </BtnLink>

                    <BtnLink
                      to={ERoutes.Developers}
                      isActive={isNavLikActive(
                        ERoutes.Developers,
                        location.pathname
                      )}
                      className="btn btn-outline-primary mb-2"
                    >
                      Developers
                    </BtnLink>
                  </>
                )}
              {currentOrganization?.orgType === EOrganisationTypes.Design && (
                <>
                  <BtnLink
                    to={setPath(ERoutes.Products, [organizationId])}
                    isActive={isNavLikActive(ERoutes.Products, location.pathname)}
                    className="btn btn-outline-primary mb-2"
                  >
                    Products
                  </BtnLink>

                  <BtnLink
                    to={setPath(ERoutes.Publications, [organizationId])}
                    isActive={isNavLikActive(
                      ERoutes.Publications,
                      location.pathname
                    )}
                    className="btn btn-outline-primary mb-2"
                  >
                    Publications
                  </BtnLink>

                  <BtnLink
                    to={ERoutes.Manufacturers}
                    isActive={isNavLikActive(
                      ERoutes.Manufacturers,
                      location.pathname
                    )}
                    className="btn btn-outline-primary mb-2"
                  >
                    Manufacturers
                  </BtnLink>

                  <BtnLink
                    to={ERoutes.Onlinestores}
                    isActive={isNavLikActive(
                      ERoutes.Onlinestores,
                      location.pathname
                    )}
                    className="btn btn-outline-primary mb-2"
                  >
                    Online stores
                  </BtnLink>

                  <BtnLink
                    to={setPath(ERoutes.ConfigurationRequests, [organizationId])}
                    isActive={isNavLikActive(
                      ERoutes.ConfigurationRequests,
                      location.pathname
                    )}
                    className="btn btn-outline-primary mb-2"
                  >
                    Configuration Requests
                  </BtnLink>
                  <BtnLink
                    to={setPath(ERoutes.Statistics, [organizationId])}
                    isActive={isNavLikActive(ERoutes.Statistics, location.pathname)}
                    className="btn btn-outline-primary mb-2"
                  >
                    Statistics
                  </BtnLink>

                  <BtnLink
                    to={setPath(ERoutes.CPThreshold, [organizationId])}
                    isActive={isNavLikActive(
                      ERoutes.CPThreshold,
                      location.pathname
                    )}
                    className="btn btn-outline-primary mb-2"
                  >
                    CP Threshold
                  </BtnLink>
                  {/*<BtnLink*/}
                  {/*  to={setPath(ERoutes.Billing, [organizationId])}*/}
                  {/*  isActive={isNavLikActive(ERoutes.Billing, location.pathname)}*/}
                  {/*  className="btn btn-outline-primary mb-2"*/}
                  {/*>*/}
                  {/*  Billing*/}
                  {/*</BtnLink>*/}
                </>
              )}

              <BtnLink
                to={setPath(ERoutes.Users, [organizationId])}
                isActive={isNavLikActive(ERoutes.Users, location.pathname)}
                className="btn btn-outline-primary mb-2"
              >
                Users
              </BtnLink>

              {isAdministrator && (
                <BtnLink
                  to={setPath(ERoutes.Settings, [organizationId])}
                  isActive={isNavLikActive(ERoutes.Settings, location.pathname)}
                  className="btn btn-outline-primary mb-2"
                >
                  Settings
                </BtnLink>
              )}

            </>
          )}
        </div>

        <div className="dash-board-content">
          <Outlet/>
        </div>
      </div>
    </div>
  );
};

export default DashboardPage;
