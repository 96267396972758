import { useSelector } from 'react-redux';
import { IState } from '../../../stores/configureStore';

const useAppBundleAlias = () => {
  const dataState = useSelector((state: IState) => state.appBundleAlias.dataState)
  const appBundleAliasList = useSelector((state: IState) => state.appBundleAlias.appBundleAliasList)
  const allAppBundleAliases = useSelector((state: IState) => state.appBundleAlias.allAppBundleAliases)
  const appBundleAlias = useSelector((state: IState) => state.appBundleAlias.appBundleAlias)
  const isAppBundleAliasLoading = useSelector((state: IState) => state.appBundleAlias.isAppBundleAliasLoading)
  const isAppBundleAliasListLoading = useSelector((state: IState) => state.appBundleAlias.isAppBundleAliasListLoading)
  const isAllAppBundleAliasesLoading = useSelector((state: IState) => state.appBundleAlias.isAllAppBundleAliasesLoading)

  return {
    dataState,
    appBundleAlias,
    appBundleAliasList,
    allAppBundleAliases,
    isAppBundleAliasLoading,
    isAppBundleAliasListLoading,
    isAllAppBundleAliasesLoading
  }
}

export default useAppBundleAlias