import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Nullable } from '../../types/common';
import { IPostTariff, IPutTariff, ITariff } from './../../types/tariff';

export interface ITariffState {
  isTariffListLoading: boolean;
  tariffList: Nullable<ITariff[]>;
  isTariffLoading: boolean;
  tariff: Nullable<ITariff>;
}

export const initialTariffState: ITariffState = {
  isTariffListLoading: false,
  tariffList: [],
  isTariffLoading: false,
  tariff: null
};

const tariffSlice = createSlice({
  name: 'tariff',
  initialState: initialTariffState,
  reducers: {
    getTariffList: (state, action: PayloadAction) => {
    },
    setIsTariffListLoading: (state, action: PayloadAction<boolean>) => {
      state.isTariffListLoading = action.payload
    },
    setIsTariffLoading: (state, action: PayloadAction<boolean>) => {
      state.isTariffLoading = action.payload
    },
    setTariffList: (state, action: PayloadAction<Nullable<ITariff[]>>) => {
      state.tariffList = action.payload
    },
    setTariff: (state, action: PayloadAction<Nullable<ITariff>>) => {
      state.tariff = action.payload
    },
    getTariffById: (state, action: PayloadAction<string>) => {
    },
    postTariff: (state, action: PayloadAction<IPostTariff>) => {
    },
    putTariff: (state, action: PayloadAction<IPutTariff>) => {
    },
    deleteTariff: (state, action: PayloadAction<string>) => {
    },
  }
});

export const {
    getTariffList,
    setIsTariffListLoading,
    setIsTariffLoading,
    setTariffList,
    setTariff,
    getTariffById,
    postTariff,
    putTariff,
    deleteTariff
} = tariffSlice.actions;

export default tariffSlice.reducer;
