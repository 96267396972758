import { Outlet } from "react-router-dom"
import Header from '../Header/Header';
import Notifier from '../Notifier/Notifier';

const Layout = () => {

  return (
    <>   
      <Header/>
      <Outlet/>
      <Notifier/>
    </>
  )
}

export default Layout