import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { enumGetKey } from '../../../../../helpers/enumFunctions';
import { EOrgUserRoles } from '../../../../../types/user';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import { enumToKeqValue } from './../../../../../helpers/enumFunctions';

const dataUsersRole = enumToKeqValue(EOrgUserRoles);

interface IUsersRoleCell {
  gridProps: GridCellProps;
  onSelectRole: (role: number) => void;
}

const UsersRoleCell = ({ gridProps, onSelectRole }: IUsersRoleCell) => {
  const { dataItem } = gridProps;

  const handleChange = (event: DropDownListChangeEvent) => {
    onSelectRole(event.target.value.value);
    dataItem.orgUserRole = event.target.value.value
  };

  return (
    <td
      colSpan={gridProps.colSpan}
      role="gridcell"
      aria-colindex={gridProps.ariaColumnIndex}
      aria-selected={gridProps.isSelected}
    >
      {' '}
      {dataItem.isInEdit ? (
        <DropDownList
          style={{ width: '150px', textAlign: 'center' }}
          data={dataUsersRole}
          textField="key"
          dataItemKey="value"
          defaultValue={{
            key: enumGetKey(dataItem.orgUserRole, EOrgUserRoles),
            value: dataItem.orgUserRole
          }}
          onChange={handleChange}
        />
      ) : (
        enumGetKey(dataItem.orgUserRole, EOrgUserRoles)
      )}
    </td>
  );
};
export default UsersRoleCell;
