import React from 'react';
import { Field } from '@progress/kendo-react-form';
import CInput from '../../../../../components/form-components/CInput';
import { isEmpty } from '../../../../../components/form-components/helpers/validation-functions';

export interface ITableValue {
  value: any[];
  name: string;
}
export const TableValue = ({ value, name }: ITableValue) => {

  return (
    <div>
      <Field
        component={CInput}
        name={name}
        validator={isEmpty}
        rows={1}
      />
      {value}
    </div>
  );
};