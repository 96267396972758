import { TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import React from 'react';
import AppBundlesVersionPage from '../AppBundleVersionPage/AppBundleVersionPage';
import AppBundleAlias from './components/AppBundleAlias';

const AppBundleAliasPage = () => {
  const [selected, setSelected] = React.useState<number>(0);
  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  return (
    <TabStrip selected={selected} onSelect={handleSelect} style={{
    }}
    >
      <TabStripTab title="Aliases">
        <AppBundleAlias />
      </TabStripTab>
      <TabStripTab title="Versions">
        <AppBundlesVersionPage />
      </TabStripTab>
    </TabStrip>
  )
}

export default AppBundleAliasPage