import {
  DateRangePicker,
  DateRangePickerChangeEvent
} from '@progress/kendo-react-dateinputs';
import {
  DropDownList,
  DropDownListChangeEvent
} from '@progress/kendo-react-dropdowns';
import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import useBilling from '../../../../helpers/hooks/storeHooks/useBilling';
import useOrganizations from '../../../../helpers/hooks/storeHooks/useOrganizations';
import useTariff from '../../../../helpers/hooks/storeHooks/useTariff';
import { getPaymentHistory } from '../../../../stores/billing/reducer';
import {
  getAllDesigningOrganization,
  setOrganisationsDesigning
} from '../../../../stores/organisation/reducer';
import { getTariffById, setTariff } from '../../../../stores/tariff/reducer';
import { getTelemetry } from '../../../../stores/telemetry/reducer';
import { EPointType, IPaymentHistory } from '../../../../types/billing';
import { Nullable } from '../../../../types/common';
import { IOrganisationDesigning } from '../../../../types/organisationPage';
import useAuthCheck from './../../../../helpers/hooks/storeHooks/useAuthCheck';
import useTelemetry from './../../../../helpers/hooks/storeHooks/useTelemetry';
import { TableValue } from './componens/TableValues';


interface IDateState {
  start: Nullable<Date>;
  end: Nullable<Date>;
}

const d = new Date();
d.setMonth(d.getMonth() - 1);
d.setDate(d.getDate() + 1);

const defaultValueDateState = {
  start: new Date(d),
  end: new Date()
};

enum DateRanges {
  month = 1,
  week = 2,
  day = 3,
  custom = 4
}

interface IStatisticsData {
  availablePoints: number,
  availableTariffPoints: number,
  availableCloudPoints: number,

  name: string,
  expired: string
  remainingTariffPoints: number,
  totalTariffPoints: number,
  remainingDiskSpace: number,
  totalDiskSpace: number

  svfCount: number,
  totalSeconds: string;
}

const StatisticsPage = () => {
  const dispatch = useDispatch();
  const { organizationId } = useParams();

  const { user } = useAuthCheck();
  const { tariff } = useTariff();
  const { paymentHistory } = useBilling();
  const { telemetry } = useTelemetry();
  const { currentOrganization, organizationsDesigning, isLoadingOrganizationDesigning } =
    useOrganizations();

  const [dates, setDates] = useState<IDateState>(defaultValueDateState);
  const [data, setData] = useState<Nullable<IStatisticsData>>(null);
  const [sortedHistory, setSortedHistory] = useState<IPaymentHistory[]>([])
  const [selectedOrganization, setSelectedOrganization] = useState<IOrganisationDesigning>();
  const [isSelected, setIsSelected] = useState({ month: true, week: false, day: false, custom: false });

  useEffect(() => {
    if (!user?.isSuperAdmin && currentOrganization) {
      dispatch(getPaymentHistory(currentOrganization?.id || ''))
      if (currentOrganization.tariffId) {
        dispatch(getTariffById(currentOrganization?.tariffId || ''))
      }
    } else if (user?.isSuperAdmin && selectedOrganization) {
      dispatch(getPaymentHistory(selectedOrganization?.id || ''))
      if (selectedOrganization.tariffId) {
        dispatch(getTariffById(selectedOrganization?.tariffId || ''))
      }
    }
  }, [currentOrganization, selectedOrganization]);

  useEffect(() => {
    if (user?.isSuperAdmin) dispatch(getAllDesigningOrganization());
    return () => {
      dispatch(setOrganisationsDesigning([]));
    };
  }, []);

  useEffect(() => {
    if (user?.isSuperAdmin && organizationsDesigning.length)
      setSelectedOrganization(organizationsDesigning[0]);
  }, [organizationsDesigning]);

  useEffect(() => {
    if (
      user?.isSuperAdmin &&
      dates.start &&
      dates.end &&
      selectedOrganization?.id
    ) {
      dispatch(
        getTelemetry({
          organizationId: selectedOrganization.id,
          startDate: dates.start.toDateString(),
          endDate: dates.end.toDateString()
        })
      );
    }
    if (!user?.isSuperAdmin && dates.start && dates.end && organizationId) {
      dispatch(
        getTelemetry({
          organizationId: organizationId,
          startDate: dates.start.toDateString(),
          endDate: dates.end.toDateString()
        })
      );
    }
  }, [dates, selectedOrganization, organizationId]);

  useEffect(() => {
    function SortByDate(x: IPaymentHistory, y: IPaymentHistory) {
      return new Date(y.expired).getTime() - new Date(x.expired).getTime()
    }

    const arr = [...paymentHistory].sort((a, b) => SortByDate(a, b))
    setSortedHistory(arr.filter(x => x.pointType === EPointType.TariffPoint))
  }, [paymentHistory])

  useEffect(() => {
    if (telemetry && paymentHistory.length > 0 && sortedHistory.length > 0) {
      setData(
        {
          availablePoints: Number.parseFloat((telemetry?.tariffPoints + telemetry?.cloudPoints).toFixed(3)) ?? 0,
          availableTariffPoints: Number.parseFloat((telemetry?.tariffPoints).toFixed(3)),
          availableCloudPoints: Number.parseFloat((telemetry?.cloudPoints).toFixed(3)),

          name: tariff?.name || '',
          expired: new Date(sortedHistory[0].expired).toLocaleDateString() || "",
          remainingTariffPoints: Number.parseFloat((telemetry?.tariffPoints).toFixed(3)),
          totalTariffPoints: tariff?.tariffPoints || 0,
          remainingDiskSpace: Number.parseFloat((telemetry?.remainingSpace).toFixed(3)),
          totalDiskSpace: tariff?.availableDiskSpace || 0,

          svfCount: telemetry?.svfCount,
          totalSeconds: new Date(telemetry?.totalSeconds * 1000).toISOString().substring(11, 19),
        }
      );
    }
  }, [tariff, telemetry, paymentHistory, sortedHistory]);

  const onChangeDate = (event: DateRangePickerChangeEvent) => {
    setIsSelected({
      ...isSelected,
      month: false,
      week: false,
      day: false,
      custom: true
    })
    setDates(event.value);
  };

  const onChangeOrganization = (event: DropDownListChangeEvent) => {
    setData(null)
    setTariff(null);
    setSelectedOrganization(event.value);
  };

  const datePickButton = (value: DateRanges) => {
    setIsSelected({
      ...isSelected,
      month: value == DateRanges.month ? true : false,
      week: value == DateRanges.week ? true : false,
      day: value == DateRanges.day ? true : false,
      custom: value == DateRanges.custom ? true : false
    });

    const d = new Date();
    if (value === DateRanges.month) {
      d.setMonth(d.getMonth() - 1);
      d.setDate(d.getDate() + 1);
    } else if (value === DateRanges.week) {
      d.setDate(d.getDate() - 6);
    } else if (value === DateRanges.day) {
      d.setDate(d.getDate());
    }

    const currentDate = new Date()
    setDates(
      {
        start: d,
        end: currentDate
      })
  }

  return (
    <div className="page-statistics">
      <div className="m-1">
        <h6 className="mb-1">Cloud points</h6>
        {user?.isSuperAdmin && (
        <div className="page-statistics-dropdown mb-1">
            <DropDownList
              name="designingOrganization"
              data={organizationsDesigning}
              textField="name"
              dataItemKey="id"
              defaultValue={organizationsDesigning[0]}
              onChange={onChangeOrganization}
              loading={isLoadingOrganizationDesigning}
            />
        </div>
        )}
        <Table striped bordered hover className="table ">
          <tbody>
            <tr className="tr-settings">
              <th className="td-first">Available points</th>
              <td>
                <TableValue
                  name={"Available points"}
                  value={data?.availablePoints ? [data?.availablePoints] : [0]}
                />
              </td>
            </tr>
            <tr className="tr-settings">
              <th className="td-first">Tariff points</th>
              <td>
                <TableValue
                  name={"Tariff points"}
                  value={data?.availableTariffPoints                    ? [data?.availableTariffPoints, " (expires on ", data?.expired, ")"] : [0]}                />
              </td>
            </tr>
            <tr className="tr-settings">
              <th className="td-first">Cloud points</th>
              <td>
                <TableValue
                  name={"Tariff points"}
                  value={data?.availableCloudPoints
                    ? [data?.availableCloudPoints] : [0]}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="m-1">
        <h6 className="" >Tariff info</h6>
        <Table striped bordered hover className="table">
          <tbody>
            <tr className="tr-settings">
              <th className="td-first">Title</th>
              <td>
                <TableValue
                  name={"Title"}
                  value={data?.name ? [data?.name] : ["No tariff"]}
                />
              </td>
            </tr>
            <tr className="tr-settings">
              <th className="td-first">Renewal date</th>
              <td>
                <TableValue
                  name={"Renewal date"}
                  value={data?.expired ? [data?.expired] : ["No date"]}                />
              </td>
            </tr>
            <tr className="tr-settings">
              <th className="td-first">Remaining tariff points</th>
              <td>
                <TableValue
                  name={"Tariff points"}
                  value={data?.remainingTariffPoints
                    ? [data?.remainingTariffPoints, "/", data?.totalTariffPoints] : [0]}
                />
              </td>
            </tr>
            <tr className="tr-settings">
              <th className="td-first">Used disk space</th>
              <td>
                <TableValue
                  name={"Used disk space"}
                  value={data?.remainingDiskSpace
                    ? [data?.remainingDiskSpace, "/", data?.totalDiskSpace] : [0]}
                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <div className="m-1">
        <h6 className="">Forge usage info</h6>
        <div className="m-3 btn-group" role="group"
          style={{ display: 'inline-flex', flexDirection: 'row', justifyContent: 'center' }}
        >
          <DateRangePicker
            className={'k-flex'}
            style={{ marginRight: '20px', height: '39px' }}
            defaultValue={defaultValueDateState}
            onChange={onChangeDate}
            value={{ start: dates.start || new Date(), end: dates.end || new Date() }}
          />
          <div>
            <button
              onClick={() => datePickButton(DateRanges.month)}
              name="month"
              style={{ marginRight: '5px' }}
              disabled={isSelected.month ? true : false}
              className={isSelected.month ? "btn btn-primary" : "btn btn-outline-primary"}>
              month
            </button>
            <button
              onClick={() => datePickButton(DateRanges.week)}
              name="week"
              style={{ marginRight: '5px' }}
              disabled={isSelected.week ? true : false}
              className={isSelected.week ? "btn btn-primary" : "btn btn-outline-primary"}>
              week
            </button>
            <button
              onClick={() => datePickButton(DateRanges.day)}
              name="day"
              disabled={isSelected.day ? true : false}
              className={isSelected.day ? "btn btn-primary" : "btn btn-outline-primary"}>
              day
            </button>
          </div>
        </div>
        <Table striped bordered hover className="table">
          <tbody>
            <tr className="tr-settings">
              <th className="td-first">SVF count</th>
              <td>
                <TableValue
                  name={"SVF count"}
                  value={data?.svfCount ? [data?.svfCount] : [0]}
                />
              </td>
            </tr>
            <tr className="tr-settings">
              <th className="td-first">Forge usage time</th>
              <td>
                <TableValue
                  name={"Forge usage time"}
                  value={[data?.totalSeconds ? data?.totalSeconds : 0, " sec"]}                />
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default StatisticsPage;
