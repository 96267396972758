import React from 'react'
import useCommon from '../../helpers/hooks/storeHooks/useCommon';

interface IErrorMessageProps {
  style?: string
}

const ErrorMessage = ({style}:IErrorMessageProps) => {
  const {error} = useCommon()

  return(
    <div className={`text-danger small ${style}`}>
      {error !=='' && error?.response?.status === 422 && error?.response?.data?.message ? error?.response?.data?.message : ''}
    </div>
  )
}
export default ErrorMessage