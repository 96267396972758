import React, { useState, useRef, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import useOrganizations from '../../../../helpers/hooks/storeHooks/useOrganizations';
import { FormText } from './Components/FormText';
import Table from 'react-bootstrap/Table';
import FormCheckBox from '../../../../components/form-components/CheckBox';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useDispatch } from 'react-redux';
import { putOrganisation } from '../../../../stores/organisation/reducer';
import useCommon from './../../../../helpers/hooks/storeHooks/useCommon';
import Spinner from '../../../../components/Loaders/Spinner/Spinner';

const SettingsPage = () => {
  const dispatch = useDispatch();

  const { currentOrganization } = useOrganizations();
  const { isLoadingDashboard } = useCommon();
  const formRef = useRef<Form | null>(null);
  const [isInEdit, setIsInEdit] = useState(false);

  const onSubmitData = (formData: any) => {
    if (!formRef?.current?.isValid()) return;

    const putData = { ...formData, id: currentOrganization?.id };

    dispatch(putOrganisation(putData));
    setIsInEdit(false);
  };

  const onCancelClick = () => {
    formRef.current?.resetForm();
    setIsInEdit(false);
  };

  return (
    <>
      {' '}
      {isLoadingDashboard ? (
        <Spinner />
      ) : (
        <Form
          ref={formRef}
          ignoreModified={true}
          onSubmit={onSubmitData}
          initialValues={{
            name: currentOrganization?.name,
            description: currentOrganization?.description,
            isActive: currentOrganization?.isActive
          }}
          render={() => (
            <FormElement>
              <Table striped bordered hover>
                <tbody>
                  <tr className="tr-settings">
                    <td className="td-first">Name</td>
                    <td>
                      <FormText
                        isInEdit={isInEdit}
                        name="name"
                        text={currentOrganization?.name || ''}
                      />
                    </td>
                  </tr>
                  <tr className="tr-settings">
                    <td className="td-first">Description</td>
                    <td>
                      <FormText
                        isInEdit={isInEdit}
                        name="description"
                        text={currentOrganization?.description || ''}
                      />
                    </td>
                  </tr>
                  <tr className="tr-settings">
                    <td className="td-first">isActive</td>
                    <td>
                      <Field
                        name="isActive"
                        id="isActive"
                        component={FormCheckBox}
                        label=""
                        defaultValue={currentOrganization?.isActive}
                        disabled={!isInEdit}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
              <Button type="submit" hidden={!isInEdit}>
                Save
              </Button>
              <Button
                hidden={isInEdit}
                variant="outline-primary"
                onClick={() => setIsInEdit(true)}
              >
                Edit
              </Button>
              <span className="ms-3">
                <Button
                  hidden={!isInEdit}
                  variant="outline-primary"
                  onClick={onCancelClick}
                >
                  Cancel
                </Button>
              </span>
            </FormElement>
          )}
        />
      )}
    </>
  );
};

export default SettingsPage;
